import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { APIMethod } from "../../../../API/APIClient";
import Loader from "../../../../components/Loader";
import ModalPopup from "../../../../components/ModalPopUp";
import Pagination from "../../../../components/Pagination";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router";
import moment from "moment";
import NoData from "../../../../components/NoDataFound";
import SearchNoDataFound from "../../../../components/SearchNoDataFound";
import ReactModal from "react-modal-resizable-draggable";

const KeywordEntries = () => {
  const [loading, setLoading] = useState(false);
  const [getListData, setGetListData] = useState([]);
  const [RFxCategoryData, setRFxCategoryData] = useState([]);
  // Delete
  const [deleteId, setDeleteId] = useState("");
  const [showModalPopup, setShowModalPopup] = useState(false);
  // page
  const [pageCount, setPageCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  //Added this code for  WOII-120. Divya. November 11 2023.. Code Start
  const [rowsPerPage, setRowsPerPage] = useState(10);
  //Divya. November 11 2023. Code End
  const { addToast } = useToasts();
  const [adding, setAdding] = useState();
  const [editing, setEditing] = useState();
  const [deleting, setDeleting] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [formFields, setFormFields] = useState({
    // Commenting the following code for WOII-185. Divya. March 19 2024.. Code Start
    //bid_source: '', rfx_category: '', exclusion: ''
    //Commenting end. Divya  March 19 2024..
    // Added this code for  WOII-185. Divya. March 19 2024.. Code Start
    bid_source: "",
    rfx_category: "",
    exclusion: "",
    status: "ACTIVE",
    //Divya. March 19 2024. Code End
  });

  const history = useHistory();

  useEffect(() => {
    //Commenting the following code for WOII-120. Divya. November 11 2023.. Code Start
    //getKeywordEntriesList(1)
    //Commenting end. Divya. November 11 2023..
    //Added this code for  WOII-120. Divya. November 11 2023.. Code Start
    getKeywordEntriesList(currentPage);
    //Divya. November 11 2023. Code End
    getRFxCategoryList();
    var data = JSON.parse(localStorage.getItem("user_Role"));
    checkPermission(data);
    //Commenting the following code for WOII-120. Divya. November 11 2023.. Code Start
    //}, [])
    //Commenting end. Divya. November 11 2023..
    //Added this code for  WOII-120. Divya. November 11 2023.. Code Start
  }, [rowsPerPage, currentPage]);
  //Divya. November 11 2023. Code End

  const checkPermission = (data) => {
    const resObject = data.find(
      (item) => item.name === "Content Library Setup"
    );
    const valObject = resObject.access.find(
      (item) => item.name === "Keyword Exclusions"
    );
    setAdding(valObject.is_create);
    setEditing(valObject.is_update);
    setDeleting(valObject.is_delete);
  };

  const getKeywordEntriesList = (page, key) => {
    setLoading(true);
    const new_key = key === undefined ? "" : key;
    global.new_key = key === undefined ? "" : key;
    var method = "GET";
    //Commenting the following code for WOII-120. Divya. November 11 2023.. Code Start
    //const apifetcherObj = APIMethod(`/keyword-exclusions/list/${page}?bid_source=${formFields.bid_source}&exclusion=${formFields.exclusion}&rfx_category_id=${formFields.rfx_category}&keyword=` + new_key, null, method)
    //Commenting end. Divya. November 11 2023..
    //Added this code for  WOII-120. Divya. November 11 2023.. Code Start
    // added status parameter for WOII-185 issue
    const apifetcherObj = APIMethod(
      `/keyword-exclusions/list/${page}?bid_source=${formFields.bid_source}&exclusion=${formFields.exclusion}&rfx_category_id=${formFields.rfx_category}&limit=${rowsPerPage}&status=${formFields.status}&keyword=` +
        new_key,
      null,
      method
    );
    //Divya. November 11 2023. Code End
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];

        if (statusCode === 200) {
          setGetListData(data.list);
          if (page === 1) {
            setPageCount(data.count);
          }
          setCurrentPage(page);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        if (error.status === 500) {
          addToast("Server Error", { appearance: "error", autoDismiss: true });
        } else {
          addToast(error, { appearance: "error", autoDismiss: true });
        }
        setLoading(false);
      });
  };

  const getRFxCategoryList = () => {
    setLoading(true);
    var method = "GET";
    const apifetcherObj = APIMethod(`/rfx-categories/all/list`, null, method);
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];

        if (statusCode === 200) {
          setRFxCategoryData(data);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const _onDelete = (id) => {
    setDeleteId(id);
    isShowPopup(true);
  };

  const isShowPopup = (status) => {
    setShowModalPopup(status);
  };

  const nextPage = (pageNumber) => {
    //Commenting the following code for WOII-120. Divya. November 11 2023.. Code Start
    //getKeywordEntriesList(pageNumber)
    //Commenting end. Divya. November 11 2023..
    //Added this code for  WOII-120. Divya. November 11 2023.. Code Start
    setCurrentPage(pageNumber);
    //Divya. November 11 2023. Code End
  };

  {
    /* Added this code for  WOII-120. Divya. November 11 2023.. Code Start */
  }
  const handleRowsPerPageChange = (selectedValue) => {
    setRowsPerPage(selectedValue);
  };
  {
    /* Divya. November 11 2023. Code End */
  }

  const Search = (key) => {
    getKeywordEntriesList(1, key);
  };

  const _onEdit = (val) => {
    history.push({
      pathname: "/add-keyword",
      state: { details: val },
    });
  };

  const getData = () => {
    getKeywordEntriesList(1);
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value,
    };
    setFormFields(formFieldsObject);
  };

  const _onResetFilter = (type) => {
    if (type === "BID_SOURCE") {
      formFields.bid_source = "";
    } else if (type === "RFX_CATEGORY") {
      formFields.rfx_category = "";
    } else if (type === "EXCLUSION") {
      formFields.exclusion = "";
    }
    // Added this code for  WOII-185. Divya. March 19 2024.. Code Start
    else if (type === "STATUS") {
      formFields.status = "";
    }
    //Divya. March 19 2024. Code End
    else {
      formFields.bid_source = "";
      formFields.rfx_category = "";
      formFields.exclusion = "";
      // Added this code for  WOII-185. Divya. March 19 2024.. Code Start
      formFields.status = "";
      //Divya. March 19 2024. Code End
      handleClose();
    }
    getKeywordEntriesList(1, "");
  };

  // Added this code for  WOII-185. Divya. March 19 2024.. Code Start
  const enableRow = (id) => {
    var method = "PUT";
    const apifetcherObj = APIMethod(
      `/keyword-exclusions/${id}/enable`,
      null,
      method
    );
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];

        if (statusCode === 200) {
          getKeywordEntriesList(currentPage);
          isShowPopup(false);
        }
      })
      .catch((error) => {
        console.error("Error enabling keyword exclusions:", error);
      });
  };

  const deleteRow = (id) => {
    var method = "DELETE";
    const apifetcherObj = APIMethod(
      `/keyword-exclusions/${id}/delete`,
      null,
      method
    );
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];

        if (statusCode === 200) {
          getKeywordEntriesList(currentPage);
          isShowPopup(false);
        }
      })
      .catch((error) => {
        console.error("Error in deleting keyword exclusions:", error);
      });
  };
  //Divya. March 19 2024. Code End
  const _onFilter = () => {
    getKeywordEntriesList(1, "");
    handleClose();
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content">
          <div className="row mb-3 align-items-center justify-content-between">
            <div className="col-lg-5 col-md-12 d-md-flex mb-md-0 mb-2 mb-lg-0 mb-md-3">
              <h2 className="page-title mb-md-0">Keyword Exclusions </h2>
            </div>
            <div className="col-lg-5 col-md-12 text-right d-md-flex align-items-center justify-content-center">
              <div className="input-group page-cont-search mr-md-3 mb-md-0 mb-2">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search by bid and exclusion"
                  onChange={(event) => Search(event.target.value)}
                />
                <span className="input-group-text text-secondary">
                  <i className="icofont-search-1"></i>
                </span>
              </div>
              <button
                type="button"
                className="btn btn-brand-1 btn-h-40 mr-3"
                onClick={() => setShow(true)}
              >
                <i className="icofont-filter mr-2"></i> Filter
              </button>
              {adding === true ? (
                <NavLink
                  to="/add-keyword"
                  className="btn btn-brand-1 bg-success px-4"
                >
                  + Add
                </NavLink>
              ) : null}
            </div>
          </div>

          <div className="mb-2 filter-by">
            {formFields.bid_source ? (
              <div className="badge">
                <span className="lable">Bid Source</span>
                <span className="value">{formFields.bid_source}</span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("BID_SOURCE")}
                ></i>
              </div>
            ) : null}
            {formFields.rfx_category ? (
              <div className="badge">
                <span className="lable">RFx Category</span>
                {RFxCategoryData.map((res, index) => {
                  return (
                    <span key={index} className="value">
                      {formFields.rfx_category == res.id ? res.name : null}
                    </span>
                  );
                })}
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("RFX_CATEGORY")}
                ></i>
              </div>
            ) : null}
            {formFields.exclusion ? (
              <div className="badge">
                <span className="lable">Exclusion</span>
                <span className="value">{formFields.exclusion}</span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("EXCLUSION")}
                ></i>
              </div>
            ) : null}
            {/*  Added this code for  WOII-185. Divya. March 19 2024.. Code Start */}
            {formFields.status ? (
              <div className="badge">
                <span className="lable">Status</span>
                <span className="value">
                  {formFields.status == "ACTIVE"
                    ? "Active"
                    : formFields.status == "DELETED"
                    ? "Archived"
                    : formFields.status}
                </span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("STATUS")}
                ></i>
              </div>
            ) : null}
          </div>
          {/* Divya. March 19 2024. Code End  */}

          <div
            style={{ height: "500px", overflowY: "auto", marginBottom: "20px" }}
          >
            <table className="table table-style-1 table-responsive-md mb-4 table reports">
              <thead className="sticky-top" style={{ zIndex: 10 }}>
                <tr>
                  <th scope="col">Bid Source </th>
                  <th scope="col">Rfx Category</th>
                  <th scope="col" style={{ maxWidth: 400 }}>
                    Exclusion
                  </th>
                  <th scope="col">Creation Date</th>
                  <th scope="col" className="text-center" width="110">
                    {editing === true || deleting === true ? "Action" : null}
                  </th>
                </tr>
              </thead>
              <ModalPopup
                showModalPopup={showModalPopup}
                onPopupClose={isShowPopup}
                id={deleteId}
                endPoint={"/keyword-exclusions/"}
                updateList={getData}
              ></ModalPopup>
              <tbody>
                {loading === false
                  ? getListData.map((res, i) => {
                      // Added this code for  WOII-185. Divya. March 19 2024.. Code Start
                      const isRowArchived = res.status === "DELETED";
                      // Divya. March 19 2024. Code End
                      return (
                        // Commenting the following code for WOII-185. Divya. March 19 2024.. Code Start
                        //<tr key={index}>
                        //Commenting end. Divya  March 19 2024..
                        //Added this code for  WOII-185. Divya. March 19 2024.. Code Start
                        <tr
                          key={res.id}
                          className={isRowArchived ? "archived" : ""}
                        >
                          {/* Divya. March 19 2024. Code End  */}
                          <td>{res.bid_source}</td>
                          <td>{res.rfx_category_name}</td>
                          <td style={{ maxWidth: 400 }}>
                            {res.exclusions.join(", ")}
                          </td>
                          <td>
                            {res.created_date
                              ? moment(res.created_date).format("MM-DD-YYYY")
                              : null}
                          </td>
                          <td className="text-center">
                            {/* Commenting the following code for WOII-185. Divya. March 19 2024.. Code Start */}
                            {/* {
                                                        editing === true ?
                                                            <button type="button" className="btn btn-action edit mr-1" onClick={() => _onEdit(res)}><i className="icofont-pencil-alt-2"></i></button> : null
                                                    }
                                                    {
                                                        deleting === true ?
                                                            <button type="button" className="btn btn-action delete" onClick={() => _onDelete(res.id)}><i className="icofont-archive"></i></button> : null
                                                    } */}
                            {/* Commenting end. Divya  March 19 2024..
                                                    Added this code for  WOII-185. Divya. March 19 2024.. Code Start */}
                            {isRowArchived ? (
                              <>
                                <button
                                  type="button"
                                  className="btn btn-action enable"
                                  onClick={() => enableRow(res.id)}
                                >
                                  <i
                                    className="icofont-check"
                                    style={{ fontSize: "18px" }}
                                  ></i>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-action delete ml-1"
                                  onClick={() => deleteRow(res.id)}
                                >
                                  <i className="icofont-archive"></i>
                                </button>
                              </>
                            ) : (
                              <>
                                {editing === true ? (
                                  <button
                                    type="button"
                                    className="btn btn-action edit mr-1"
                                    onClick={() => _onEdit(res)}
                                  >
                                    <i className="icofont-pencil-alt-2"></i>
                                  </button>
                                ) : null}
                                {deleting === true ? (
                                  <button
                                    type="button"
                                    className="btn btn-action delete"
                                    onClick={() => _onDelete(res.id)}
                                  >
                                    <i className="icofont-archive"></i>
                                  </button>
                                ) : null}
                              </>
                            )}
                            {/* Divya. March 19 2024. Code End  */}
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
          {getListData.length === 0 && loading === false && global.new_key ? (
            <SearchNoDataFound message={"No Keyword Exculsion Found"} />
          ) : getListData.length === 0 && loading === false ? (
            <NoData />
          ) : null}
          {<Loader isLoading={loading} />}
          <div className="text-md-right">
            <nav aria-label="Page navigation">
              <Pagination
                pages={pageCount}
                nextPage={nextPage}
                currentPage={currentPage}
                pageCount={pageCount}
                //Added this code for  WOII-120. Divya. November 11 2023.. Code Start
                rowsPerPage={handleRowsPerPageChange}
                // Divya. November 11 2023. Code End
              />
            </nav>
          </div>
        </div>
      </div>

      {/* Filter Modal Box */}
      <ReactModal
        initWidth={550}
        initHeight={"auto"}
        top={137.5}
        className={"contained-modal-title-vcenter"}
        onRequestClose={handleClose}
        isOpen={show}
        disableResize={true}
        disableKeystroke={true}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="">
            Filter Q&As
          </h5>
          <button type="button" className="close" onClick={handleClose}>
            <i className="icofont-close"></i>
          </button>
        </div>
        <div className="modal-body">
          <div className="row form-style">
            <div className="col-md-6">
              <div className="form-group">
                <label>Bid Source</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  name="bid_source"
                  value={formFields["bid_source"]}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>RFx Category</label>
                <select
                  id="disabledSelect"
                  className="form-control custom-select"
                  onChange={handleChange}
                  name="rfx_category"
                  value={formFields["rfx_category"]}
                >
                  <option value="" disabled>
                    Select your option
                  </option>
                  {RFxCategoryData.map((res, index) => {
                    return (
                      <option key={index} value={res.id}>
                        {res.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            {/* Added this code for  WOII-185. Divya. March 19 2024.. Code Start */}
            <div className="col-md-6">
              <div className="form-group">
                <label>Status</label>
                <select
                  id="disabledSelect"
                  className="form-control custom-select "
                  onChange={handleChange}
                  name="status"
                  value={formFields["status"]}
                >
                  <option value="" disabled>
                    Select your option
                  </option>
                  <option value="ACTIVE">Active</option>
                  <option Value="DELETED">Archived</option>
                </select>
              </div>
            </div>
            {/* Divya. March 19 2024. Code End  */}
            <div className="col-md-6">
              <div className="form-group">
                <label>Exclusion</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  name="exclusion"
                  value={formFields["exclusion"]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer ">
          <button
            type="button"
            className="btn btn-lg btn-brand-1 bg-danger"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className="btn btn-lg btn-brand-1"
            onClick={() => _onResetFilter()}
          >
            Reset
          </button>
          <button
            type="button"
            className="btn btn-lg btn-brand-1 bg-success"
            onClick={() => _onFilter()}
          >
            Search
          </button>
        </div>
      </ReactModal>
    </>
  );
};
export default KeywordEntries;
