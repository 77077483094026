import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { NavLink, generatePath } from "react-router-dom";
import Pagination from "../../../../components/Pagination";
import { APIMethod, TOAST_TIMEOUT } from "../../../../API/APIClient";
import Loader from "../../../../components/Loader";
import ModalPopup from "../../../../components/ModalPopUp";
import { useHistory } from "react-router";
import SearchNoDataFound from "../../../../components/SearchNoDataFound";
import { useToasts } from "react-toast-notifications";
import moment from "moment";
import ReactModal from "react-modal-resizable-draggable";

const PreRfx = (props) => {
  const [loading, setLoading] = useState(false);
  const [getListData, setGetListData] = useState([]);
  // Delete
  const [deleteId, setDeleteId] = useState("");
  const [showModalPopup, setShowModalPopup] = useState(false);
  // page
  const [pageCount, setPageCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  {
    /* Added this code for  WOII-120. Divya. November 02 2023.. Code Start */
  }
  const [rowsPerPage, setRowsPerPage] = useState(10);
  {
    /* Divya. November 02 2023. Code End */
  }
  // Add modal box
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const history = useHistory();
  const [adding, setAdding] = useState();
  const [editing, setEditing] = useState();
  const [deleting, setDeleting] = useState();
  const [RFxCategoryData, setRFxCategoryData] = useState([]);
  const [businessUnitData, setBusinessUnitData] = useState([]);
  const [selectAllValue, setSelectAllValue] = useState(false);
  const [formFields, setFormFields] = useState({
    // Commenting the following code for WOII-185. Divya. March 18 2024.. Code Start
    //businessUnitId: '', rfxClient: '', rfxNumber: '', rfxTitle: '', preRfxStatus: 'Pending', rfxCategory: '', fromDate: undefined, toDate: undefined,
    //Commenting end. Divya  March 18 2024..
    //Added this code for  WOII-185. Divya. March 18 2024.. Code Start
    businessUnitId: "",
    rfxClient: "",
    rfxNumber: "",
    rfxTitle: "",
    preRfxStatus: "Pending",
    rfxCategory: "",
    fromDate: undefined,
    toDate: undefined,
    status: "Active",
    posting: "Original"
    //Divya. March 18 2024. Code End
  });
  const { addToast } = useToasts();

  useEffect(() => {
    var value = localStorage.getItem("Pre_rfx_page");
    var final_data = value && JSON.parse(value);
    var data = JSON.parse(localStorage.getItem("user_Role"));
    getPreRFxList(final_data ? final_data.page : currentPage);
    if (final_data && final_data.pageCount) {
      setPageCount(final_data.pageCount);
    }
    checkPermission(data);
    getRfxCategoryList();
    getBusinessUnitList();
    // var filters = localStorage.getItem('pre_rfx_filter')
    // var filters_data = filters && JSON.parse(filters)
    // if (filters_data) {
    //     formFields.rfxClient = filters_data.rfxClient
    //     formFields.businessUnitId = filters_data.businessUnitId
    //     formFields.rfxNumber = filters_data.rfxNumber
    //     formFields.rfxCategory = filters_data.rfxCategory
    //     formFields.fromDate = filters_data.filter_start_date
    //     formFields.toDate = filters_data.filter_end_date
    //     formFields.rfxTitle = filters_data.rfxTitle
    //     formFields.preRfxStatus = filters_data.preRfxStatus ? filters_data.preRfxStatus : 'Pending'
    //     //Added this code for  WOII-185. Divya. March 18 2024.. Code Start
    //     formFields.status = filters_data.status ? filters_data.status : 'PENDING'
    //     //Divya. March 18 2024. Code End
    // }
  }, [rowsPerPage, currentPage]);

  const checkPermission = (data) => {
    const resObject = data.find((item) => item.name === "Manage Opportunities");
    const valObject = resObject.access.find((item) => item.name === "Pre-RFxs");
    setAdding(valObject.is_create);
    setEditing(valObject.is_update);
    setDeleting(valObject.is_delete);
  };

  const getPreRFxList = (page, key, type) => {
    localStorage.removeItem("Pre_rfx_page");
    setLoading(true);
    const new_key = key === undefined ? "" : key;
    var filter_start_date = "";
    if (formFields.fromDate) {
      filter_start_date =
        moment(formFields.fromDate).format("DD-MM-YYYY") ==
        moment(formFields.toDate).format("DD-MM-YYYY")
          ? ""
          : moment(formFields.fromDate).format("DD-MM-YYYY");
    }
    const filter_end_date =
      formFields.toDate === undefined
        ? ""
        : moment(formFields.toDate).format("DD-MM-YYYY");
    if (type === "FILTER") {
      var filterObject = {
        rfxClient: formFields.rfxClient,
        businessUnitId: formFields.businessUnitId,
        rfxNumber: formFields.rfxNumber,
        rfxCategory: formFields.rfxCategory,
        filter_start_date: formFields.fromDate,
        filter_end_date: formFields.toDate,
        rfxTitle: formFields.rfxTitle,
        preRfxStatus: formFields.preRfxStatus,
        //Added this code for  WOII-185. Divya. March 18 2024.. Code Start
        status: formFields.status,
        //Divya. March 18 2024. Code End
      };
      localStorage.setItem("pre_rfx_filter", JSON.stringify(filterObject));
    }
    var method = "GET";
    {
      /* Commenting the following code for WOII-120. Divya. November 02 2023.. Code Start */
    }
    //const apifetcherObj = APIMethod(`/pre-rfx/list/${page}?client=${formFields.rfxClient}&business_unit_id=${formFields.businessUnitId}&rfx_number=${formFields.rfxNumber}&category=${formFields.rfxCategory}&closing_from_date=${filter_start_date}&closing_to_date=${filter_end_date}&pre_rfx_status=${formFields.preRfxStatus}&keyword=` + encodeURIComponent(new_key || formFields.rfxTitle), null, method)
    {
      /* Commenting end. Divya. November 02 2023.. */
    }
    {
      /* Added this code for  WOII-120. Divya. November 02 2023.. Code Start */
    }
    var apifetcherObj = "";
    console.log(props.location.details, props.location.page);
    console.log(formFields.businessUnitId, "**************");
    if (props.location.page == "dashboard-pre-rfx") {
      console.log("entered to props");
      const formFieldsObject = {
        ...formFields,
        preRfxStatus: props.location.details.clickedval,
        businessUnitId: props.location.details.businessUnitId,
      };
      console.log("leaving---->");
      setFormFields(formFieldsObject);
      // Commenting the following code for WOII-185. Divya. March 18 2024.. Code Start
      //apifetcherObj = APIMethod(`/pre-rfx/list/${page}?client=${formFields.rfxClient}&business_unit_id=${props.location.details.businessUnitId}&rfx_number=${formFields.rfxNumber}&category=${formFields.rfxCategory}&closing_from_date=${props.location.details.fromdate}&closing_to_date=${props.location.details.todate}&pre_rfx_status=${props.location.details.clickedval}&limit=${rowsPerPage}&keyword=` + encodeURIComponent(new_key || formFields.rfxTitle), null, method)
      //Commenting end. Divya  March 18 2024..
      //Added this code for  WOII-185. Divya. March 18 2024.. Code Start
      apifetcherObj = APIMethod(
        `/pre-rfx/list/${page}?client=${
          formFields.rfxClient
        }&business_unit_id=${
          props.location.details.businessUnitId
        }&rfx_number=${formFields.rfxNumber}&category=${
          formFields.rfxCategory
        }&closing_from_date=${
          props.location.details.fromdate
        }&closing_to_date=${props.location.details.todate}&pre_rfx_status=${
          props.location.details.clickedval
        }&limit=${rowsPerPage}&status=${
          formFields.status === "Active" ? "PENDING" : formFields.status
        }&keyword=` + encodeURIComponent(new_key || formFields.rfxTitle),
        null,
        method
      );
      //Divya. March 18 2024. Code End
    } else {
      // Commenting the following code for WOII-185. Divya. March 18 2024.. Code Start
      //apifetcherObj = APIMethod(`/pre-rfx/list/${page}?client=${formFields.rfxClient}&business_unit_id=${formFields.businessUnitId}&rfx_number=${formFields.rfxNumber}&category=${formFields.rfxCategory}&closing_from_date=${filter_start_date}&closing_to_date=${filter_end_date}&pre_rfx_status=${formFields.preRfxStatus}&limit=${rowsPerPage}&keyword=` + encodeURIComponent(new_key || formFields.rfxTitle), null, method)
      //Commenting end. Divya  March 18 2024..
      //Added this code for  WOII-185. Divya. March 18 2024.. Code Start
      apifetcherObj = APIMethod(
        `/pre-rfx/list/${page}?client=${
          formFields.rfxClient
        }&business_unit_id=${formFields.businessUnitId}&rfx_number=${
          formFields.rfxNumber
        }&category=${
          formFields.rfxCategory
        }&closing_from_date=${filter_start_date}&closing_to_date=${filter_end_date}&pre_rfx_status=${
          formFields.preRfxStatus
        }&limit=${rowsPerPage}&status=${
          formFields.status === "Active" ? "PENDING" : formFields.status
        }&posting=${
          formFields.posting
        }&keyword=` + encodeURIComponent(new_key || formFields.rfxTitle),
        null,
        method
      );
      //Divya. March 18 2024. Code End
    }
    {
      /* Divya. November 02 2023. Code End */
    }
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];

        if (statusCode === 200) {
          console.log("printing data------>", data.list);
          setGetListData(data.list);
          for (var i in data.list) {
            data.list[i].isSelected = false;
          }
          if (page === 1) {
            setPageCount(data.count);
          }
          setCurrentPage(page);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getRfxCategoryList = () => {
    setLoading(true);
    var method = "GET";
    const apifetcherObj = APIMethod(`/rfx-categories/all/list`, null, method);
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];

        if (statusCode === 200) {
          setRFxCategoryData(data);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getBusinessUnitList = () => {
    setLoading(true);
    var method = "GET";
    const apifetcherObj = APIMethod(`/business-units/all/list`, null, method);
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];

        if (statusCode === 200) {
          setBusinessUnitData(data);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const nextPage = (pageNumber, selectedValue) => {
    setCurrentPage(pageNumber);
    // getPreRFxList(pageNumber)
    if (selectAllValue === true) {
      setSelectAllValue(false);
    }
  };

  {
    /* Added this code for  WOII-120. Divya. November 02 2023.. Code Start */
  }
  const handleRowsPerPageChange = (selectedValue) => {
    setRowsPerPage(selectedValue);
  };
  {
    /* Divya. November 02 2023. Code End */
  }

  const _onDelete = (id) => {
    setDeleteId(id);
    isShowPopup(true);
  };

  const isShowPopup = (status) => {
    setShowModalPopup(status);
  };

  const _onEdit = (val) => {
    localStorage.setItem(
      "Pre_rfx_page",
      JSON.stringify({ page: currentPage, pageCount: pageCount })
    );
    val.id && history.push(generatePath("/edit-pre-rfx/:id", { id: val.id }));
  };

  const Search = (key) => {
    getPreRFxList(1, key);
  };

  const downaloadrfx = async () => {
    addToast("New RFx Extraction started", {
      autoDismissTimeout: TOAST_TIMEOUT,
      appearance: "info",
      autoDismiss: true,
    });
    // const response = await fetch("http://3.88.88.150:5050/rfx_prod");

    const response = await fetch("http://3.88.88.150:5050/rfx_stag");
    // console.log(response);
    if (response.status == 200) {
      // throw new Error('Network response was not ok');
      addToast("New  RFx Extraction completed", {
        autoDismissTimeout: TOAST_TIMEOUT,
        appearance: "success",
        autoDismiss: true,
      });
    } else if (response.status == 400) {
      addToast("New RFx Extraction failed due to chromedriver error. ", {
        autoDismissTimeout: TOAST_TIMEOUT,
        appearance: "success",
        autoDismiss: true,
      });
    } else {
      addToast("New RFx Extraction failed due to some errors.", {
        autoDismissTimeout: TOAST_TIMEOUT,
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value,
    };
    setFormFields(formFieldsObject);
  };

  const dateValidation = (date) => {
    if (formFields.fromDate) {
      const formFieldsObject = {
        ...formFields,
        toDate: date,
      };
      setFormFields(formFieldsObject);
    } else {
      addToast("Please select RFx closing from date first", {
        autoDismissTimeout: TOAST_TIMEOUT,
        appearance: "warning",
        autoDismiss: true,
      });
    }
  };

  const setFromDate = (date) => {
    const formFieldsObject = {
      ...formFields,
      fromDate: date,
    };
    setFormFields(formFieldsObject);
  };

  const _onResetFilter = (type) => {
    if (type === "BUSINESS_UNIT_TYPE") {
      formFields.businessUnitId = "";
    } else if (type === "RFX_CLIENT") {
      formFields.rfxClient = "";
    } else if (type === "RFX_NUMBER") {
      formFields.rfxNumber = "";
    } else if (type === "RFX_TITLE") {
      formFields.rfxTitle = "";
    } else if (type === "PRE_RFX_STATUS") {
      formFields.preRfxStatus = "";
    } else if (type === "RFX_CATEGORY") {
      formFields.rfxCategory = "";
    } else if (type === "FROM_DATE") {
      formFields.fromDate = undefined;
    } else if (type === "TO_DATE") {
      formFields.toDate = undefined;
    } else if (type === "POSTING") {
      formFields.posting = ""
    }
    //Added this code for  WOII-185. Divya. March 18 2024.. Code Start
    else if (type === "STATUS") {
      formFields.status = "";
    }
    //Divya. March 18 2024. Code End
    else {
      formFields.businessUnitId = "";
      formFields.rfxClient = "";
      formFields.rfxNumber = "";
      formFields.rfxTitle = "";
      formFields.preRfxStatus = "";
      //Added this code for  WOII-185. Divya. March 18 2024.. Code Start
      formFields.status = "";
      //Divya. March 18 2024. Code End
      formFields.rfxCategory = "";
      formFields.fromDate = undefined;
      formFields.toDate = undefined;
      handleClose();
    }
    getPreRFxList(1, "", "FILTER");
  };

  const _onFilter = () => {
    props.location.page = "";
    getPreRFxList(1, "", "FILTER");
    handleClose();
  };

  const getData = () => {
    getPreRFxList(currentPage);
  };

  const selectedPrerfx = (id) => {
    setSelectAllValue(!selectAllValue);
    for (var i in getListData) {
      if (id == getListData[i].id) {
        getListData[i].isSelected = !getListData[i].isSelected;
      }
    }
    setGetListData([...getListData]);
  };

  const onDeleteAll = () => {
    var array = [];
    var count = 0;
    for (var i in getListData) {
      if (getListData[i].isSelected == true) {
        count = count + 1;
        array.push(getListData[i].id);
      }
    }
    if (count == 0) {
      addToast("Please select first", {
        autoDismissTimeout: TOAST_TIMEOUT,
        appearance: "error",
        autoDismiss: true,
      });
    } else {
      setLoading(true);
      var method = "DELETE";
      var body = JSON.stringify(array);
      const apifetcherObj = APIMethod("/pre-rfx/delete/bulk", body, method);
      apifetcherObj
        .then((response) => {
          return Promise.all([response.status, response.json()]);
        })
        .then((res) => {
          let statusCode = res[0];
          let data = res[1];

          if (statusCode === 200) {
            setSelectAllValue(false);
            setLoading(false);
            getPreRFxList(1);
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  // Added this code for  WOII-185. Divya. March 18 2024.. Code Start
  const enableRow = (id) => {
    var method = "PUT";
    const apifetcherObj = APIMethod(`/pre-rfx/${id}/enable`, null, method);
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];

        if (statusCode === 200) {
          getPreRFxList(currentPage);
          isShowPopup(false);
        }
      })
      .catch((error) => {
        console.error("Error enabling pre-rfx:", error);
      });
  };

  const deleteRow = (id) => {
    var method = "DELETE";
    const apifetcherObj = APIMethod(`/pre-rfx/${id}/delete`, null, method);
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];

        if (statusCode === 200) {
          getPreRFxList(currentPage);
          isShowPopup(false);
        }
      })
      .catch((error) => {
        console.error("Error in deleting pre-rfx:", error);
      });
  };
  //Divya. March 18 2024. Code End
  const onSelectAll = () => {
    for (var i in getListData) {
      getListData[i].isSelected = !getListData[i].isSelected;
    }
    setSelectAllValue(true);
    setGetListData([...getListData]);
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content">
          <div className="row mb-4 align-items-center justify-content-between">
            <div className="col-lg-3 col-md-12 d-md-flex mb-md-0 mb-2 mb-lg-0 mb-md-3">
              <h2 className="page-title mb-md-0">Pre-RFxs</h2>
            </div>
            <div className="col-lg-6 col-md-12 text-right d-md-flex align-items-center justify-content-center">
              <div className="input-group page-cont-search mr-md-3 mb-md-0 mb-2">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search by RFx title"
                  onChange={(event) => Search(event.target.value)}
                />
                <span className="input-group-text text-secondary">
                  <i className="icofont-search-1"></i>
                </span>
              </div>
              <button
                type="button"
                className="btn btn-brand-1 btn-h-40 mr-3"
                onClick={handleShow}
              >
                <i className="icofont-filter mr-2"></i> Filter
              </button>
              {adding === true ? (
                <NavLink
                  to={{ pathname: "/add-pre-rfx", state: { page: "preRfx" } }}
                  className="btn btn-brand-1 bg-success px-4"
                >
                  + Add
                </NavLink>
              ) : null}
            </div>
          </div>
          <div className="col-12 text-right">
            <button
              type="button"
              style={{ marginRight: "20px" }}
              className="btn btn-brand-1 bg-success "
              onClick={downaloadrfx}
            >
              Download new Pre-RFx
            </button>
            <button
              type="button"
              className="btn delete-all-button"
              onClick={() => onDeleteAll()}
              disabled={!deleting}
            >
              Archive Selected
            </button>
          </div>

          <div className="mb-2 filter-by">
            {formFields.businessUnitId ? (
              <div className="badge">
                <span className="lable">Business Unit</span>
                {businessUnitData.map((res, index) => {
                  return (
                    <span key={index} className="value">
                      {formFields.businessUnitId == res.id ? res.name : null}
                    </span>
                  );
                })}
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("BUSINESS_UNIT_TYPE")}
                ></i>
              </div>
            ) : null}
            {formFields.rfxClient ? (
              <div className="badge">
                <span className="lable">Client / Agency</span>
                <span className="value">{formFields.rfxClient}</span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("RFX_CLIENT")}
                ></i>
              </div>
            ) : null}
            {formFields.rfxNumber ? (
              <div className="badge">
                <span className="lable">RFx Number</span>
                <span className="value">{formFields.rfxNumber}</span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("RFX_NUMBER")}
                ></i>
              </div>
            ) : null}
            {formFields.rfxTitle ? (
              <div className="badge">
                <span className="lable">RFx Title</span>
                <span className="value">{formFields.rfxTitle}</span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("RFX_TITLE")}
                ></i>
              </div>
            ) : null}
            {formFields.preRfxStatus ? (
              <div className="badge">
                <span className="lable">Pre-RFx Status</span>
                <span className="value">
                  {formFields.preRfxStatus == "APPROVED"
                    ? "Go"
                    : formFields.preRfxStatus == "PENDING_APPROVAL"
                    ? "Sent for Approval"
                    : formFields.preRfxStatus == "PUSH_BACK"
                    ? "Pushedback"
                    : formFields.preRfxStatus == "DECLINED"
                    ? "No-Go"
                    : formFields.preRfxStatus}
                </span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("PRE_RFX_STATUS")}
                ></i>
              </div>
            ) : null}
            {/* Added this code for  WOII-185. Divya. March 18 2024.. Code Start */}
            {formFields.status ? (
              <div className="badge">
                <span className="lable">Status</span>
                <span className="value">
                  {formFields.status == "PENDING"
                    ? "Active"
                    : formFields.status == "DELETED"
                    ? "Archived"
                    : formFields.status}
                </span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("STATUS")}
                ></i>
              </div>
            ) : null}
            {/* Divya. March 18 2024. Code End  */}
            {formFields.posting ? (
              <div className="badge">
                <span className="lable">Posting</span>
                <span className="value">
                {formFields.posting}
                </span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("POSTING")}
                ></i>
              </div>
            ) : null}
            {formFields.rfxCategory ? (
              <div className="badge">
                <span className="lable">RFx Category</span>
                {RFxCategoryData.map((res, index) => {
                  return (
                    <span key={index} className="value">
                      {formFields.rfxCategory == res.id ? res.name : null}
                    </span>
                  );
                })}
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("RFX_CATEGORY")}
                ></i>
              </div>
            ) : null}
            {formFields.fromDate ? (
              <div className="badge">
                <span className="lable">RFx Closing From Date</span>
                <span className="value">
                  {moment(formFields.fromDate).format("MM-DD-YYYY")}
                </span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("FROM_DATE")}
                ></i>
              </div>
            ) : null}
            {formFields.toDate ? (
              <div className="badge">
                <span className="lable">RFx Closing To Date</span>
                <span className="value">
                  {moment(formFields.toDate).format("MM-DD-YYYY")}
                </span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("TO_DATE")}
                ></i>
              </div>
            ) : null}
          </div>

          <div
            className="table-responsive "
            style={{
              height: "400px",
              overflowY: "auto",
              marginBottom: "20px",
            }}
          >
            <table
              className="table table-style-1 mb-4 table-md-responsive"
              id=""
            >
              <thead className="sticky-top" style={{ zIndex: 10 }}>
                <tr>
                  <th scope="col">
                    <input
                      type="checkbox"
                      onChange={() => onSelectAll()}
                      checked={selectAllValue}
                    />
                  </th>
                  <th scope="col">BU</th>
                  <th scope="col" style={{ textTransform: "none" }}>
                    TITLE
                  </th>
                  <th scope="col" style={{ textTransform: "none" }}>
                    STATUS
                  </th>
                  <th scope="col">CLIENT</th>
                  <th scope="col">State</th>
                  <th scope="col">Posted On</th>
                  <th scope="col">Posting</th>
                  <th scope="col" style={{ textTransform: "none" }}>
                    RFx DUE
                  </th>
                  <th scope="col" className="text-center" width="160">
                    Action
                  </th>
                </tr>
              </thead>
              <ModalPopup
                showModalPopup={showModalPopup}
                onPopupClose={isShowPopup}
                id={deleteId}
                endPoint={"/pre-rfx/"}
                updateList={getData}
              ></ModalPopup>
              <tbody>
                {loading === false
                  ? getListData.map((res, index) => {
                      // Added this code for  WOII-185. Divya. March 18 2024.. Code Start
                      const isRowArchived = res.status === "DELETED";
                      // Divya. March 18 2024. Code End
                      return (
                        // Commenting the following code for WOII-185. Divya. March 18 2024.. Code Start
                        //<tr key={index}>
                        //Commenting end. Divya  March 18 2024..
                        //Added this code for  WOII-185. Divya. March 18 2024.. Code Start
                        <tr
                          key={res.id}
                          className={isRowArchived ? "archived" : ""}
                        >
                          {/* Divya. March 18 2024. Code End  */}
                          <td>
                            <input
                              type="checkbox"
                              onChange={() => selectedPrerfx(res.id)}
                              checked={res.isSelected}
                            />
                          </td>
                          <td>{res.business_name}</td>
                          <td title={res.title}>
                            {res.title
                              ? `${res.title.replace(/(.{30})..+/, "$1…")}`
                              : ""}
                          </td>
                          <td>
                            {res.rfx_status === "APPROVED"
                              ? "Go"
                              : res.rfx_status === "PENDING_APPROVAL"
                              ? "Sent for Approval"
                              : res.rfx_status === "PENDING"
                              ? "Pending"
                              : res.rfx_status === "PUSH_BACK"
                              ? "Pushed Back"
                              : res.rfx_status === "DECLINED"
                              ? "No-Go"
                              : res.rfx_status === "OPEN"
                              ? "Open"
                              : null}
                          </td>
                          <td>{res.rfx_client_agency}</td>
                          <td>{res.state_name}</td>
                          <td>
                            {res.published &&
                              moment(res.published, "DD/MM/YYYY").format(
                                "MM-DD-YYYY"
                              )}
                          </td>
                          <td>{res.posting || "Addendum"}</td>
                          <td>
                            {res.due &&
                              moment(res.due, "DD/MM/YYYY hh:mm:ss").format(
                                "MM-DD-YYYY"
                              )}
                          </td>
                          <td className="text-center" width="160">
                            {/* Commenting the following code for WOII-185. Divya. March 18 2024.. Code Start */}
                            {/* <button type="button" className="btn btn-action edit " onClick={() => _onEdit(res)} disabled={!editing}><i className="icofont-pencil-alt-2"  ></i></button>
                                                      <button type="button" className="btn btn-action delete ml-1" onClick={() => _onDelete(res.id)} disabled={!deleting}><i className="icofont-archive"></i></button> */}
                            {/* Commenting end. Divya  March 18 2024..  */}
                            {/* Added this code for  WOII-185. Divya. March 18 2024.. Code Start */}
                            {isRowArchived ? (
                              <>
                                <button
                                  type="button"
                                  className="btn btn-action enable"
                                  onClick={() => enableRow(res.id)}
                                >
                                  <i
                                    className="icofont-check"
                                    style={{ fontSize: "18px" }}
                                  ></i>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-action delete ml-1"
                                  onClick={() => deleteRow(res.id)}
                                >
                                  <i className="icofont-archive"></i>
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  type="button"
                                  className="btn btn-action edit "
                                  onClick={() => _onEdit(res)}
                                  disabled={!editing}
                                >
                                  <i className="icofont-pencil-alt-2"></i>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-action delete ml-1"
                                  onClick={() => _onDelete(res.id)}
                                  disabled={!deleting}
                                >
                                  <i className="icofont-archive"></i>
                                </button>
                              </>
                            )}
                            {/* Divya. March 18 2024. Code End  */}
                          </td>
                        </tr>
                      );
                      // }
                    })
                  : null}
              </tbody>
            </table>
            {getListData.length === 0 && loading === false && global.new_key ? (
              <SearchNoDataFound message={"No Pre RFx Found"} />
            ) : getListData.length === 0 && loading === false ? (
              <SearchNoDataFound message={"No Pre RFx Found"} />
            ) : null}
            {<Loader isLoading={loading} />}
          </div>
          <div className="text-md-right">
            <nav aria-label="Page navigation">
              <Pagination
                pages={pageCount}
                nextPage={nextPage}
                currentPage={currentPage}
                pageCount={pageCount}
                //Added this code for  WOII-120. Divya. November 02 2023.. Code Start
                rowsPerPage={handleRowsPerPageChange}
                // Divya. November 02 2023. Code End
              />
            </nav>
          </div>
        </div>
      </div>

      {/* Filter Modal Box */}
      <ReactModal
        initWidth={600}
        initHeight={590}
        top={137.5}
        className={"contained-modal-title-vcenter"}
        onRequestClose={handleClose}
        isOpen={show}
        disableResize={true}
        disableKeystroke={true}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="">
            Filter Search Pre-RFxs
          </h5>
          <button type="button" className="close" onClick={handleClose}>
            <i className="icofont-close"></i>
          </button>
        </div>
        <div className="modal-body">
          <div className="row form-style">
            <div className="col-md-6">
              <div className="form-group">
                <label>Business Unit</label>
                <select
                  id="disabledSelect"
                  className="form-control custom-select"
                  onChange={handleChange}
                  name="businessUnitId"
                  value={formFields["businessUnitId"]}
                >
                  <option value="" disabled>
                    Select your option
                  </option>
                  {businessUnitData.map((res, index) => {
                    return (
                      <option key={index} value={res.id}>
                        {res.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Client / Agency</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  name="rfxClient"
                  value={formFields["rfxClient"]}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>RFx Number</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  name="rfxNumber"
                  value={formFields["rfxNumber"]}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Title</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  name="rfxTitle"
                  value={formFields["rfxTitle"]}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Pre-RFx Status</label>
                <select
                  id="disabledSelect"
                  className="form-control custom-select "
                  onChange={handleChange}
                  name="preRfxStatus"
                  value={formFields["preRfxStatus"]}
                >
                  <option value="" disabled>
                    Select your option
                  </option>
                  <option>Open</option>
                  <option>Pending</option>
                  <option value="APPROVED">Go</option>
                  <option Value="DECLINED">No-Go</option>
                  <option value={"PUSH_BACK"}>Pushedback</option>
                  <option value="PENDING_APPROVAL">Sent for Approval</option>
                </select>
              </div>
            </div>
            {/* Added this code for  WOII-185. Divya. March 18 2024.. Code Start */}
            <div className="col-md-6">
              <div className="form-group">
                <label>Status</label>
                <select
                  id="disabledSelect"
                  className="form-control custom-select "
                  onChange={handleChange}
                  name="status"
                  value={formFields["status"]}
                >
                  <option value="" disabled>
                    Select your option
                  </option>
                  <option value="PENDING">Active</option>
                  <option Value="DELETED">Archived</option>
                </select>
              </div>
            </div>
            {/* Divya. March 18 2024. Code End  */}
            <div className="col-md-6">
              <div className="form-group">
                <label>Category</label>
                <select
                  id="disabledSelect"
                  className="form-control custom-select"
                  onChange={handleChange}
                  name="rfxCategory"
                  value={formFields["rfxCategory"]}
                >
                  <option value="" disabled>
                    Select your option
                  </option>
                  {RFxCategoryData.map((res, index) => {
                    return (
                      <option key={index} value={res.id}>
                        {res.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label>Posting</label>
                <select
                  id="disabledSelect"
                  className="form-control custom-select"
                  onChange={handleChange}
                  name="posting"
                  value={formFields["posting"]}
                >
                  <option value="" disabled>
                    Select your option
                  </option>
                  <option value="Original">Original</option>
                  <option Value="Addendum">Addendum</option>
                </select>
              </div>
            </div>
            
            <div className="col-md-6">
              <div className="form-group">
                <label>RFx Closing From Date</label>
                <DatePicker
                  selected={formFields.fromDate}
                  onChange={(date) => setFromDate(date)}
                  className="form-control"
                  placeholder="select Date"
                  dateFormat="MM-dd-yyyy"
                  scrollableYearDropdown
                  showYearDropdown
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>RFx Closing To Date </label>
                <DatePicker
                  selected={formFields.toDate}
                  onChange={(date) => dateValidation(date)}
                  className="form-control"
                  placeholder="select Date"
                  dateFormat="MM-dd-yyyy"
                  minDate={formFields.fromDate}
                  scrollableYearDropdown
                  showYearDropdown
                />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer ">
          <button
            type="button"
            className="btn btn-lg btn-brand-1 bg-danger"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className="btn btn-lg btn-brand-1"
            onClick={() => _onResetFilter()}
          >
            Reset
          </button>
          <button
            type="button"
            className="btn btn-lg btn-brand-1 bg-success"
            onClick={() => _onFilter()}
          >
            Search
          </button>
        </div>
      </ReactModal>
    </>
  );
};
export default PreRfx;
