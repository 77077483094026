import React, { useEffect, useState } from "react";
import { NavLink, generatePath } from "react-router-dom";
import { APIMethod } from "../../../../API/APIClient";
import ModalPopup from "../../../../components/ModalPopUp";
import Pagination from "../../../../components/Pagination";
import Loader from "../../../../components/Loader";
import { useHistory } from "react-router";
import NoData from "../../../../components/NoDataFound";
import SearchNoDataFound from "../../../../components/SearchNoDataFound";
import ReactModal from "react-modal-resizable-draggable";

const ManageUsers = () => {
  // Added this code for  WOII-185. Divya. March 22 2024.. Code Start
  const [formFields, setFormFields] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_no: "",
    status: "ACTIVE",
  });
  const [showFilter, setShowFilter] = useState(false);
  const handleCloseFilter = () => setShowFilter(false);
  // Divya. March 22 2024. Code End
  const [loading, setLoading] = useState(false);
  const [getListData, setGetListData] = useState([]);
  // Delete
  const [deleteId, setDeleteId] = useState("");
  // Delete PopUp
  const [showModalPopup, setShowModalPopup] = useState(false);
  // Pagination
  const [pageCount, setPageCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  {
    /* Added this code for  WOII-120. Divya. November 07 2023.. Code Start */
  }
  const [rowsPerPage, setRowsPerPage] = useState(10);
  {
    /* Divya. November 07 2023. Code End */
  }
  const history = useHistory();
  const [adding, setAdding] = useState();
  const [editing, setEditing] = useState();
  const [deleting, setDeleting] = useState();

  useEffect(() => {
    //Commenting the following code for WOII-120. Divya. November 07 2023.. Code Start
    //getUserList(1)
    //Commenting end. Divya. November 07 2023..
    //Added this code for  WOII-120. Divya. November 07 2023.. Code Start
    getUserList(currentPage);
    {
      /* Divya. November 07 2023. Code End */
    }
    var data = JSON.parse(localStorage.getItem("user_Role"));
    checkPermission(data);
    //Commenting the following code for WOII-120. Divya. November 07 2023.. Code Start
    //}, [])
    //Commenting end. Divya. November 07 2023..
    //Added this code for  WOII-120. Divya. November 07 2023.. Code Start
  }, [rowsPerPage, currentPage]);
  {
    /* Divya. November 07 2023. Code End */
  }

  const checkPermission = (data) => {
    const resObject = data.find((item) => item.name === "Manage Security");
    const valObject = resObject.access.find((item) => item.name === "Users");
    setAdding(valObject.is_create);
    setEditing(valObject.is_update);
    setDeleting(valObject.is_delete);
  };

  const getUserList = (page, key) => {
    setLoading(true);
    const new_key = key === undefined ? "" : key;
    global.new_key = key === undefined ? "" : key;
    var method = "GET";
    //Commenting the following code for WOII-120. Divya. November 07 2023.. Code Start
    //const apifetcherObj = APIMethod(`/users/list/${page}?keyword=` + new_key, null, method)
    //Commenting end. Divya. November 07 2023..
    //Added this code for  WOII-120. Divya. November 07 2023.. Code Start
    // Added "firstname/lastName/emai/phone_no/status" parameters for WOII-185 by Divya
    const apifetcherObj = APIMethod(
      `/users/list/${page}?limit=${rowsPerPage}&first_name=${formFields.first_name}&last_name=${formFields.last_name}&email=${formFields.email}&phone_no=${formFields.phone_no}&status=${formFields.status}&keyword=` +
        new_key,
      null,
      method
    );
    {
      /* Divya. November 07 2023. Code End */
    }
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];

        if (statusCode === 200) {
          setGetListData(data.list);
          console.log(JSON.stringify(data.list, null, 4));
          if (page === 1) {
            setPageCount(data.count);
          }
          setCurrentPage(page);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const nextPage = (pageNumber) => {
    //Commenting the following code for WOII-120. Divya. November 07 2023.. Code Start
    //getUserList(pageNumber)
    //Commenting end. Divya. November 07 2023..
    //Added this code for  WOII-120. Divya. November 07 2023.. Code Start
    setCurrentPage(pageNumber);
    {
      /* Divya. November 07 2023. Code End */
    }
  };

  {
    /* Added this code for  WOII-120. Divya. November 07 2023.. Code Start */
  }
  const handleRowsPerPageChange = (selectedValue) => {
    setRowsPerPage(selectedValue);
  };
  {
    /* Divya. November 07 2023. Code End */
  }

  const _onDelete = (id) => {
    setDeleteId(id);
    isShowPopup(true);
  };

  const isShowPopup = (status) => {
    setShowModalPopup(status);
  };

  const Search = (key) => {
    getUserList(1, key);
  };

  const _onEdit = (val) => {
    setFormFields({ ...formFields });
    console.log(val);
    val && history.push(generatePath("/edit-user-role/:val", { val }));
  };

  const getData = () => {
    getUserList(1);
  };

  // Added this code for  WOII-185. Divya. March 22 2024.. Code Start
  const handleChange = (event) => {
    console.log("event:::::::::" + event);
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value,
    };
    setFormFields(formFieldsObject);
  };
  const _onResetFilter = (type) => {
    if (type === "FIRST_NAME") {
      formFields.description = "";
    } else if (type === "LAST_NAME") {
      formFields.description = "";
    } else if (type === "EMAIL") {
      formFields.description = "";
    } else if (type === "PHONE_NO") {
      formFields.description = "";
    } else if (type === "STATUS") {
      formFields.status = "";
    } else {
      formFields.status = "";
      handleCloseFilter();
    }
    getUserList(1, "");
  };
  const _onFilter = () => {
    getUserList(1, "");
    handleCloseFilter();
  };

  const enableRow = (id) => {
    var method = "PUT";
    const apifetcherObj = APIMethod(`/users/${id}/enable`, null, method);
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];
        if (statusCode === 200) {
          getUserList(currentPage);
          isShowPopup(false);
        }
      })
      .catch((error) => {
        console.error("Error enabling rfx-categories:", error);
      });
  };
  const deleteRow = (id) => {
    var method = "DELETE";
    const apifetcherObj = APIMethod(`/users/${id}/delete`, null, method);
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];
        if (statusCode === 200) {
          getUserList(currentPage);
          isShowPopup(false);
        }
      })
      .catch((error) => {
        console.error("Error in deleting rfx-categories:", error);
      });
  };
  const handleClose = () => {
    setId("");
    formFields.first_name = "";
    formFields.last_name = "";
    formFields.email = "";
    formFields.phone_no = "";
    setCodeCount(0);
    setShow(false);
    setFlag(false);
  };
  // Divya. March 22 2024. Code End

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content">
          <div className="row mb-3 align-items-center justify-content-between">
            <div className="col-lg-5 col-md-12 d-md-flex mb-md-0 mb-2 mb-lg-0 mb-md-3">
              <h2 className="page-title mb-md-0">Users </h2>
            </div>
            <div className="col-lg-5 col-md-12 text-right d-md-flex align-items-center justify-content-center">
              <div className="input-group page-cont-search mr-md-3 mb-md-0 mb-2">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search by user"
                  onChange={(event) => Search(event.target.value)}
                />
                <span className="input-group-text text-secondary">
                  <i className="icofont-search-1"></i>
                </span>
              </div>
              {/*  Added this code for  WOII-185. Divya. March 22 2024.. Code Start */}
              <button
                type="button"
                className="btn btn-brand-1 btn-h-40 mr-3"
                onClick={() => setShowFilter(true)}
              >
                <i className="icofont-filter mr-2"></i> Filter
              </button>
              {/*  Divya. March 22 2024. Code End  */}
              {adding === true ? (
                <NavLink
                  to="/add-user"
                  className="btn btn-brand-1 bg-success px-4"
                >
                  + Add
                </NavLink>
              ) : null}
            </div>
          </div>

          <div className="mb-2 filter-by">
            {/*  Added this code for  WOII-185. Divya. March 22 2024.. Code Start */}
            {formFields.description ? (
              <div className="badge">
                <span className="lable">First Name</span>
                <span className="value">{formFields.first_name}</span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("FIRST_NAME")}
                ></i>
              </div>
            ) : null}
            {formFields.description ? (
              <div className="badge">
                <span className="lable">Last Name</span>
                <span className="value">{formFields.last_name}</span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("LAST_NAME")}
                ></i>
              </div>
            ) : null}
            {formFields.description ? (
              <div className="badge">
                <span className="lable">Email</span>
                <span className="value">{formFields.email}</span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("EMAIL")}
                ></i>
              </div>
            ) : null}
            {formFields.description ? (
              <div className="badge">
                <span className="lable">Phone Number</span>
                <span className="value">{formFields.phone_no}</span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("PHONE_NO")}
                ></i>
              </div>
            ) : null}
            {formFields.status ? (
              <div className="badge">
                <span className="lable">Status</span>
                <span className="value">
                  {formFields.status == "ACTIVE"
                    ? "Active"
                    : formFields.status == "DELETED"
                    ? "Archived"
                    : formFields.status}
                </span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("STATUS")}
                ></i>
              </div>
            ) : null}
          </div>
          {/*  Divya. March 22 2024. Code End  */}

          <div
            className="table-responsive "
            style={{
              height: "500px",
              overflowY: "auto",
              marginBottom: "20px",
            }}
          >
            <table className="table table-style-1 table-responsive-md mb-4 table reports">
              <thead className="sticky-top" style={{ zIndex: 10 }}>
                <tr>
                  <th scope="col">User Id</th>
                  <th scope="col">User Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Default BU</th>
                  <th scope="col">Role </th>
                  <th scope="col">Default Category</th>
                  <th scope="col" className="text-center" width="110">
                    {editing === true || deleting === true ? "Action" : null}
                  </th>
                </tr>
              </thead>
              <ModalPopup
                showModalPopup={showModalPopup}
                onPopupClose={isShowPopup}
                id={deleteId}
                endPoint={"/users/"}
                updateList={getData}
              />
              <tbody>
                {loading === false
                  ? getListData.map((res, index) => {
                      {
                        /*  Added this code for  WOII-185. Divya. March 22 2024.. Code Start */
                      }
                      const isRowArchived = res.status === "DELETED";
                      {
                        /*  Divya. March 22 2024. Code End  */
                      }
                      return (
                        // Added this code for  WOII-185. Divya. March 22 2024.. Code Start
                        <tr
                          key={index}
                          className={isRowArchived ? "archived" : ""}
                        >
                          {/* Divya. March 22 2024. Code End */}
                          <td>{res.id}</td>
                          <td>
                            {res.first_name} {res.last_name}
                          </td>
                          <td>{res.email}</td>
                          <td>
                            {res.user_business_unit.map((buss) => {
                              return buss.is_checked === false
                                ? null
                                : buss.name;
                            })}
                          </td>
                          <td style={{ width: 10 }}>
                            {res.user_roles.map((role, i) => {
                              if (i == 0) {
                                return role.name;
                              } else {
                                return "," + role.name;
                              }
                            })}
                          </td>
                          <td>
                            {res.rfx_category_list.map((cat) => {
                              return cat.is_checked === false ? null : cat.name;
                            })}
                          </td>
                          <td className="text-center">
                            {/* Commenting the following code for WOII-185. Divya. March 22 2024.. Code Start
                                                    {
                                                        editing === true ?
                                                            res.id === 217 ? null :
                                                                <button type="button" className="btn btn-action edit mr-1" onClick={() => _onEdit(res.id)}><i className="icofont-pencil-alt-2"></i></button> : null
                                                    }
                                                    {
                                                        deleting === true ?
                                                            res.id === 217 ? null :
                                                                <button type="button" className="btn btn-action delete" onClick={() => _onDelete(res.id)}><i className="icofont-archive"></i></button> : null
                                                    }
                                                    Commenting end. Divya  March 22 2024.. */}
                            {/* Added this code for  WOII-185. Divya. March 22 2024.. Code Start */}
                            {isRowArchived ? (
                              <>
                                <button
                                  type="button"
                                  className="btn btn-action enable"
                                  onClick={() => enableRow(res.id)}
                                >
                                  <i
                                    className="icofont-check"
                                    style={{ fontSize: "18px" }}
                                  ></i>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-action delete ml-1"
                                  onClick={() => deleteRow(res.id)}
                                >
                                  <i className="icofont-archive"></i>
                                </button>
                              </>
                            ) : (
                              <>
                                {editing === true ? (
                                  <button
                                    type="button"
                                    className="btn btn-action edit mr-1"
                                    onClick={() => _onEdit(res.id)}
                                  >
                                    <i className="icofont-pencil-alt-2"></i>
                                  </button>
                                ) : null}
                                {deleting === true ? (
                                  <button
                                    type="button"
                                    className="btn btn-action delete"
                                    onClick={() => _onDelete(res.id)}
                                  >
                                    <i className="icofont-archive"></i>
                                  </button>
                                ) : null}
                              </>
                            )}
                            {/* Divya. March 22 2024. Code End */}
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
          {getListData.length === 0 && loading === false && global.new_key ? (
            <SearchNoDataFound message={"No Manage User Found"} />
          ) : getListData.length === 0 && loading === false ? (
            <NoData />
          ) : null}
          {<Loader isLoading={loading} />}
          <div className="text-md-right">
            <nav aria-label="Page navigation">
              <Pagination
                pages={pageCount}
                endPoint={"/users/list/"}
                nextPage={nextPage}
                currentPage={currentPage}
                pageCount={pageCount}
                //Added this code for  WOII-120. Divya. November 07 2023.. Code Start
                rowsPerPage={handleRowsPerPageChange}
                // Divya. November 07 2023. Code End
              />
            </nav>
          </div>
        </div>
      </div>

      {/*Added this code for  WOII-185. Divya. March 22 2024.. Code Start */}

      {/* Filter model */}

      <ReactModal
        initWidth={550}
        initHeight={"auto"}
        top={137.5}
        className={"contained-modal-title-vcenter"}
        onRequestClose={handleClose}
        isOpen={showFilter}
        disableResize={true}
        disableKeystroke={true}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="">
            Filter
          </h5>
          <button type="button" className="close" onClick={handleCloseFilter}>
            <i className="icofont-close"></i>
          </button>
        </div>
        <div className="modal-body">
          <div className="row form-style">
            <div className="col-md-6">
              <div className="form-group">
                <label>First Name</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  name="first_name"
                  value={formFields["first_name"]}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  name="last_name"
                  value={formFields["last_name"]}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Email</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  name="email"
                  value={formFields["email"]}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Phone Number</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  name="phone_no"
                  value={formFields["phone_no"]}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Status</label>
                <select
                  id="disabledSelect"
                  className="form-control custom-select "
                  onChange={handleChange}
                  name="status"
                  value={formFields["status"]}
                >
                  <option value="" disabled>
                    Select your option
                  </option>
                  <option value="ACTIVE">Active</option>
                  <option Value="DELETED">Archived</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer ">
          <button
            type="button"
            className="btn btn-lg btn-brand-1 bg-danger"
            onClick={handleCloseFilter}
          >
            Cancel
          </button>
          <button
            className="btn btn-lg btn-brand-1"
            onClick={() => _onResetFilter()}
          >
            Reset
          </button>
          <button
            type="button"
            className="btn btn-lg btn-brand-1 bg-success"
            onClick={() => _onFilter()}
          >
            Search
          </button>
        </div>
      </ReactModal>
      {/* Divya. March 22 2024. Code End  */}
    </>
  );
};
export default ManageUsers;
