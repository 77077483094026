import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { APIMethod, TOAST_TIMEOUT } from "../../../../API/APIClient";
import Loader from "../../../../components/Loader";
import ModalPopup from "../../../../components/ModalPopUp";
import NoData from "../../../../components/NoDataFound";
import Pagination from "../../../../components/Pagination";
import { useToasts } from "react-toast-notifications";
import SearchNoDataFound from "../../../../components/SearchNoDataFound";
import ReactModal from "react-modal-resizable-draggable";
import moment from "moment";

const RfxTypes = (props) => {
  const [getListData, setGetListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [flag, setFlag] = useState(false);
  // Commenting the following code for WOII-185. Divya. March 23 2024.. Code Start
  //const [formFields, setFormFields] = useState({ code: '', name: '', description: '' });
  //Commenting end. Divya  March 23 2024..
  // Added this code for  WOII-185. Divya. March 23 2024.. Code Start
  const [formFields, setFormFields] = useState({
    code: "",
    name: "",
    description: "",
    status: "ACTIVE",
  });
  const [showFilter, setShowFilter] = useState(false);
  const handleCloseFilter = () => setShowFilter(false);
  // Divya. March 23 2024. Code End
  const [codeCount, setCodeCount] = React.useState(0);
  // Edit
  const [edit, setEdit] = useState("");
  const [id, setId] = useState("");
  // Delete
  const [deleteId, setDeleteId] = useState("");
  const [showModalPopup, setShowModalPopup] = useState(false);
  // page
  const [pageCount, setPageCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  {
    /* Added this code for  WOII-120. Divya. November 09 2023.. Code Start */
  }
  const [rowsPerPage, setRowsPerPage] = useState(10);
  {
    /* Divya. November 09 2023. Code End */
  }
  // Add modal box
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setFlag(false);
    setId("");
    setFormFields({ ...formFields, name: "", code: "", description: "" });
  };
  const handleShow = () => setShow(true);
  const [adding, setAdding] = useState();
  const [editing, setEditing] = useState();
  const [deleting, setDeleting] = useState();
  const { addToast } = useToasts();

  //--------date-Piicker----------
  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    //Commenting the following code for WOII-120. Divya. November 09 2023.. Code Start
    //getRfxTypeList(1)
    //Commenting end. Divya. November 09 2023..
    //Added this code for  WOII-120. Divya. November 09 2023.. Code Start
    getRfxTypeList(currentPage);
    {
      /* Divya. November 09 2023. Code End */
    }
    var data = JSON.parse(localStorage.getItem("user_Role"));
    checkPermission(data);
    //Commenting the following code for WOII-120. Divya. November 09 2023.. Code Start
    //}, [])
    //Commenting end. Divya. November 09 2023..
    //Added this code for  WOII-120. Divya. November 09 2023.. Code Start
  }, [rowsPerPage, currentPage]);
  {
    /* Divya. November 09 2023. Code End */
  }

  const checkPermission = (data) => {
    const resObject = data.find((item) => item.name === "Organizational Setup");
    const valObject = resObject.access.find(
      (item) => item.name === "RFx Types"
    );
    setAdding(valObject.is_create);
    setEditing(valObject.is_update);
    setDeleting(valObject.is_delete);
  };

  const getRfxTypeList = (page, key) => {
    setLoading(true);
    const new_key = key === undefined ? "" : key;
    global.new_key = key === undefined ? "" : key;
    var method = "GET";
    //Commenting the following code for WOII-120. Divya. November 09 2023.. Code Start
    //const apifetcherObj = APIMethod(`/rfx-types/list/${page}?keyword=` + new_key, null, method)
    //Commenting end. Divya. November 09 2023..
    //Added this code for  WOII-120. Divya. November 09 2023.. Code Start
    //const apifetcherObj = APIMethod(`/rfx-types/list/${page}?limit=${rowsPerPage}&keyword=` + new_key, null, method)
    {
      /* Divya. November 09 2023. Code End */
    }
    //Added "code, name, description, status" parameters for WOII-185 by Divya
    const apifetcherObj = APIMethod(
      `/rfx-types/list/${page}?limit=${rowsPerPage}&name=${formFields.name}&code=${formFields.code}&description=${formFields.description}&status=${formFields.status}&keyword=` +
        new_key,
      null,
      method
    );
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];

        if (statusCode === 200) {
          setGetListData(data.list);
          if (page === 1) {
            setPageCount(data.count);
          }
          setCurrentPage(page);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const formatDate = () => {
    var d = startDate,
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value,
    };
    setFormFields(formFieldsObject);
  };

  const save = () => {
    if (
      formFields.code === "" ||
      formFields.name === "" ||
      formFields.description === ""
    ) {
      addToast("Please fill all the fields", {
        autoDismissTimeout: TOAST_TIMEOUT,
        appearance: "error",
        autoDismiss: true,
      });
      setFlag(true);
    } else if (codeCount < 2) {
      addToast("RFx Type code should have min. 2 character", {
        autoDismissTimeout: TOAST_TIMEOUT,
        appearance: "info",
        autoDismiss: true,
      });
    } else {
      setButtonLoader(true);
      if (edit === "/") {
        var params = JSON.stringify({
          id: id,
          name: formFields.name,
          description: formFields.description,
          code: formFields.code,
          start_date: formatDate("Sun May 11,2014"),
        });
        var method = "PUT";
      } else {
        params = JSON.stringify({
          name: formFields.name,
          description: formFields.description,
          code: formFields.code,
          start_date: formatDate("Sun May 11,2014"),
        });
        method = "POST";
      }
      const apifetcherObj = APIMethod("/rfx-types", params, method);
      apifetcherObj
        .then((response) => {
          return Promise.all([response.status, response.json()]);
        })
        .then((res) => {
          let statusCode = res[0];
          let data = res[1];

          if (statusCode === 200) {
            handleClose();
            setFormFields({
              ...formFields,
              code: "",
              name: "",
              description: "",
            });
            getRfxTypeList(currentPage);
            setButtonLoader(false);
            setFlag(false);
            addToast(data.message, {
              autoDismissTimeout: TOAST_TIMEOUT,
              appearance: "success",
              autoDismiss: true,
            });
          } else {
            addToast(data.message, {
              autoDismissTimeout: TOAST_TIMEOUT,
              appearance: "error",
              autoDismiss: true,
            });
            setButtonLoader(false);
          }
        })
        .catch((error) => {
          setButtonLoader(false);
        });
    }
  };

  const _onDelete = (id) => {
    setDeleteId(id);
    isShowPopup(true);
  };

  const isShowPopup = (status) => {
    setShowModalPopup(status);
  };

  const _onEdit = (val) => {
    handleShow();
    setFormFields({
      ...formFields,
      code: val.code,
      name: val.name,
      description: val.description,
    });
    setStartDate(new Date(val.start_date));
    setCodeCount(val.code.length);
    setEdit("/");
    setId(val.id);
  };

  const Search = (key) => {
    getRfxTypeList(1, key);
  };

  const nextPage = (pageNumber) => {
    //Commenting the following code for WOII-120. Divya. November 09 2023.. Code Start
    //getRfxTypeList(pageNumber)
    //Commenting end. Divya. November 09 2023..
    //Added this code for  WOII-120. Divya. November 09 2023.. Code Start
    setCurrentPage(pageNumber);
    {
      /* Divya. November 09 2023. Code End */
    }
  };

  {
    /* Added this code for  WOII-120. Divya. November 09 2023.. Code Start */
  }
  const handleRowsPerPageChange = (selectedValue) => {
    setRowsPerPage(selectedValue);
  };
  {
    /* Divya. November 09 2023. Code End */
  }

  const getData = () => {
    getRfxTypeList(1);
  };

  // Added this code for  WOII-185. Divya. March 23 2024.. Code Start
  const _onResetFilter = (type) => {
    if (type === "STATUS") {
      formFields.status = "";
    } else if (type === "CODE") {
      formFields.code = "";
    } else if (type === "NAME") {
      formFields.name = "";
    } else if (type === "DESCRIPTION") {
      formFields.description = "";
    } else {
      formFields.status = "";
      formFields.description = "";
      formFields.name = "";
      formFields.code = "";
      handleCloseFilter();
    }
    getRfxTypeList(1, "");
  };

  const _onFilter = () => {
    getRfxTypeList(1, "");
    handleCloseFilter();
  };

  const enableRow = (id) => {
    var method = "PUT";
    const apifetcherObj = APIMethod(`/rfx-types/${id}/enable`, null, method);
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];

        if (statusCode === 200) {
          getRfxTypeList(currentPage);
          isShowPopup(false);
        }
      })
      .catch((error) => {
        console.error("Error enabling rfx-types:", error);
      });
  };

  const deleteRow = (id) => {
    var method = "DELETE";
    const apifetcherObj = APIMethod(`/rfx-types/${id}/delete`, null, method);
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];

        if (statusCode === 200) {
          getRfxTypeList(currentPage);
          isShowPopup(false);
        }
      })
      .catch((error) => {
        console.error("Error in deleting rfx-types:", error);
      });
  };
  // Divya. March 23 2024. Code End

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content">
          <div className="row mb-3 align-items-center justify-content-between">
            <div className="col-lg-5 col-md-12 d-md-flex mb-md-0 mb-2 mb-lg-0 mb-md-3">
              <h2 className="page-title mb-md-0">RFx Types</h2>
            </div>
            <div className="col-lg-5 col-md-12 text-right d-md-flex align-items-center justify-content-center">
              <div className="input-group page-cont-search mr-md-3 mb-md-0 mb-2">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search by name and description"
                  onChange={(event) => Search(event.target.value)}
                />
                <span className="input-group-text text-secondary">
                  <i className="icofont-search-1"></i>
                </span>
              </div>
              {/*  Added this code for  WOII-185. Divya. March 23 2024.. Code Start */}
              <button
                type="button"
                className="btn btn-brand-1 btn-h-40 mr-3"
                onClick={() => setShowFilter(true)}
              >
                <i className="icofont-filter mr-2"></i> Filter
              </button>
              {/*  Divya. March 23 2024. Code End  */}
              {adding === true ? (
                <button
                  type="button"
                  className="btn btn-brand-1 btn-h-40 bg-success px-4"
                  onClick={handleShow}
                >
                  + Add
                </button>
              ) : null}
            </div>
          </div>
          {/*  Added this code for  WOII-185. Divya. March 23 2024.. Code Start */}
          <div className="mb-2 filter-by">
            {formFields.status ? (
              <div className="badge">
                <span className="lable">Status</span>
                <span className="value">
                  {formFields.status == "ACTIVE"
                    ? "Active"
                    : formFields.status == "DELETED"
                    ? "Archived"
                    : formFields.status}
                </span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("STATUS")}
                ></i>
              </div>
            ) : null}
            {formFields.description ? (
              <div className="badge">
                <span className="lable">Description</span>
                <span className="value">{formFields.description}</span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("DESCRIPTION")}
                ></i>
              </div>
            ) : null}
            {formFields.name ? (
              <div className="badge">
                <span className="lable">Name</span>
                <span className="value">{formFields.name}</span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("NAME")}
                ></i>
              </div>
            ) : null}
            {formFields.code ? (
              <div className="badge">
                <span className="lable">Code</span>
                <span className="value">{formFields.code}</span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("CODE")}
                ></i>
              </div>
            ) : null}
          </div>
          {/*  Divya. March 23 2024. Code End  */}

          <div
            className="table-responsive"
            style={{ height: "400px", overflowY: "auto", marginBottom: "20px" }}
          >
            <table
              className="table table-style-1 table-responsive-lg mb-4 table reports"
              id="reportTable"
            >
              <thead className="sticky-top" style={{ zIndex: 10 }}>
                <tr>
                  <th scope="col" style={{ textTransform: "none" }}>
                    RFx TYPE CODE
                  </th>
                  <th scope="col" style={{ textTransform: "none" }}>
                    RFx TYPE NAME
                  </th>
                  <th scope="col">Description</th>
                  <th scope="col">Effective Date</th>
                  <th scope="col" className="text-center" width="110">
                    {editing === true || deleting === true ? "Action" : null}
                  </th>
                </tr>
              </thead>
              <ModalPopup
                showModalPopup={showModalPopup}
                onPopupClose={isShowPopup}
                id={deleteId}
                endPoint={"/rfx-types/"}
                updateList={getData}
              ></ModalPopup>
              <tbody>
                {loading === false
                  ? getListData.map((res) => {
                      {
                        /*  Added this code for  WOII-185. Divya. March 23 2024.. Code Start */
                      }
                      const isRowArchived = res.status === "DELETED";
                      {
                        /*  Divya. March 23 2024. Code End  */
                      }
                      return (
                        // Commenting the following code for WOII-185. Divya. March 23 2024.. Code Start
                        // <tr key={index}>
                        //Commenting end. Divya  March 23 2024..
                        // Added this code for  WOII-185. Divya. March 23 2024.. Code Start
                        <tr
                          key={res.id}
                          className={isRowArchived ? "archived" : ""}
                        >
                          {/* Divya. March 23 2024. Code End */}
                          <td>{res.code}</td>
                          <td>{res.name}</td>
                          <td title={res.description}>
                            {res.description.length > 30
                              ? `${res.description.substring(0, 30)}...`
                              : res.description}
                          </td>
                          <td>
                            {res.start_date
                              ? moment(res.start_date, "YYYY-MM-DD").format(
                                  "MM-DD-YYYY"
                                )
                              : null}
                          </td>
                          <td className="text-center">
                            {/* Commenting the following code for WOII-185. Divya. March 23 2024.. Code Start */}
                            {/* {
                                                        editing === true ?
                                                            <button type="button" className="btn btn-action edit mr-1" onClick={() => _onEdit(res)}><i className="icofont-pencil-alt-2"></i></button> : null
                                                    }
                                                    {
                                                        deleting === true ?
                                                            <button type="button" className="btn btn-action delete" onClick={() => _onDelete(res.id)}><i className="icofont-archive"></i></button> : null
                                                    } */}
                            {/* Commenting end. Divya  March 23 2024..
                                                    Added this code for  WOII-185. Divya. March 23 2024.. Code Start */}
                            {isRowArchived ? (
                              <>
                                <button
                                  type="button"
                                  className="btn btn-action enable"
                                  onClick={() => enableRow(res.id)}
                                >
                                  <i
                                    className="icofont-check"
                                    style={{ fontSize: "18px" }}
                                  ></i>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-action delete ml-1"
                                  onClick={() => deleteRow(res.id)}
                                >
                                  <i className="icofont-archive"></i>
                                </button>
                              </>
                            ) : (
                              <>
                                {editing === true ? (
                                  <button
                                    type="button"
                                    className="btn btn-action edit mr-1"
                                    onClick={() => _onEdit(res)}
                                  >
                                    <i className="icofont-pencil-alt-2"></i>
                                  </button>
                                ) : null}
                                {deleting === true ? (
                                  <button
                                    type="button"
                                    className="btn btn-action delete"
                                    onClick={() => _onDelete(res.id)}
                                  >
                                    <i className="icofont-archive"></i>
                                  </button>
                                ) : null}
                              </>
                            )}
                            {/* Divya. March 23 2024. Code End */}
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
          {getListData.length === 0 && loading === false && global.new_key ? (
            <SearchNoDataFound message={"No RFx Type Found"} />
          ) : getListData.length === 0 && loading === false ? (
            <NoData />
          ) : null}
          {<Loader isLoading={loading} />}
          <div className="text-md-right">
            <nav aria-label="Page navigation">
              <Pagination
                pages={pageCount}
                nextPage={nextPage}
                currentPage={currentPage}
                pageCount={pageCount}
                //Added this code for  WOII-120. Divya. November 09 2023.. Code Start
                rowsPerPage={handleRowsPerPageChange}
                // Divya. November 09 2023. Code End
              />
            </nav>
          </div>
        </div>
      </div>

      {/* Add Modal Box */}
      <ReactModal
        initWidth={550}
        initHeight={580}
        top={132.5}
        className={"contained-modal-title-vcenter"}
        onRequestClose={handleClose}
        isOpen={show}
        disableResize={true}
        disableKeystroke={true}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="">
            {id ? "Edit" : "Add"} RFx Type
          </h5>
          <button type="button" className="close" onClick={handleClose}>
            <i className="icofont-close"></i>
          </button>
        </div>
        <div className="modal-body">
          <div className="row form-style">
            <div className="col-md-12">
              <div className="form-group">
                <label>RFx Type Code</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  onKeyUp={(e) => setCodeCount(e.target.value.length)}
                  maxLength={5}
                  name="code"
                  value={formFields["code"]}
                />
                <div className="d-flex flex-column">
                  <span className="character-count">{codeCount}/5</span>
                  <span className="text-danger">
                    {flag === true && formFields.code === ""
                      ? "Please enter your code"
                      : null}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>RFx Type Name</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  name="name"
                  value={formFields["name"]}
                />
                <span className="text-danger">
                  {flag === true && formFields.name === ""
                    ? "Please enter name"
                    : null}
                </span>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Description</label>
                <textarea
                  className="form-control h-auto"
                  id=""
                  cols="30"
                  rows="3"
                  onChange={handleChange}
                  name="description"
                  value={formFields["description"]}
                />
                <span className="text-danger">
                  {flag === true && formFields.description === ""
                    ? "Please enter description"
                    : null}
                </span>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Effective Start Date</label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  className="form-control"
                  placeholder="select Date"
                  dateFormat="MM-dd-yyyy"
                  minDate={new Date()}
                  scrollableYearDropdown
                  showYearDropdown
                />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-lg btn-brand-1 bg-danger"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-lg btn-brand-1 bg-success"
            onClick={() => save()}
            disabled={buttonLoader}
          >
            {buttonLoader && (
              <img
                src={
                  require("../../../../assets/css/images/button_loader.gif")
                    .default
                }
                alt="loading..."
                style={{ marginLeft: 5 }}
              />
            )}
            <span>Save</span>
          </button>
        </div>
      </ReactModal>
      {/*Added this code for  WOII-185. Divya. March 23 2024.. Code Start */}
      {/* Filter model */}
      <ReactModal
        initWidth={550}
        initHeight={"auto"}
        top={137.5}
        className={"contained-modal-title-vcenter"}
        onRequestClose={handleClose}
        isOpen={showFilter}
        disableResize={true}
        disableKeystroke={true}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="">
            Filter
          </h5>
          <button type="button" className="close" onClick={handleCloseFilter}>
            <i className="icofont-close"></i>
          </button>
        </div>
        <div className="modal-body">
          <div className="row form-style">
            <div className="col-md-6">
              <div className="form-group">
                <label>Status</label>
                <select
                  id="disabledSelect"
                  className="form-control custom-select "
                  onChange={handleChange}
                  name="status"
                  value={formFields["status"]}
                >
                  <option value="" disabled>
                    Select your option
                  </option>
                  <option value="ACTIVE">Active</option>
                  <option Value="DELETED">Archived</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Description</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  name="description"
                  value={formFields["description"]}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  name="name"
                  value={formFields["name"]}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Code</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  name="code"
                  value={formFields["code"]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer ">
          <button
            type="button"
            className="btn btn-lg btn-brand-1 bg-danger"
            onClick={handleCloseFilter}
          >
            Cancel
          </button>
          <button
            className="btn btn-lg btn-brand-1"
            onClick={() => _onResetFilter()}
          >
            Reset
          </button>
          <button
            type="button"
            className="btn btn-lg btn-brand-1 bg-success"
            onClick={() => _onFilter()}
          >
            Search
          </button>
        </div>
      </ReactModal>
      {/* Divya. March 23 2024. Code End  */}
    </>
  );
};
export default RfxTypes;
