import React, { useEffect, useRef, useState, useNavigate } from "react";
import { Breadcrumb, Tabs, Tab, Nav, Row, Col, Modal, Button, Container } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css"
import { useLocation, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import SectionizedRFx from "../../../../components/SectionizedRFx";
import RawRFx from "../../../../components/RawRFx";
import RfxData from "../../../../components/RfxData";
import { APIMethod, TOAST_TIMEOUT, FileUploadMethod, tiny_key } from "../../../../API/APIClient";
import moment from "moment";
import { useToasts } from 'react-toast-notifications';
import { useHistory } from "react-router";
import ReactModal from 'react-modal-resizable-draggable';
import ModalPopup from '../../../../components/ModalPopUp';
import { Editor } from '@tinymce/tinymce-react';
import sendComment from '../../../../assets/css/images/send-comment.png';
import sendComment1 from '../../../../assets/css/images/send-comment-white.jpeg';
import sendComment2 from '../../../../assets/css/images/send-comment-black.jpeg';
{/* Added this code for  WOII-189. Divya. February 26 2024.. Code Start */}
import ReactDOMServer from 'react-dom/server';
import {CKEditor} from 'ckeditor4-react';
import Swal from 'sweetalert2';
{/* Divya. February 26 2024. Code End */}



import { MultiSelect } from "react-multi-select-component";
import chatIcon from '../../../../assets/css/images/chat.png';
import Chat from "../../../../components/Chat";
import { NavLink } from "react-router-dom";
import gptlogo from '../../../../assets/css/images/gptlogo.png';


// import { Configuration, OpenAIApi } from 'openai';

const regex = /(<([^>]+)>)/ig;

const GenerateProposalMp = (props) => {
    const answerRef = useRef(null);
    const answerOutRefs = useRef([]);
    const [isLoding, setLoading] = useState(false);
    const { id } = useParams()
    const [SearchbuttonLoader, setSearchButtonLoader] = useState(false);
    const [searchData, setSearchData] = useState([]);
    const [documentData, setDocumentData] = useState([]);
    const [RFxCategoryData, setRFxCategoryData] = useState([]);
    const [approverData, setApproverData] = useState([]);
    // const [initialHeading , setInitialHeading] = useState('');
    const [hasComments,setHasComments]=useState(false);
    const [commentData, setCommentData] = useState([]);
    const [addDocuments, setAddDocuments] = useState([]);
    const [documentList, setDocumentList] = useState([]);
    const [contentData, setContentData] = useState([]);
    const [tinyChangesidx, setTinyChangesidx] = useState([]);
    const [aiSegmentationData, setAiSegmentationData] = useState();
    const [showModalPopup, setShowModalPopup] = useState(false);
    const [commentModal, setCommentModal] = useState(false);
    const [itemAnswer, setItemAnswer] = useState('')
    const [itemNumber, setItemNumber] = useState(0)
    const [itemHeading, setItemHeading] = useState('')
    /*Added this code for  WOII-195. Dharma. April 16 2024.. Code Start */
    const [pushBackLoader, setPushBackLoader] = useState(false);
    const [approveLoader, setApproveLoader] = useState(false);
    const [denyLoader, setDenyLoader] = useState(false);
    /*Dharma. April 16 2024. Code End */
    const [formFields, setFormFields] = useState({ rfxCategory: '', question: '', tag: '', preRfxId: '', sendForApprovalLoader: false, page: '', keyword: '', modalQuestion: '', modalAnswer: '', comment: '', commentAddLoader: false, isComment: false, document_type: '',
    //  version: '',
    //Commenting the following code for WOII-189. Divya. February 26 2024.. Code Start
    // description: '', predefine_sections: '', submitButtonLoader: false, saveButtonLoader: false, })
    //Commenting end. Divya. February 26 2024..
    //Added this code for  WOII-189. Divya. February 26 2024.. Code Start
    description: '', predefine_sections: '', submitButtonLoader: false, saveButtonLoader: false, requirement: '', requirementIn: '', page: '',})
    //Divya. February 26 2024. Code End
    const [copiedData, setCopiedData] = useState(['']);
    {/* Added this code for WI-2. Divya. June 19 2023.. Code Start */}
    const [isCollapsed, setIsCollapsed] = useState(true);
    const handleToggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
      };
    {/* Divya. June 19 2023. Code End */}
    //Goto back
    const goBack = () => {
        window.history.back();
    }
    // Toast
    const { addToast } = useToasts();
    const location = useLocation();
    //--------date-Piicker----------
    const [startDate, setStartDate] = useState();
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [show, setShow] = useState(false);
    const [addDocumentShow, setAddDocumentShow] = useState(false);
    const [answerModal, setAnswerModal] = useState(false);
    const [modalFlag, setModalFlag] = useState(false);
    const [modalButtonLoader, SetModalButtonLoader] = useState(false);
    const [aiSearchButtonLoader, setAiSearchButtonLoader] = useState(false);
    const [EditTemplateModal, setEditTemplateModal] = useState(false);
    const [aiSearchData, setAiSearchData] = useState([]);
    const [answer, setAnswer] = useState('')
    const [prompt, setPrompt] = useState('')
    const [baseprompt, setBasePrompt] = useState('')
    const [error, setError] = useState('');
    const [processing, setProcessing] = useState(false);
    // const [text, setText] = useState('As a way of preparing a response to '+preRfxData?.title ? preRfxData?.title : '-'+' RFP, provide answers to the following RFP Questions. RFP is issued by Solomons International. Provide this proposal on behalf of Solomons International.\nRFP question:')
    const [editAnswerIndex, setEditAnswerIndex] = useState('')
    const [preRfxData, setPreRfxData] = useState();
    const [preRfxTitle, setPreRfxTitle] = useState("");
    const [url, setUrl] = useState("");
    const [pageCount, setPageCount] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    // file
    const [file, setFile] = useState([]);
    const [editIndex, setEditIndex] = useState(-1);
    const [editDocumentId, setEditDocumentId] = useState('');
    const sectionId = useRef('');
    const sectionTitle = useRef('');
    const predefined_sections_ref = useRef('');
    const [predefined_sections, setPredefined_sections] = useState('')
    //---Date Time picker
    //coollapse menu - 1
    const CollapseMenu1 = () => {
        var element = document.getElementById("PraposalTemp");
        console.log(element)
        element.classList.toggle("collapse-tab");

        for (var i = 0; i < 5; i++) {
            var hideIcon = document.getElementById(`hideCollapseButton${i + 1}`);
            if (hideIcon) {
                hideIcon.classList.toggle("show-coll-menu-page");
                var hideText = document.getElementById(`hideCollapseText${i + 1}`);
                hideText.classList.toggle("hide-coll-menu-page");
            } else {
                break;
            }
        }
    }
    const [proposalDocumentDeleteId, setProposalDocumentDeleteId] = useState('')
    const history = useHistory();
    const [selected, setSelected] = useState([]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [file1, setFile1] = useState('');
    const [modalFlag1, setModalFlag1] = useState(false);

    const [showChatModal, setShowChatModal] = useState(false)
    const [users, setUsers] = useState([])
    const [commentsData, setCommentsData] = useState([])
    const [answerOutRefsInitialized, setAnswerOutRefsInitialized] = useState(false);


    const options = [
        { label: "Grapes 🍇", value: "grapes" },
        { label: "Mango 🥭", value: "mango" },
        { label: "Strawberry 🍓", value: "strawberry", disabled: true },
    ];
    var InitialHeading  = ''
    useEffect(() => {
        getRfxData()
        getRFxCategoryList()
        getApproverVersion()
        checkPage()
        getProposalSectionComments()
        getDocumentTypeList()
        getProposalDocuments()
        getAiImageSegmentation()
        getContractData()
        getUserList()
    }, [])

    useEffect(() => {
        // Initialize answerOutRefs array only if it hasn't been initialized yet
        if (!answerOutRefsInitialized) {
          answerOutRefs.current = approverData.map(() => React.createRef());
          setAnswerOutRefsInitialized(true);
        }
      }, [approverData, answerOutRefsInitialized]);


    useEffect(() => {
        if (answerRef.current) {
            console.log(answerRef.current)
                answerRef.current.setData(answer, {
                callback: function() {
                    console.log("Setting Answer")
                }
            })
        }
    }, [answerRef.current, answer])

    useEffect(() => {
        if (predefined_sections_ref.current) {
            predefined_sections_ref.current.setData(formFields['predefine_sections'] === 'requirement_checklist' ? renderTable(): (aiSegmentationData?.[formFields.predefine_sections] ? aiSegmentationData?.[formFields.predefine_sections] : `No Data Available`), {
                callback: function() {
                    console.log("Pre-defined Set")
                }
            },
        )};
    }, [formFields])

    const isViewPath = /^\/view-mp-approval-genrate-proposal\/\d+$/.test(window.location.pathname);
    /* Added this code for  WO-215. Prasanna. June 03 2024.. Code Start */
    const isProposalApproved = location.state.proposal_status === 'Proposal Is Approved';
    /* Prasanna. June 03 2024. Code End */


    const getRfxData = () => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/document-section/${id}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                                        setUrl(data.url);
                } else {
                    addToast(data.message, { appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { appearance: 'error', autoDismiss: true });
            })
    }

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    const handleFileSelect = (event) => {
        setFile1( event.target.files[0])
    };
    

    const getRFxCategoryList = () => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfx-categories/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setRFxCategoryData(data)
                } else {
                }
            })
            .catch(error => {
            })
    }

    const getCompaniesList = async (page, key, company) => {
        return new Promise(async (resolve, reject) => {
            const new_key = key === undefined ? '' : key;
            global.new_key = key === undefined ? '' : key;
            var method = 'GET';
            try {
                const response = await APIMethod(`/company-details/list/${page}?limit=10000&keyword=${new_key}`, null, method);
                const [statusCode, data] = await Promise.all([response.status, response.json()]);
        
                if (statusCode === 200) {
                    const companiesList = data.list;
                    if(company === "Self")
                    {
                        const selfCompany = companiesList.find(company => company.business_unit_type_id_name.toLowerCase() === "self");
                        resolve(selfCompany.name?selfCompany.name:"Self_Company");
                    }
                    
                    if (page === 1) {
                        setPageCount(data.count);
                    }
                    setCurrentPage(page);
                }
            } catch (error) {
                // Handle any errors that may occur during the API calls or processing
                console.error(error);
            }
        })
    };

    const save =async (file) => {
        // Additional logic for saving the document
        setButtonLoader(true);
        try {
            const aValue = await getApproverVersion();
            console.log(aValue);
            const formData = new FormData();
            formData.append('file', file1);
            formData.append('rfx_id',aValue);
            addToast("Word document uploaded successfully", { appearance: 'info', autoDismiss: true });
        
            const apifetcherObj = await FileUploadMethod('/template-content/file', formData, 'POST');
            getProposalDocuments()
            addToast("Section Level Content Modified Successfully", { appearance: 'success', autoDismiss: true });
        }
        catch{
            addToast("Error while saving content from Word document ", { appearance: 'error', autoDismiss: true });
        }
        // Simulating asynchronous operation
        setTimeout(() => {
          setButtonLoader(false);
          handleCloseModal();
        }, 2000);
      };
      let a=null;
      const getApproverVersion = () => {
          return new Promise((resolve, reject) => {
              var method = 'GET'
              const apifetcherObj = APIMethod(`/template-content/list/1?pre_rfx_id=${id}&is_approver=false`, null, method)
              apifetcherObj
                  .then(response => Promise.all([response.status, response.json()]))
                  .then(res => {
                      let statusCode = res[0]
                      let data = res[1]
      
                      if (statusCode === 200) {
                          for (var i in data.list) {
                              if (data.list[i].is_check == false) {
                                  data.list[i].is_check = false;
                              } else {
                                  data.list[i].is_check = true;
                              }
                              if (data.list[i].answer) {
                                  data.list[i].answer = data.list[i].answer.replaceAll("<strong", "<strong style='font-weight:bold'");
                              }
                              if (data.list[i].description) {
                                  data.list[i].description = data.list[i].description.replaceAll("<strong", "<strong style='font-weight:bold'");
                              }
                          }
                          setApproverData(data.list)
                          console.log(data.list[0].title)
                          getUserComments(data.list[0].id)
                          InitialHeading = data.list[0].title
                          a = data.list[0].pre_rfx_id;
                          console.log(a);
                          resolve(a);
                      } else {
                          addToast(data.message, { appearance: 'error', autoDismiss: true });
                          reject(data.message);
                      }
                  })
                  .catch(error => {
                      addToast(error, { appearance: 'error', autoDismiss: true });
                      reject(error);
                  });
          });
      };


    const reEditTemplate = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "This process will revert the status to 'Proposal Template Created' and will be irreversible",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            confirmButtonColor: '#d33',
            cancelButtonColor: '#044661',
        }).then((result) => {
            if (result.isConfirmed) {
                sendEvent("RE_EDIT_TEMPLATE")
                history.push({ pathname: `/manage-proposal-templates`})
            }
        });
    }


    const checkPage = () => {
        if (props.location.state == null) {
        } else {
            formFields.preRfxId = props.location.state.detail
            formFields.page = props.location.state.page
        }
        var method = 'GET'
        const apifetcherObj = APIMethod(`/pre-rfx/${id}?rfx_type=AI`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(async res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setPreRfxData(data)
                    let title= data.title ? data.title:"<Rfx_Title>"
                    let clientName = data.rfx_client_agency ? data.rfx_client_agency:"<Client Name>"
                    let selfCompanyName = await getCompaniesList(1, undefined, "Self");
                    // console.log(selfCompanyName)
                    if(selfCompanyName.length === 0)
                    {
                        selfCompanyName = "Self_Company";
                    }
                    setPrompt('Prepare response to the following question of the RFP '+title+' on behalf of '+selfCompanyName+'. RFP is issued by '+clientName+'.\nQuestion of the RFP:\n'+InitialHeading.replace(/^[0-9a-zA-Z]+\.\s*/, ""));
                    setBasePrompt('Prepare response to the following question of the RFP '+title+' on behalf of '+selfCompanyName+'. RFP is issued by '+clientName+'.\nQuestion of the RFP:');
                    setLoading(false);

                } else {
                    addToast(data.message, { appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                setLoading(false);
            })
    }
    

    const getProposalDocuments = () => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/proposal/document/list/${id}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setAddDocuments(data)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
            })
    }

    const getAiImageSegmentation = () => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfp-image-segmentation/${id}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setAiSegmentationData(data)
                    // formFields.aiScope = data.scope
                    // formFields.aiProposalStructure = data.proposal_structure
                } else {
                    addToast(data.message, { appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                alert(error)
            })
    }

    const _onSearch = () => {
        if (formFields.keyword === '') {
            addToast('Please Fill all the fields', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true })
        } else {
            setSearchButtonLoader(true)
            let new_from_date = fromDate ? moment(fromDate).format(
                "yyyy-MM-DD"
            ) : '';
            let new_to_date = toDate ? moment(toDate).format(
                "yyyy-MM-DD"
            ) : '';
            setSearchData([])
            var method = 'GET'
            const apifetcherObj = APIMethod(`/qna/list/1?question_answer=${formFields.keyword}&tag=${formFields.tag}&rfx_category_id=${formFields.rfxCategory}&from_date=${new_from_date}&to_date=${new_to_date}&is_approvals=False`, null, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode === 200) {
                        setSearchData(data.list)
                        getDocumentList()
                        setSearchButtonLoader(false)
                    } else {
                        setSearchButtonLoader(false)
                    }
                })
                .catch(error => {
                    alert(error)
                    setSearchButtonLoader(false)
                })
        }
    }

    const getDocumentList = () => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/document/list/1?keyword=${formFields.keyword}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setDocumentData(data.list)
                    setSearchButtonLoader(false)
                } else {
                    setSearchButtonLoader(false)
                }
            })
            .catch(error => {
                alert(error)
                setSearchButtonLoader(false)
            })
    }

    const getProposalSectionComments = () => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/template-content/comments/list/${id}?comment_type=PROPOSAL_COMMENT`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setCommentData(data)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
            })
    }

    const getDocumentTypeList = () => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfx-document-type/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setDocumentList(data)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
            })
    }

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const formFieldsObject = {
            ...formFields,
            [name]: value
        }
        setFormFields(formFieldsObject);
    }

    const _onSendForApproval = () => {
        if (formFields.isComment == false) {
            addToast("Please Enter Comment First", { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true })
        } else {
            /**Commenting the following code for WOII-195. Dharma. April 16 2024.. Code Start
            formFields.sendForApprovalLoader = true;
            / Commenting end. Dharma. April 16 2024..**/
            /*Added this code for  WOII-195. Dharma. April 16 2024.. Code Start */
            setFormFields(prevState => ({ ...prevState, sendForApprovalLoader: true }));
            /*Dharma. April 16 2024. Code End */
            var method = 'PUT'
            var params = JSON.stringify({
                id: id,
                proposal_status: 'PENDING_APPROVAL'
            })
            const apifetcherObj = APIMethod(`/pre-rfx`, params, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode === 200) {
                        addToast('Sent for Approval', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true })
                        /**Commenting the following code for WOII-195. Dharma. April 16 2024.. Code Start
                        formFields.sendForApprovalLoader = false;
                        / Commenting end. Dharma. April 16 2024..**/
                        /*Added this code for  WOII-195. Dharma. April 16 2024.. Code Start */
                        setFormFields(prevState => ({ ...prevState, sendForApprovalLoader: false }));
                        /*Dharma. April 16 2024. Code End */
                        onUpdateStatus("SEND_FOR_APPROVAL_FOR_PROPOSAL")
                    } else {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                        /**Commenting the following code for WOII-195. Dharma. April 16 2024.. Code Start
                        formFields.sendForApprovalLoader = false;
                        / Commenting end. Dharma. April 16 2024..**/
                        /*Added this code for  WOII-195. Dharma. April 16 2024.. Code Start */
                        setFormFields(prevState => ({ ...prevState, sendForApprovalLoader: false }));
                        /*Dharma. April 16 2024. Code End */
                    }
                })
                .catch(error => {
                    addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                     /**Commenting the following code for WOII-195. Dharma. April 16 2024.. Code Start
                    formFields.sendForApprovalLoader = false;
                    / Commenting end. Dharma. April 16 2024..**/
                    /*Added this code for  WOII-195. Dharma. April 16 2024.. Code Start */
                    setFormFields(prevState => ({ ...prevState, sendForApprovalLoader: false }));
                    /*Dharma. April 16 2024. Code End */
                })
        }
    }

    const onViewQA = (data) => {
        formFields.modalQuestion = data.question
        formFields.modalAnswer = data.answer
        setShow(true)
    }

    const addComment = () => {
        if (formFields.comment == '') {
            addToast('Please enter comment', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else {
            /**Commenting the following code for WOII-195. Dharma. April 16 2024.. Code Start
            formFields.commentAddLoader = true
            / Commenting end. Dharma. April 16 2024..**/
            /*Added this code for  WOII-195. Dharma. April 16 2024.. Code Start */
            setFormFields(prevState => ({ ...prevState, commentAddLoader: true }));
            /*Dharma. April 16 2024. Code End */
            var method = 'POST'
            var params = JSON.stringify({
                comment: formFields.comment,
                pre_rfx_id: id,
                type: "PROPOSAL_COMMENT"
            })
            const apifetcherObj = APIMethod(`/template-content/post/comment`, params, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode == 200) {
                        // addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true })
                        getProposalSectionComments()
                        /**Commenting the following code for WOII-195. Dharma. April 16 2024.. Code Start
                        formFields.commentAddLoader = false
                        formFields.isComment = true
                        formFields.comment = ''
                        / Commenting end. Dharma. April 16 2024..**/
                        /*Added this code for  WOII-195. Dharma. April 16 2024.. Code Start */
                        setFormFields(prevState => ({ ...prevState, commentAddLoader: false, isComment: true, comment: '' }));
                        /*Dharma. April 16 2024. Code End */
                    } else {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                        /**Commenting the following code for WOII-195. Dharma. April 16 2024.. Code Start
                        formFields.commentAddLoader = false
                        / Commenting end. Dharma. April 16 2024..**/
                        /*Added this code for  WOII-195. Dharma. April 16 2024.. Code Start */
                        setFormFields(prevState => ({ ...prevState, commentAddLoader: false }));
                        /*Dharma. April 16 2024. Code End */
                    }
                })
                .catch(error => {
                    addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    /**Commenting the following code for WOII-195. Dharma. April 16 2024.. Code Start
                    formFields.commentAddLoader = false
                    / Commenting end. Dharma. April 16 2024..**/
                    /*Added this code for  WOII-195. Dharma. April 16 2024.. Code Start */
                    setFormFields(prevState => ({ ...prevState, commentAddLoader: false }));
                    /*Dharma. April 16 2024. Code End */
                })
        }
    }

    const onUpdateStatus = (key) => {
        if (formFields.isComment == false && key != 'PROPOSAL_COMPLETED' && key != 'DENY') {
            addToast('Please enter comment', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else if (key == 'PROPOSAL_COMPLETED') {
            var showMessage = false;
            for (var i in approverData) {
                if (approverData[i].is_check == false) {
                    showMessage = true;
                    break;
                }
            }
            if (showMessage === true) {
                addToast('Before Submiting all checkbox should be checked.', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
            } else {
                let fromOnUpdateStatus = true
            sendEvent(key, fromOnUpdateStatus, true)
            setFormFields(prevState => ({ ...prevState, submitButtonLoader: true }));
            }
            return;
        } else {
            let fromOnUpdateStatus = true
            sendEvent(key, fromOnUpdateStatus, true)
            setFormFields(prevState => ({ ...prevState, submitButtonLoader: true }));
        }
    }

    const sendEvent = (key, fromAnotherMethod=false) => {
        if (key == 'PROPOSAL_COMPLETED') {
            setFormFields(prevState => ({ ...prevState, submitButtonLoader: false }));
        }
        var method = 'PUT'
        const apifetcherObj = APIMethod(`/action/event/${key}/${id}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]
                const fromSendEvent = true
                if (statusCode == 200) {
                    formFields.isComment = false;
                    if (key == 'PROPOSAL_COMPLETED' || key == 'SEND_FOR_APPROVAL_FOR_PROPOSAL') {
                        onSubmit(fromSendEvent)
                        goBack()
                    } 
                    else if (key == 'RE_EDIT_TEMPLATE') {
                        addToast('Success', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true });
                    }
                    else {
                        if(!fromAnotherMethod) {
                            addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true });
                        }
                        onSaveProposal(fromSendEvent)
                        goBack()
                    }
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
            })
    }

    const onSubmit = (type, fromAnotherMethod=false) => {
        var method = 'PUT'
        if(tinyChangesidx.length != 0)
        {
            for (let i = 0; i < tinyChangesidx.length; i++) {
                const currentIndex = tinyChangesidx[i];
                for (var j in approverData) {
                    if (currentIndex == j) {
                        approverData[j].answer = answerOutRefs.current[currentIndex]?.getData();
                    }
                }
            }
        }
        
        if (approverData.length > 0) {
            for (var i in approverData) {
                approverData[i].priority = parseInt(i)
                approverData[i].total_sections = approverData.length
            }
        }
        
        var params = JSON.stringify(approverData)
        /**Commenting the following code for WOII-195. Dharma. April 16 2024.. Code Start
        if (!type) {
            formFields.saveButtonLoader = true;
        }
        / Commenting end. Dharma. April 16 2024..**/
        /*Added this code for  WOII-195. Dharma. April 16 2024.. Code Start */
        if (type == 'PUSHBACK') {
            setPushBackLoader(true);
        } else if (type == 'PROPOSAL_APPROVED') {
            setApproveLoader(true);
        } else if (!type) {
            setFormFields(prevState => ({ ...prevState, saveButtonLoader: true }));
        } else if (type == 'DENY') {
            setDenyLoader(true);
        }
        /*Dharma. April 16 2024. Code End */
        const apifetcherObj = APIMethod(`/template-content/${id}?stage=proposal&is_approver=false`, params, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                console.log(type)
                if (statusCode == 200) {
                    setFormFields(prevState => ({ ...prevState, submitButtonLoader: false }));
                    setTinyChangesidx([]);
                    if(!fromAnotherMethod) {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true })
                    }
                    if (addDocuments.length > 0 && props.location.state.page != 'Approval') {
                        let fromOnSubmit = true
                        onSaveProposal(fromOnSubmit)
                    }
                    if (type == 'PROPOSAL_APPROVED') {
                        let fromOnSubmit = true
                        onUpdateStatus('PROPOSAL_APPROVED')
                    }
                    if (type == 'DENY') {   
                        let fromOnSubmit = true
                        onUpdateStatus('PROPOSAL_DENY')
                    }
                    if (type == 'PUSHBACK') {
                        let fromOnSubmit = true
                        onUpdateStatus('PUSHBACK')
                    } 
                    if (!type) {
                        setFormFields(prevState => ({ ...prevState, saveButtonLoader: false }));
                        history.push({ pathname: '/manage-proposal'})
                    }
                    /*Added this code for  WOII-195. Dharma. April 16 2024.. Code Start */
                    setFormFields(prevState => ({ ...prevState, saveButtonLoader: false }));
                    /*Dharma. April 16 2024. Code End */
                } else {
                    setFormFields(prevState => ({ ...prevState, submitButtonLoader: false }));
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    /**Commenting the following code for WOII-195. Dharma. April 16 2024.. Code Start
                    formFields.saveButtonLoader = false;
                    / Commenting end. Dharma. April 16 2024..**/
                    /*Added this code for  WOII-195. Dharma. April 16 2024.. Code Start */
                    setFormFields(prevState => ({ ...prevState, saveButtonLoader: false }));
                    /*Dharma. April 16 2024. Code End */
                }
                /*Added this code for  WOII-195. Dharma. April 16 2024.. Code Start */
                setPushBackLoader(false);
                setApproveLoader(false);
                setDenyLoader(false);
                /*Dharma. April 16 2024. Code End */
            })
            .catch(error => {
                setFormFields(prevState => ({ ...prevState, submitButtonLoader: false }));
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                /**Commenting the following code for WOII-195. Dharma. April 16 2024.. Code Start
                formFields.saveButtonLoader = false;
                / Commenting end. Dharma. April 16 2024..**/
                /*Added this code for  WOII-195. Dharma. April 16 2024.. Code Start */
                setFormFields(prevState => ({ ...prevState, saveButtonLoader: false }));
                setPushBackLoader(false);
                setApproveLoader(false);
                setDenyLoader(false);
                /*Dharma. April 16 2024. Code End */
            })
    }

    const onCheckboxClick = (index) => {
        var temp = [];
        for (var i in approverData) {
            if (index == i) {
                approverData[i].is_check = !approverData[i].is_check;
            }
            temp.push(approverData[i])
        }
        setApproverData(temp);
    }

    const onCancelModal = () => {
        setFormFields({ ...formFields, document_type: "",
        //  version: "",
          description: "" })
        setEditDocumentId('')
        setFile()
        setAddDocumentShow(!addDocumentShow)
    }

    const filehandler = event => {
        setFile(event.target.files)
    }

    // const _onAddDocument = () => {
    //     if (
    //         // formFields.version === '' || 
    //         formFields.description === '' || file === undefined || formFields.document_type === '') {
    //         setModalFlag(true)
    //         addToast('Please fill all the fields', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
    //     } else {
    //         if (file.type) {
    //             console.log(file.type)
    //             SetModalButtonLoader(true)
    //             var method = 'POST'
    //             var form = new FormData();
    //             form.append('file', file);
    //             const apifetcherObj = FileUploadMethod(`/document/attachment/upload`, form, method)
    //             apifetcherObj
    //                 .then(response => { return Promise.all([response.status, response.json()]) })
    //                 .then(res => {
    //                     let statusCode = res[0]
    //                     let data = res[1]
    //                     var name = ''
    //                     if (statusCode === 200) {
    //                         for (var i in documentList) {
    //                             if (documentList[i].id == formFields.document_type) {
    //                                 name = documentList[i].name
    //                             }
    //                         }
    //                         var obj = {
    //                             document_id: formFields.document_type,
    //                             document_type_name: name,
    //                             file_name: file.name,
    //                             // version: formFields.version,
    //                             file: data.file,
    //                             description: formFields.description
    //                         }

    //                         if (editIndex >= 0) {
    //                             for (var index in addDocuments) {
    //                                 if (index == editIndex) {
    //                                     addDocuments.splice(editIndex, 1);
    //                                     addDocuments.splice(editIndex, 0, obj);
    //                                     setEditIndex(-1)
    //                                     setAddDocuments(addDocuments)
    //                                     break;
    //                                 }
    //                             }
    //                         } else {
    //                             addDocuments.push(obj)
    //                             setAddDocuments(addDocuments)
    //                         }
    //                         setAddDocumentShow(!addDocumentShow)
    //                         setEditIndex(-1)
    //                         setFile('')
    //                         setEditDocumentId('')
    //                         setFormFields({ ...formFields,
    //                             //  version: '', 
    //                              description: '', document_type: '' })
    //                         SetModalButtonLoader(false)
    //                     } else {
    //                         addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
    //                     }
    //                 })
    //                 .catch(error => {
    //                     SetModalButtonLoader(false)
    //                 })
    //         } else {
    //             console.log("editing else block---->")
    //             var name = ''
    //             for (var i in documentList) {
    //                 if (documentList[i].id == formFields.document_type) {
    //                     name = documentList[i].name
    //                 }
    //             }
    //             for (var i in addDocuments) {
    //                 if (i == editIndex) {
    //                     addDocuments[i].document_id = formFields.document_type,
    //                         addDocuments[i].document_type_name = name,
    //                         addDocuments[i].file_name = file.name,
    //                         // addDocuments[i].version = formFields.version,
    //                         addDocuments[i].description = formFields.description
    //                 }
    //             }
    //             setAddDocumentShow(!addDocumentShow)
    //             setEditIndex(-1)
    //             setFormFields({ ...formFields, document_type: '', name: '',
    //             //  version: '',
    //               description: '' })
    //             setFile('')
    //         }
    //     }
    // }


const _EditDocuementfun= async()=>{
            console.log("editing function block---->")
            var name = ''
            for (var i in documentList) {
                if (documentList[i].id == formFields.document_type) {
                    name = documentList[i].name
                }
            }
            for (var i in addDocuments) {
                if (i == editIndex) {
                    addDocuments[i].document_id = formFields.document_type,
                        addDocuments[i].document_type_name = name,
                        addDocuments[i].file_name = file.name,
                        // addDocuments[i].version = formFields.version,
                        addDocuments[i].description = formFields.description
                }
            }
            setAddDocumentShow(!addDocumentShow)
            setEditIndex(-1)
            setFormFields({ ...formFields, document_type: '', name: '',
            //  version: '',
                description: '' })
            setFile('')

}

 
    const _onAddDocument = async () => {
        if ( formFields.description === '' || file.length === 0 || formFields.document_type === '') {
            setModalFlag(true);
            addToast('Please fill all the fields', {
                autoDismissTimeout: TOAST_TIMEOUT,
                appearance: 'error',
                autoDismiss: true
            });
        } else {
            if(editIndex >=0){
                _EditDocuementfun()
            } 
            // console.log("final testing array-------->",addDocuments)
            for (let i = 0; i < file.length; i++) {
                const singleFile = file[i];
                try {
                    if (singleFile.type) {
                        SetModalButtonLoader(true);
                        const method = 'POST';
                        const form = new FormData();
                        form.append('file', singleFile);
                        const apifetcherObj = FileUploadMethod(`/document/attachment/upload`,form,method);
                        const response = await apifetcherObj;
                        const responseBody = await response.text(); 
                        const status = response.status;
                        const data = responseBody ? JSON.parse(responseBody) : null; 
                        if (status === 200 && editIndex<0) {
                            let name = '';
                            for (var j in documentList) {
                                if (documentList[j].id == formFields.document_type) {
                                    name = documentList[j].name;
                                }
                            }
                            const obj = {
                                document_id: formFields.document_type,
                                document_type_name: name,
                                file_name: singleFile.name,
                                file: data.file,
                                description: formFields.description
                            };
                            // console.log("printing object------->",obj)
                            // Add the current document to the addDocuments array
                            addDocuments.push(obj);
                            setAddDocuments([...addDocuments]);
                        }
                         else {
                            addToast(data.message, {
                                autoDismissTimeout: TOAST_TIMEOUT,
                                appearance: 'error',
                                autoDismiss: true
                            });
                        }

                    }
                } catch (error) {
                    console.error('Error uploading file:', error);
                    addToast('Error uploading file', {
                        autoDismissTimeout: TOAST_TIMEOUT,
                        appearance: 'error',
                        autoDismiss: true
                    });
                } finally {
                    SetModalButtonLoader(false);
                }
            }
    
            // Reset state variables after processing all files
            setAddDocumentShow(!addDocumentShow);
            setEditIndex(-1);
            setFile('');
            setEditDocumentId('');
            setFormFields({
                ...formFields,
                description: '',
                document_type: ''
            });
        }
    };


    
    const onSaveProposal = (fromAnotherMethod=false) => {
        if (addDocuments.length == 0) {
            addToast("Please add documents", { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else {
            var method = 'POST'
            var params = JSON.stringify(addDocuments)
            const apifetcherObj = APIMethod(`/proposal/document/${id}`, params, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode === 200) {
                        if(!fromAnotherMethod){
                            addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true });
                        }
                        getProposalDocuments()
                    } else {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    }
                })
                .catch(error => {
                    SetModalButtonLoader(false)
                })
        }
    }

    const getContractData = () => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/contract/section/list/${id}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setContentData(data)
                } else {

                }
            })

            .catch(error => {
                addToast(error, { appearance: 'error', autoDismiss: true });
            })
    }

    const getUserList = () => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/users/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    var array = [];
                    for (var i in data) {
                        array.push({ label: data[i].first_name + " " + data[i].last_name, value: data[i].id, object: data[i] })
                    }
                    setUsers(array)
                } else {

                }
            })
            .catch(error => {
                addToast(error, { appearance: 'error', autoDismiss: true });
            })
    }

    const getUserComments = (id) => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/users/chat/comment/${id}/0`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode == 200) {
                    if(data.length>0){
                        setHasComments(true)
                    }
                    else{
                        setHasComments(false)
                    }
                
                    setCommentsData(data)
                }
            })
            .catch(error => {
                addToast(error, { appearance: 'error', autoDismiss: true });
            })
    }

    const deleteProposalDocument = (data) => {
        isShowPopup(true)
        setProposalDocumentDeleteId(data.id)
    }

    const _onEditDocument = (index, data) => {
        // console.log("printing files array----------->",file)
        // console.log("Inedit mode -------------->",data)
        setEditDocumentId(data.id)
        setFormFields({ ...formFields,
            //  version: data.version,
              description: data.description, document_type: data.document_id })  
        setFile({ name: data.file_name })
        // file[index].name=data.file_name
        setAddDocumentShow(!addDocumentShow)
        setEditIndex(index)
    }

    const aiQuestionSearch = () => {
        setAiSearchData([])
        setAiSearchButtonLoader(true)
        var method = 'POST'
        var params = JSON.stringify({
            question: formFields.question,
            id: id
        })
        const apifetcherObj = APIMethod(`/proposal/question`, params, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode == 200) {
                    for (var i in data) {
                        if (data[i].answer) {
                            data[i].answer = data[i].answer.replaceAll("<strong", "<strong style='font-weight:bold'");
                        }
                    }
                    setAiSearchData(data)
                    setAiSearchButtonLoader(false)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    setAiSearchButtonLoader(false)
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setAiSearchButtonLoader(false)
            })
    }

    const updateAtIndex = (index, newValue) => {
        // answerOutRefs.current[index].setContent(newValue);
        answerOutRefs.current[index].setData(newValue, {
            callback: function () {
                console.log("Setting New Value")
            },
        })
        for (var i in approverData) {
            if (index == i) {
                approverData[i].answer = newValue;
            }
        }
      };

    const isShowPopup = (status) => {
        setShowModalPopup(status)
    };

    const onEditAnswer = (data, index) => {
        setAnswer(data)
        // answerRef.current = data
        setEditAnswerIndex(index)
        setAnswerModal(!answerModal)
    }

    const onSubmitAnswer = () => {
        for (var i in approverData) {
            if (editAnswerIndex == i) {
                answerOutRefs.current[i].setData(answerRef.current.getData(), {
                    callback: function () {
                        console.log("Setting New Value")
                    },
                })
                approverData[i].answer = answerRef.current.getData();
            }
        }
        setApproverData([...approverData])
        setEditAnswerIndex('')
        setAnswerModal(!answerModal)
    }

   

    const handlePromptChange = (e) => {
        setPrompt(e.target.value);
    }

    const handleGenerate = async () => {
        setProcessing(true);
        if(!prompt.trim()) {
            setError('Prompt text is required⚠️');
            setProcessing(false);
            return;
        }
        
        setError('')
        const p = prompt;
        
        try {
            const response = await fetchOpenAI(p);
            setError('');
            setAnswer(response);
            // answerRef.current.setData(answer, {
            //     callback: function() {
            //         console.log("Setting Answer")
            //     }
            // })
          } catch (e) {
            setError('Error generating response⚠️');
            console.log(e);
          }
        setProcessing(false);
    };

    const fetchOpenAI = async (prompt) => {
        return new Promise((resolve, reject) => {
            var method = 'POST'
            const requestData = JSON.stringify({
                prompt: prompt,
            });
            const apifetcherObj = APIMethod(`/pre-rfx/gptresponse`, requestData, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]
                    if (statusCode === 200) {
                        // console.log(data)
                        /*Commented code for WOII-203 by Dharma 24-05-2024 */
                        // console.log(data.choices[0].text);
                        // resolve(data.choices[0].text)
                        /*Commented end for WOII-203 by Dharma 24-05-2024*/
                        /*Added code  for WOII-203 by Dharma 24-05-2024*/
                        resolve(data.response)
                        /*Added code end for WOII-203 by Dharma 24-05-2024*/
                    }
                    else{
                        reject(new Error('API call failed'));
                    }
                })
                .catch(error => {
                    console.error('API call error:', error);
                    reject(error);
                })
        });
    } 


      

    //Added this code for  WOII-189. Divya. February 26 2024.. Code Start
    function renderTable() {
        return ReactDOMServer.renderToString(
            <table className="table table-style-1 table-responsive-lg mb-4 table">
                <thead>
                    <tr>
                        <th>Requirement</th>
                        <th>Reference in</th>
                        <th>Page</th>
                    </tr>
                </thead>
                {
                    aiSegmentationData && aiSegmentationData.requirement_checklist.sort((a, b) => a.page - b.page) && aiSegmentationData.requirement_checklist.map((res, index) => (
                        <tr key={index}> 
                            <td title={res.requirement} style={{ maxWidth: "350px" }}>{res.requirement}</td>
                            <td style={{ maxWidth: "350px" }}>{res.reference_in}</td>
                            <td>{res.page}</td>
                        </tr>
                     ))
                }
            </table>
        );
    } 
    //Divya. February 26 2024. Code End



    const onSubmitComment = () => {
        if (selected.length == 0) {
            addToast('Please Select Users', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else if (formFields.comment == '') {
            addToast('Please Enter Comment', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else {
            formFields.saveButtonLoader = true;
            var array = [];
            for (var i in selected) {
                array.push({ id: selected[i].value, email: selected[i].object.email })
            }
            var method = 'POST'
            var params = JSON.stringify({
                pre_rfx_id: id,
                comment: formFields.comment,
                users: array,
                section_id: sectionId.current,
                subject: `Edit comment on ${sectionTitle.current} (${preRfxData?.title}) `
            })
            const apifetcherObj = APIMethod(`/users/chat/comment`, params, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode == 200) {
                        setSelected([])
                        formFields.comment = ''
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true });
                        getUserComments(sectionId.current)
                        formFields.saveButtonLoader = false
                    }
                })
                .catch(error => {
                    addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    setAiSearchButtonLoader(false)
                    formFields.saveButtonLoader = false
                })
        }
    }
    return (
        <>
            <ModalPopup
                showModalPopup={showModalPopup}
                onPopupClose={isShowPopup}
                id={proposalDocumentDeleteId}
                endPoint={'/proposal/document/'}
                updateList={getProposalDocuments}
            ></ModalPopup>

            <div className="page-wrapper form-style">
                <div className="page-content">
                    <div className="mb-3">
                        {
                            location.state &&
                            <Breadcrumb>
                                <Breadcrumb.Item onClick={() => goBack()}>{location.state.from}</Breadcrumb.Item>
                                <Breadcrumb.Item active>Generate Proposal</Breadcrumb.Item>
                            </Breadcrumb>
                        }
                    </div>
                    <div className="row col-12">
                        <div className="col-3">
                            {location.state && <h2 className="page-title">{location.state.from == 'Proposal Approvals' ? 'Proposal Approval' : 'Proposal'}</h2>}
                        </div>
                        <div className="col-9 row">
                            <h4 className="font-16 font-medium">RFx No. : <span className="text-secondary">{preRfxData?.rfx_number ? preRfxData?.rfx_number : '-'}</span></h4>
                            <h4 className="font-16 font-medium ml-5">RFx Title : <span className="text-secondary">{preRfxData?.title ? preRfxData?.title : '-'}</span></h4>
                        </div>
                    </div>
                    <div className="mb-3">
                        <a href="javascript:void(0)" onClick={CollapseMenu1}>
                            <img src="/images/collapse-icon.svg" width="27" />
                        </a>
                    </div>
                    <div className="text-right my-3">
                        {location.state.status == 'PIC' && <NavLink className="btn btn-brand-1 bg-success btn-sm mr-3 px-4" to={{ pathname: `/edit-proposal-dispatch/${props.match.params.id}`, state: { from: "Generate Proposal", status: location.state.status, rfx_num: preRfxData?.rfx_number, rfx_name: preRfxData?.title, client_url: preRfxData?.client_url } }}>{isLoding && <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />}Dispatch</NavLink>}
                        <button className="btn btn-brand-1 btn-sm mr-3 px-4" onClick={() => reEditTemplate()} disabled={isViewPath} /*{false || preRfxData?.proposal_status == 'PENDING' || preRfxData?.proposal_status == 'PENDING_APPROVAL' ? false : true}*/> Edit Template</button>
                         <button className="btn btn-brand-1 btn-sm mr-3 px-4" onClick={() => history.push({ pathname: '/generate-word-template', state: { id: id } })} disabled={isViewPath}>Generate Proposal Document <span className="ml-2 font-22 align-middle"></span></button>
                        {/*<button className="btn btn-brand-1 btn-sm bg-success mr-3 px-4" onClick={()=>setIsModalOpen(true)}>Import Word Document <span className="ml-2 font-22 align-middle"></span></button>    */}
                        {/* Added this code for  WO-215. Prasanna. June 03 2024.. Code Start */}
                        <button className="btn btn-brand-1 btn-sm bg-success mr-3 px-4" onClick={()=>setIsModalOpen(true)} disabled={isProposalApproved}>Import Word Document <span className="ml-2 font-22 align-middle"></span></button>
                        {/* Prasanna. June 03 2024. Code End */}
                    </div>
                    <ReactModal
                isOpen={isModalOpen}
                onRequestClose={handleCloseModal}
                className="contained-modal-title-vcenter"
                initWidth={550}
                initHeight={'auto'}
                top={137.5}>
                <div className="modal-header">
                    <h5 className="modal-title">Select Word Document</h5>
                    <button type="button" className="close" onClick={handleCloseModal}>
                        <i className="icofont-close"></i>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="form-group">
                        <label>Upload Word Document</label>
                            <div className="custom-file">
                                <input type="file" className="custom-file-input" id="inputGroupFile02" accept=".docx" onChange={handleFileSelect} />
                                <label className="custom-file-label" htmlFor="inputGroupFile02" aria-describedby="inputGroupFileAddon02">{file1 === undefined ? 'Choose File' : file1.name}</label>
                                <span className="text-danger">{modalFlag === true && file === undefined ? 'Please Select file' : null}</span>
                            </div>
                            </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>Cancel</button>
                    <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={save} disabled={buttonLoader}>
                        {buttonLoader && (
                            <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                        )}
                        <span>SaveWord</span>
                    </button>
                </div>
                </div>
            </ReactModal>
                    
                    <div className="d-flex overflow-auto pb-4" >
                        <div className="tab-style-2 mb-4" id="PraposalTemp">
                            <Tab.Container id="controlled-tab-example2" defaultActiveKey="SearchRep">
                                <Row>
                                    <div className="tab-style-1  col-12 d-flex">
                                        <Nav className="d-flex  nav nav-tabs ">
                                            <Nav.Item className="mr-3">
                                                <img src={require('../../../../assets/css/images/search.png').default} className='hide-coll-menu-page ml-2 mt-3 mr-2' id='hideCollapseButton1' title="Search Content Repo" width={30} height={30} />
                                                <div id='hideCollapseText1'>
                                                    <Nav.Link eventKey="SearchRep" >Search Content Repo</Nav.Link>
                                                </div>
                                            </Nav.Item>
                                            <Nav.Item className="mr-3">
                                                <img src={require('../../../../assets/css/images/data-icon.png').default} className='hide-coll-menu-page ml-2 mt-3 mr-2' id='hideCollapseButton2' title="RFx Data" width={30} height={30} />
                                                <div id='hideCollapseText2'>
                                                    <Nav.Link eventKey="RfxData" >RFx Data</Nav.Link>
                                                </div>
                                            </Nav.Item>
                                            <Nav.Item className="mr-3">
                                                <img src={require('../../../../assets/css/images/view-icon.png').default} className='hide-coll-menu-page ml-2 mt-3 mr-2' id='hideCollapseButton3' title="View RAW RFx" width={30} height={30} />
                                                <div id='hideCollapseText3'>
                                                    <Nav.Link eventKey="RawRFx">View RAW RFx</Nav.Link>
                                                </div>
                                            </Nav.Item>
                                            {/*{Commenting this code to remove View Sectionized Rfx  June 14 2024 Code Start...}
                                            <Nav.Item className="mr-3">
                                                <img src={require('../../../../assets/css/images/section-icon.png').default} className='hide-coll-menu-page ml-2 mt-3 mr-2' id='hideCollapseButton4' title="View Sectionized RFx" width={30} height={30} />
                                                <div id='hideCollapseText4'>
                                                    <Nav.Link eventKey="ViewSectionizedRfx">View Sectionized RFx</Nav.Link>
                                                </div>
                                            </Nav.Item>
                                            {Commenting this code to remove View Sectionized Rfx  June 14 2024 Code End...}
                                            */}
                                            <Nav.Item className="mr-3">
                                                <img src={require('../../../../assets/css/images/pre-define-icon.png').default} className='hide-coll-menu-page ml-2 mt-3 mr-2 mb-3' id='hideCollapseButton5' title="Pre-Define Sections of RFx" width={30} height={30} />
                                                <div id='hideCollapseText5'>
                                                    <Nav.Link eventKey="PreDefineSectionsofRfx">Pre-Defined Sections of RFx</Nav.Link>
                                                </div>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                    <Col sm={12} className="tab-style-2">
                                        <Tab.Content>
                                            <Tab.Pane eventKey="SearchRep">
                                                <h4 className="font-16">Content Library Search</h4>
                                                <div className="form-wraper form-style mb-4">

                                                    <div className="row">
                                                        <div className="col-md-12 col-lg-12  mb-4">
                                                            <div className="form-group">
                                                                <label className="font-bold">Topic/Question</label>
                                                                <input type="text" className="form-control mb-4" value={formFields['question']} name="question" onChange={handleChange} disabled={isViewPath}/>

                                                            </div>
                                                            <div className="text-center">
                                                                <button type="button" className="btn btn-lg btn-brand-1  mx-auto" onClick={() => aiQuestionSearch()} disabled={aiSearchButtonLoader || formFields.page == 'View' ? true : false}> {aiSearchButtonLoader && (
                                                                    <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                                                )}Search</button>
                                                            </div>
                                                            <div className="col-12 my-3" style={{ position: 'relative', right: 27 }}>
                                                                <p className="mb-0 text-primary">Search result matching the Question</p>
                                                                <table className="table table-style-1 table-responsive-lg mb-4 table " style={{ position: 'relative', right: 27 }}>
                                                                    <tr>
                                                                        <th>Question</th>
                                                                        <th>Answer</th>
                                                                        <th>Score</th>
                                                                    </tr>
                                                                    {
                                                                        aiSearchData.map((res) => {
                                                                            return (
                                                                                <tr>
                                                                                    <td>{res.question}</td>
                                                                                    <td><div dangerouslySetInnerHTML={{ __html: res.answer }}
                                                                                    style={{
                                                                                        maxWidth: '700px', 
                                                                                        overflow: 'hidden', 
                                                                                        textOverflow: 'ellipsis',
                                                                                        whiteSpace: 'normal', 
                                                                                        overflowX: 'auto'
                                                                                    }}
                                                                                    ></div></td>
                                                                                    <td>
                                                                                        {res.percentage}
                                                                                        <button className="btn btn-action p-2 mr-2" style={{ display: 'flex', alignItems: 'center' }} onClick={() => {updateAtIndex(itemNumber, res.answer)}}>
                                                                                            <i className="icofont-arrow-right" style={{ fontSize: '24px' }}></i>
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </table>
                                                                {
                                                                    searchData.length === 0 ?
                                                                        <div className="col-12" style={{ justifyContent: 'center', alignItems: 'center', alignSelf: 'center', textAlign: 'center' }}>
                                                                            <span>No Search Data Found</span>
                                                                        </div> : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-center">
                                                        <div className="col-md-12 col-lg-12">
                                                            <div className="form-group">
                                                                <label className="font-bold mb-0">Search by Keyword</label>
                                                                <input type="text" className="form-control" value={formFields['keyword']} name="keyword" onChange={handleChange} disabled={isViewPath}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-lg-6">
                                                            <div className="form-group">
                                                                <label>RFx Category</label>
                                                                <select id="disabledSelect" className="form-control custom-select" value={formFields['rfxCategory']} name="rfxCategory" onChange={handleChange} disabled={isViewPath}>
                                                                    <option value="" disabled selected>Select your option</option>
                                                                    {
                                                                        RFxCategoryData.map((res) => {
                                                                            return (
                                                                                <option value={res.id}>{res.name}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-md-6 col-lg-6">
                                                            <div className="form-group">
                                                                <label>Keywords/Tags</label>
                                                                <input type="text" className="form-control" value={formFields['tag']} name="tag" onChange={handleChange} />
                                                            </div>
                                                        </div> */}

                                                        <div className="col-md-6 col-lg-4">
                                                            <div className="form-group">
                                                                <label>Content Entered From Date</label>
                                                                <DatePicker
                                                                    selected={fromDate}
                                                                    onChange={(date) => setFromDate(date)}
                                                                    className="form-control"
                                                                    placeholder="select Date"
                                                                    dateFormat="MM-dd-yyyy"
                                                                    scrollableYearDropdown
                                                                    showYearDropdown
                                                                    disabled={isViewPath}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-lg-4">
                                                            <div className="form-group">
                                                                <label>Content Entered To Date</label>
                                                                <DatePicker
                                                                    selected={toDate}
                                                                    onChange={(date) => setToDate(date)}
                                                                    className="form-control"
                                                                    placeholder="select Date"
                                                                    dateFormat="MM-dd-yyyy"
                                                                    scrollableYearDropdown
                                                                    showYearDropdown
                                                                    disabled={isViewPath}
                                                                />
                                                            </div>
                                                        </div>



                                                        <div className="col-12 text-center mt-4">
                                                            <button type="button" className="btn btn-lg btn-brand-1 mr-3" onClick={() => _onSearch()} disabled={SearchbuttonLoader || formFields.page == 'View' ? true : false}>
                                                                {SearchbuttonLoader && (
                                                                    <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                                                )}
                                                                <span>Search</span>
                                                            </button>
                                                        </div>


                                                        <div className="col-12 my-3">
                                                            <p className="mb-0 text-primary">Search result matching keyword in Question Repository</p>
                                                            <table className="table table-style-1 table-responsive-lg mb-4 table ">
                                                                <tr>
                                                                    <th>Question</th>
                                                                    <th>Answer</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                                {
                                                                    searchData.map((res) => {
                                                                        return (
                                                                            <tr>
                                                                                <td>{res.question.replace(/(.{20})..+/, "$1…")}</td>
                                                                                <td><div dangerouslySetInnerHTML={{ __html: res.answer }} style={{width : '100%', marginRight : '-300px' }}></div></td>
                                                                                <td>
                                                                                    <button className="btn btn-action" onClick={() => onViewQA(res)}>
                                                                                        <i className="icofont-eye-alt"></i>
                                                                                    </button>
                                                                                    <button className="btn btn-action mt-2" style={{ display: 'flex', alignItems: 'center' }} onClick={() => {updateAtIndex(itemNumber, res.answer)}}>
                                                                                            <i className="icofont-arrow-right" style={{ fontSize: '22px' }}></i>
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </table>
                                                            {
                                                                searchData.length === 0 ?
                                                                    <div className="col-12" style={{ justifyContent: 'center', alignItems: 'center', alignSelf: 'center', textAlign: 'center' }}>
                                                                        <span>No Search Data Found</span>
                                                                    </div> : null
                                                            }
                                                        </div>
                                                        <div className="col-12 my-3">
                                                            <table className="table table-style-1 table-responsive-lg mb-4">
                                                                <tr>
                                                                    <th>File Name</th>
                                                                    <th>RFP Title</th>
                                                                    <th>Client Name</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                                {
                                                                    documentData.map((res) => {
                                                                        return (
                                                                            <tr>
                                                                                {/* Commenting the following code for WOII-171. Divya. January 12 2024.. Code Start  */}
                                                                                {/* <td style={{maxWidth:"620px",overflow:"auto"}}>{res.file === undefined ? null : res.file.substring(res.file.lastIndexOf('/') + 1).replace(/\-/, '|').split('|')[1]}</td> */}
                                                                                {/* Commenting end. Divya. January 12 2024.. 
	                                                                            Added this code for  WOII-171. Divya. January 12 2024.. Code Start */}
                                                                                <td>{res.file === undefined ? null : res.file.substring(res.file.lastIndexOf('/') + 1).replace(/\-/, '|').split('|')[1].replace(/(.{20})..+/, "$1…")}</td>
                                                                                {/* Divya. January 12 2024. Code End */}
                                                                                <td>{res.title.replace(/(.{20})..+/, "$1…")}</td>
                                                                                <td>{res.name.replace(/(.{30})..+/, "$1…")}</td>
                                                                                <td>
                                                                                    <button className="btn btn-action" onClick={() => window.open(res.file, "_blank")}>
                                                                                        <i className="icofont-eye-alt"></i>
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </table>
                                                            {
                                                                documentData.length === 0 ?
                                                                    <div className="col-12" style={{ justifyContent: 'center', alignItems: 'center', alignSelf: 'center', textAlign: 'center' }}>
                                                                        <span>No Search Document Found</span>
                                                                    </div> : null
                                                            }
                                                        </div>

                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="RfxData">
                                                <RfxData
                                                    page={(props.location.state && props.location.state.page == 'View') || isViewPath ? '/view-parse-rfx-detail' : null}
                                                    type={'AI'}
                                                    id={props.location.state && id} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="RawRFx">
                                                {
                                                    url ?
                                                        <RawRFx
                                                            url={url}
                                                        /> : null
                                                }
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="ViewSectionizedRfx">
                                                {props.location.state && <SectionizedRFx
                                                    rfx_id={id}
                                                   //Added this code for  WOII-72. Divya. September 11 2023.. Code Start
                                                    height={'1000'}
                                                    //Divya. September 11 2023. Code End
                                                    page={(props.location.state && formFields.page == 'View') || (isViewPath) ? '/view-parse-rfx-detail' : null} />}
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="PreDefineSectionsofRfx">
                                                <h4 className="font-16">View Pre-Defned Sections</h4>
                                                <div className="form-wraper form-style mb-4">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <select id="disabledSelect" className="form-control custom-select" value={formFields['predefine_sections']} name="predefine_sections" onChange={handleChange} disabled={isViewPath} >
                                                                    <option value="" disabled selected>Select your option</option>
                                                                    <option value="scope">Scope</option>
                                                                    {/* Commenting the following code for WOII-189. Divya. February 26 2024.. Code Start */}
                                                                    {/* <option>Requirements check list</option> */}
                                                                    {/* Commenting end. Divya. February 26 2024..
                                                                    Added this code for  WOII-189. Divya. February 26 2024.. Code Start */}
                                                                    <option value="requirement_checklist">Requirements check list</option>
                                                                    {/* Divya. February 26 2024. Code End */}
                                                                    <option value="proposal_structure">Proposal Structure</option>
                                                                    <option value="submission_structure">Submission instructions</option>
                                                                    <option value="evaluation_criteria">Evalution Criteria</option>
                                                                    <option value="contract">Contract</option>
                                                                </select>
                                                            </div>
                                                            <div className="form-group">
                                                                {/* {aiSegmentationData?.[formFields.predefine_sections] ? aiSegmentationData?.[formFields.predefine_sections] : `No Data Available`} */}
                                                                {/* <Editor
                                                                    apiKey={tiny_key}
                                                                    // onInit={(evt, editor) => proposalStructureRef.current = editor}
                                                                    // Commenting the following code for WOII-189. Divya. February 26 2024.. Code Start 
                                                                    //initialValue={aiSegmentationData?.[formFields.predefine_sections] ? aiSegmentationData?.[formFields.predefine_sections] : `No Data Available`}
                                                                    // Commenting end. Divya. February 26 2024..
                                                                    // Added this code for  WOII-189. Divya. February 26 2024.. Code Start
                                                                    initialValue={formFields['predefine_sections'] === 'requirement_checklist' ? renderTable(): (aiSegmentationData?.[formFields.predefine_sections] ? aiSegmentationData?.[formFields.predefine_sections] : `No Data Available`)}
                                                                    //Divya. February 26 2024. Code End
                                                                    disabled={true || isViewPath}
                                                                    init={{
                                                                        height: 500,
                                                                        menubar: 'file edit view insert format tools table tc help',
                                                                        selector: 'textarea#full-featured',
                                                                        plugins: 'print preview importcss paste searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image  table lists',
                                                                        toolbar: 'undo redo | blocks| bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                                                    }}
                                                                /> */}
                                                                
                                                                <CKEditor
                                                                    initData={predefined_sections}
                                                                    readOnly={true || isViewPath}
                                                                    onInstanceReady={(event) => {predefined_sections_ref.current = event.editor}}
                                                                    name="predefined_p"
                                                                    config={{
                                                                        height: 500,
                                                                        versionCheck: false,
                                                                        toolbar: [
                                                                        { name: 'document', items: ['Source', 'Save', 'NewPage', 'Preview', 'Print', 'Templates'] },
                                                                        { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', 'Undo', 'Redo'] },
                                                                        { name: 'editing', items: ['Find', 'Replace', 'SelectAll', 'Scayt'] },
                                                                        { name: 'forms', items: ['Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField'] },
                                                                        { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', 'RemoveFormat'] },
                                                                        { name: 'paragraph', items: ['NumberedList', 'BulletedList', 'Outdent', 'Indent', 'Blockquote', 'CreateDiv', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', 'BidiLtr', 'BidiRtl', 'Language'] },
                                                                        { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
                                                                        { name: 'insert', items: ['Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe'] },
                                                                        { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
                                                                        { name: 'colors', items: ['TextColor', 'BGColor'] },
                                                                        { name: 'tools', items: ['Maximize', 'ShowBlocks', 'About'] }
                                                                        ],
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </div>
                        <div style={{ minWidth: "1020px", marginLeft: 50 }}>

                            <div className="tab-style-2 mb-4" id="PraposalTemp">
                                <Tab.Container id="controlled-tab-example2" defaultActiveKey="ProposalStructure">
                                    <Row>
                                        <div className="tab-style-1  col-12 d-flex">
                                            <Nav className="d-flex  nav nav-tabs ">
                                                <Nav.Item className="mr-3">
                                                    <Nav.Link eventKey="ProposalStructure">Proposal Structure</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </div>
                                        <Col sm={12} className="tab-style-2">
                                            <Tab.Content>
                                                <Tab.Pane eventKey="ProposalStructure">
                                                    <div style={{ width: 'auto', backgroundColor: 'white' }} className="mb-4">
                                                        <Tab.Container id="left-tab-example" defaultActiveKey="0">
                                                            <Row >
                                                                <table className="table table-style-1 mb-4 table-md-responsive" id="" style={{ width: '97.2%', marginLeft: 14 }}>
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col">Heading/Title</th>
                                                                            <th scope="col">Description</th>
                                                                        </tr>
                                                                    </thead>
                                                                </table>
                                                                <Col md={4} style={{ marginTop: 10, marginLeft: 20 }} className='h-auto'>
                                                                    <Nav variant="pills" className="flex-column vertical-tabs">
                                                                        {/* Commenting the following code for WOII-72. Divya. September 11 2023.. Code Start */}
                                                                        {/*<div style={{ overflow: 'scroll', height: 410 }}>*/}
                                                                        {/* Commenting end. Divya. September 11 2023.. */}
                                                                        {/*Added this code for  WOII-72. Divya. September 11 2023.. Code Start*/}
                                                                        <div style={{ overflow: 'scroll', height: 510 }}>
                                                                        {/*Divya. September 11 2023. Code End*/}
                                                                            {
                                                                                approverData?.map((res, i) => {
                                                                                    // setHasComments(false)
                                                                                    return (
                                                                                        <Nav.Item style={{ flexDirection: 'row' }}>
                                                                                            <Nav.Link eventKey={i} onClick={() => {setItemAnswer(res.answer);setItemNumber(i);console.log(res.title);getUserComments(res.id); setPrompt(baseprompt + "\n"+res.title.replace(/^[0-9a-zA-Z]+\.\s*/, ""))}}>{res.title}</Nav.Link>
                                                                                            <div className="col-12 row" >
                                                                                                <div className="col-4">
                                                                                                    <span>Completed</span>
                                                                                                    <input type="checkbox" className="ml-1" value={res.is_check} defaultChecked={res.is_check} onChange={() => onCheckboxClick(i)} disabled={(props.location.state && props.location.state.page == 'View') || (props.location.state && props.location.state.status == 'PIC') || (props.location.state && formFields.page == 'Approval') ? true : false} />
                                                                                                </div>
                                                                                                <div className="col-8" >
                                                                                                    <select id="disabledSelect" className="font-14 ml-4 mb-2" style={{ backgroundColor: 'white', height: 40, borderColor: '#e6e6e6', borderRadius: 6 }} value={res.section} name={res.section} onChange={e => onSectionChange(i, e.target.value, 'section')} disabled={true || isViewPath}>
                                                                                                        <option value="" disabled selected>Select your option</option>
                                                                                                        <option value={'Section-Level 1'}>Section-Level 1</option>
                                                                                                        <option value={'Section-Level 2'}>Section-Level 2</option>
                                                                                                        <option value={'Section-Level 3'}>Section-Level 3</option>
                                                                                                        <option value={'Section-Level 4'}>Section-Level 4</option>
                                                                                                        <option value={'Question'}>Question</option>
                                                                                                    </select>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Nav.Item>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </Nav>
                                                                </Col>
                                                                <Col style={{ width: '50%' }}>
                                                                    <Tab.Content>
                                                                        {
                                                                            approverData?.map((res, j) => {
                                                                                return (
                                                                                    <Tab.Pane eventKey={j}>
                                                                                        {
                                                                                            approverData ?
                                                                                                <div>
                                                                                                    {/* <div className="bg-white py-4 px-lg-4 px-md-4 border rounded-theme " style={{ height: 150 }}>
                                                                                                        <div className="form-group" style={{ overflow: 'scroll', height: 120 }} dangerouslySetInnerHTML={{ __html: res.description }} ></div>
                                                                                                    </div> */}
                                                                                                    {/* <Editor
                                                                                                        apiKey={tiny_key}
                                                                                                        // onInit={(evt, editor) => proposalStructureRef.current = editor}
                                                                                                        initialValue={res.description}
                                                                                                        disabled={true || isViewPath}
                                                                                                        init={{
                                                                                                            height: 500,
                                                                                                            menubar: 'file edit view insert format tools table tc help',
                                                                                                            selector: 'textarea#full-featured',
                                                                                                            plugins: 'print preview importcss paste searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image  table lists',
                                                                                                            toolbar: 'undo redo | blocks |bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                                                                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                                                                                        }}
                                                                                                    /> */}
                                                                                                    <CKEditor
                                                                                                        initData={res.description}
                                                                                                        readOnly={true || isViewPath}
                                                                                                        name="templates_p"
                                                                                                        config={{
                                                                                                            height: 500,
                                                                                                            versionCheck: false,
                                                                                                            toolbar: [
                                                                                                            { name: 'document', items: ['Source', 'Save', 'NewPage', 'Preview', 'Print', 'Templates'] },
                                                                                                            { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', 'Undo', 'Redo'] },
                                                                                                            { name: 'editing', items: ['Find', 'Replace', 'SelectAll', 'Scayt'] },
                                                                                                            { name: 'forms', items: ['Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField'] },
                                                                                                            { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', 'RemoveFormat'] },
                                                                                                            { name: 'paragraph', items: ['NumberedList', 'BulletedList', 'Outdent', 'Indent', 'Blockquote', 'CreateDiv', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', 'BidiLtr', 'BidiRtl', 'Language'] },
                                                                                                            { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
                                                                                                            { name: 'insert', items: ['Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe'] },
                                                                                                            { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
                                                                                                            { name: 'colors', items: ['TextColor', 'BGColor'] },
                                                                                                            { name: 'tools', items: ['Maximize', 'ShowBlocks', 'About'] }
                                                                                                            ],
                                                                                                        }}
                                                                                                    />
                                                                                                    {/*Commenting the following code for WOII-95. Divya. September 20 2023.. Code Start */}
                                                                                                    {/*<div className="d-flex row justify-content-between">
                                                                                                        <h3 className="page-title" style={{ marginTop: 30 }}>Answer</h3>
                                                                                                        <div>
                                                                                                            <button type="button" className="btn btn-action edit mt-2 mr-3" onClick={() => { setCommentModal(true), getUserComments(res.id), sectionId.current = res.id, sectionTitle.current = res.title }}><img src={sendComment} style={{ marginTop: 5 }} /></button>
                                                                                                            <button type="button" className="btn btn-action edit mt-3 mr-3" style={{ backgroundColor: '#3c7bfd', height: 33, color: '#fff', width: 33, padding: 0 }} disabled={(props.location.state && props.location.state.page == 'View') || (props.location.state && formFields.page == 'Approval') || (props.location.state && props.location.state.status == 'PIC') ? true : false} onClick={() => onEditAnswer(res.answer, j)}><i className="icofont-pencil-alt-2"></i></button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {/* <div className="bg-white px-lg-4 px-md-4 border rounded-theme mt-1" > */}
                                                                                                    {/* <div className="form-group" style={{ overflow: 'scroll', height: 400 }} dangerouslySetInnerHTML={{ __html: res.answer }}></div>
                                                                                                    <Editor
                                                                                                        apiKey={tiny_key}
                                                                                                        // onInit={(evt, editor) => proposalStructureRef.current = editor}
                                                                                                        initialValue={res.answer}
                                                                                                        disabled={true}
                                                                                                        init={{
                                                                                                            height: 500,
                                                                                                            menubar: 'file edit view insert format tools table tc help',
                                                                                                            selector: 'textarea#full-featured',
                                                                                                            plugins: 'print preview importcss paste searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image  table lists',
                                                                                                            toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                                                                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                                                                                        }}
                                                                                                    />
                                                                                                    {/* </div> */} 
                                                                                                    {/*Commenting end. Divya. September 20 2023.. */}
                                                                                                </div>
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                    </Tab.Pane>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Tab.Content>

                                                                </Col>
                                                                {/*Commenting the following code for WOII-95. Divya. November 21 2023.. Code Start */}
                                                                {/*Added this code for  WOII-95. Divya. September 20 2023.. Code Start */}
                                                               {/* <Row className="mt-4 ml-2">
                                                                    <Tab.Content>
                                                                        {
                                                                            approverData?.map((res, j) => {
                                                                                return (
                                                                                    <Tab.Pane eventKey={j}>
                                                                                        {
                                                                                            approverData ? 
                                                                                                <div style={{width:"100%"}}> 
                                                                                                    <div className="row d-flex justify-content-between">
                                                                                                        <h3 className="page-title" style={{ marginLeft:400,marginBottom:30}}>Answer</h3>
                                                                                                        <div>
                                                                                                            <button type="button" className="btn btn-action edit mr-3" onClick={() => { setCommentModal(true), getUserComments(res.id), sectionId.current = res.id, sectionTitle.current = res.title }}><img src={sendComment}/></button>
                                                                                                            <button type="button" className="btn btn-action edit mr-4" style={{ backgroundColor: '#3c7bfd', height: 33, color: '#fff', width: 33, padding: 0 }} disabled={(props.location.state && props.location.state.page == 'View') || (props.location.state && formFields.page == 'Approval') || (props.location.state && props.location.state.status == 'PIC') ? true : false} onClick={() => onEditAnswer(copiedData === ''? res.answer: copiedData, j)}><i className="icofont-pencil-alt-2"></i></button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <Editor
                                                                                                        apiKey={tiny_key}
                                                                                                        initialValue={copiedData === ''? res.answer: copiedData}
                                                                                                        disabled={true}
                                                                                                        init={{
                                                                                                            height: 500,
                                                                                                            menubar: 'file edit view insert format tools table tc help',
                                                                                                            selector: 'textarea#full-featured',
                                                                                                            plugins: 'print preview importcss paste searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image  table lists',
                                                                                                            toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                                                                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                                                                                        }}
                                                                                                    />
                                                                                                </div> 
                                                                                                :
                                                                                                null
                                                                                        } 
                                                                                    </Tab.Pane> 
                                                                                )
                                                                            })
                                                                        }
                                                                    </Tab.Content>          
                                                                    </Row> */}
                                                                {/*Divya. September 20 2023. Code End*/}
                                                                {/*Commenting end. Divya. November 21 2023.. */}
                                                            </Row>
                                                            <Row className="card" style={{marginLeft : "18px", marginRight : "0px", paddingTop : "3px"}}>
                                                            <Tab.Content>
                                                            {
                                                                approverData?.map((res, j) => {
                                                                    // getUserComments(res.id)
                                                                    /* Added this code for  WO-215. Prasanna. June 03 2024.. Code Start */
                                                                    const isProposalApproved = location.state.proposal_status === 'Proposal Is Approved';
                                                                    /* Prasanna. June 03 2024. Code End */
                                                                    return (
                                                                        <Tab.Pane eventKey={j}>
                                                                            {
                                                                                approverData ? 
                                                                                    <div> 
                                                                                        <div className="row d-flex justify-content-between">
                                                                                            <h3 className="page-title" style={{ marginLeft:500,marginBottom:30}}>Answer</h3>
                                                                                            <div>
                                                                                                {hasComments ?(
                                                                                                    <button type="button" className="btn btn-action edit mr-3" onClick={() => { setCommentModal(true), getUserComments(res.id), sectionId.current = res.id, sectionTitle.current = res.title }}><img src={sendComment2}/></button>
                                                                                                ):(
                                                                                                    <button type="button" className="btn btn-action edit mr-3" onClick={() => { setCommentModal(true), getUserComments(res.id), sectionId.current = res.id, sectionTitle.current = res.title }}><img src={sendComment1}/></button>
                                                                                                )

                                                                                                }
                                                                                                

                                                                                                <button type="button" className="btn btn-action edit mr-4" style={{ backgroundColor: 'white', height: 35, color: '#fff', width: 35, padding: 0, borderRadius: '50%', overflow: 'hidden', outline: 'none' }} disabled={(props.location.state && props.location.state.page == 'View') || (props.location.state && formFields.page == 'Approval') || (props.location.state && props.location.state.status == 'PIC') || ((location.state.proposal_status == 'Proposal Is Approved') ? true : false) || (isViewPath)} onClick={() => onEditAnswer(answerOutRefs.current[j].getData(), j)}><img src={gptlogo} style={{ width: 35, height: 35, borderRadius: '50%' }}/> </button>
                                                                                            </div>
                                                                                        </div>
                                                                                        {/* <Editor
                                                                                            apiKey={tiny_key}
                                                                                            onInit={(evt, editor) => answerOutRefs.current[j] = editor}
                                                                                            // initialValue={(copiedData[itemNumber] === '' || copiedData[itemNumber] != undefined)? res.answer:copiedData[[itemNumber]]}
                                                                                            // onEditorChange={(evt, editor) => answerOutRef.current = editor}
                                                                                            initialValue={res.answer}
                                                                                            onChange={() => {
                                                                                            if (!tinyChangesidx.includes(j)) {
                                                                                                setTinyChangesidx((prev) => [...prev, j]);
                                                                                            }
                                                                                            }}                                                                                          
                                                                                            disabled={isProposalApproved}                                                                                
                                                                                            init={{
                                                                                                height: 500,
                                                                                                menubar: 'file edit view insert format tools table tc help',
                                                                                                selector: 'textarea#full-featured',
                                                                                                plugins: 'print preview importcss paste searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image  table lists',
                                                                                                toolbar: 'undo redo|blocks | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                                                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                                                                            }}
                                                                                            
                                                                                        /> */}
                                                                                        <CKEditor
                                                                                            initData={res.answer}
                                                                                            readOnly={isProposalApproved}
                                                                                            onInstanceReady={(event) => answerOutRefs.current[j] = event.editor}
                                                                                            name="answers_p"
                                                                                            onChange={(event) => {
                                                                                                const editor = event.editor;
                                                                                                const data = editor.getData();
                                                                                                if (!tinyChangesidx.includes(j)) {
                                                                                                    setTinyChangesidx((prev) => [...prev, j]);
                                                                                                }
                                                                                            }}
                                                                                            config={{
                                                                                                height: 500,
                                                                                                versionCheck: false,
                                                                                                toolbar: [
                                                                                                { name: 'document', items: ['Source', 'Save', 'NewPage', 'Preview', 'Print', 'Templates'] },
                                                                                                { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', 'Undo', 'Redo'] },
                                                                                                { name: 'editing', items: ['Find', 'Replace', 'SelectAll', 'Scayt'] },
                                                                                                { name: 'forms', items: ['Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField'] },
                                                                                                { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', 'RemoveFormat'] },
                                                                                                { name: 'paragraph', items: ['NumberedList', 'BulletedList', 'Outdent', 'Indent', 'Blockquote', 'CreateDiv', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', 'BidiLtr', 'BidiRtl', 'Language'] },
                                                                                                { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
                                                                                                { name: 'insert', items: ['Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe'] },
                                                                                                { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
                                                                                                { name: 'colors', items: ['TextColor', 'BGColor'] },
                                                                                                { name: 'tools', items: ['Maximize', 'ShowBlocks', 'About'] }
                                                                                                ],
                                                                                            }}
                                                                                        />
                                                                                        {/* {console.log(answerOutRef.current.getContent())} */}
                                                                                    </div> 
                                                                                    :
                                                                                    null
                                                                            } 
                                                                        </Tab.Pane> 
                                                                    )
                                                                })
                                                            }
                                                        </Tab.Content>          
                                                        </Row>
                                                    </Tab.Container>
                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </div>
                            {
                                <div className="col-12">
                                    <div className="card">
                                    {/* Commenting the following code for WI-2. Divya. June 19 2023.. Code Start */}
                                        {/*<div className="card-header">*/}
                                    {/* Commenting end. Divya. June 19 2023.. */}
                                    {/* Added this code for WI-2. Divya. June 19 2023.. Code Start */}
                                        <div className="card-header d-flex justify-content-between align-items-center">
                                    {/* Divya. June 19 2023. Code End */}
                                            <h4 className="font-16 mb-0">Comments</h4>
                                            {/* Added this code for WI-2. Divya. June 19 2023.. Code Start */}
                                            <button className="btn btn-sm" type="button" style={{fontSize: '20px',border:'solid'}} onClick={handleToggleCollapse}>{isCollapsed ? "+":"-"}</button>
                                            {/* Divya. June 19 2023. Code End */}
                                            </div>
                                        {/*added this "!isCollapsed && (" line for WOII-69 on August 21 2023 Divya*/}
                                        {!isCollapsed && ( 
                                        //Divya August 21 2023 code end
                                        <div className="card-body bg-white">
                                            {
                                                commentData.map((res, index) => {
                                                    return (
                                                        <div key={index} className="bg-light p-2 rounded mb-2">
                                                            <p className="mb-1">
                                                                <span className="font-medium mr-2">{res.user.first_name} {res.user.last_name}</span>
                                                                <span className="text-secondary font-12 mr-3">{moment(res.created_date).format("D-MMM-YYYY h:mm a")}</span>
                                                            </p>
                                                            <p className="font-14 mb-0 ">{res.comment}</p>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {/*added this "!isCollapsed && (" line for WI-2 0n JUNE 19 2023 Divya*/}  
                                            {/* Commenting the following code for WOII-69. Divya. August 21 2023.. Code Start */}                                                                                 
                                            {/*{!isCollapsed && ( */}  
                                            {/* Commenting end. Divya. August 21 2023.. */}        
                                                <div className="form-group form-style">
                                                    <label>Add Comment</label>
                                                    <textarea className="form-control h-auto mb-2" rows="5" placeholder="Write here.." onChange={handleChange} name="comment" value={formFields['comment']}></textarea>
                                                    <button type="button" className="btn btn-brand-1 btn-sm" onClick={() => addComment()} disabled={formFields.commentAddLoader || (props.location.state && props.location.state.page == 'View')}>
                                                        {formFields.commentAddLoader && (
                                                            <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                                        )}
                                                        Post <i class="icofont-paper-plane"></i></button>
                                                </div>
                                            {/* Commenting the following code for WOII-69. Divya. August 21 2023.. Code Start */}                                         
                                           {/* )} */}
                                            {/* Commenting end. Divya. August 21 2023.. */} 
                                        </div>
                                        //added this code for WOII-69 on August 21 2023 Divya
                                        )}
                                        {/*Divya August 21 2023 code end*/}
                                    </div>
                                </div>
                            }


                            <div className="col-12 my-3">
                                <div className="d-flex flex-row justify-content-between">
                                    {/* Commenting the following code for WOII-73. Divya. September 04 2023.. Code Start */}
                                    {/*<p className="font-medium">Upload Proposal</p>*/}
                                    {/* Commenting end. Divya. September 04 2023.. */}
                                    {/* Added this code for  WOII-73. Divya. September 04 2023.. Code Start */}
                                    <p className="font-20 font-bold">Upload Proposal</p>
                                    {/* Divya. September 04 2023. Code End */}
                                    <button type="button" className="btn btn-brand-1 btn-h-40 bg-success px-4 mb-4" disabled={props.location.state && props.location.state.page == 'View'} onClick={() => setAddDocumentShow(!addDocumentShow)}>+ Add</button>
                                </div>
                                <table className="table table-style-1 table-responsive-lg mb-4 table ">
                                    <tr>
                                        <th>Document Type</th>
                                        <th>File Name</th>
                                        {/* <th>Version</th> */}
                                        <th>Description</th>
                                        <th>Action</th>
                                    </tr>
                                    {
                                        addDocuments.map((res, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{res.document_type_name}</td>
                                                    <td>{res.file_name}</td>
                                                    {/* <td>{res.version}</td> */}
                                                    <td>{res.description}</td>
                                                    <td>
                                                        <button type="button" className="btn btn-action" onClick={() => window.open(res.file, "_blank")}><i className="icofont-eye-alt"></i></button>
                                                        {(props.location.state && props.location.state.page == 'Approval') || (props.location.state && props.location.state.page == 'View') ? null : <button type="button" className="btn btn-action edit mr-1 ml-1" onClick={() => _onEditDocument(i, res)}><i className="icofont-pencil-alt-2"></i></button>}
                                                        {(props.location.state && props.location.state.page == 'Approval') || (props.location.state && props.location.state.page == 'View') ? null : <button type="button" className="btn btn-action delete" onClick={() => deleteProposalDocument(res)}><i className="icofont-archive"></i></button>}

                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }

                                </table>
                            </div>
                            <div className="d-flex justify-content-center">

                                <div className="">
                                    <button type="button" className="btn btn-lg btn-brand-1 bg-danger ml-3" onClick={() => goBack()}>Cancel</button>
                                    {(props.location.state && props.location.state.status == 'PIA') ? <button type="button" className="btn btn-lg btn-brand-1 ml-3 bg-success" disabled={props.location.state && props.location.state.page == 'View'} onClick={() => onUpdateStatus('PROPOSAL_COMPLETED')}>
                                         {formFields.submitButtonLoader && (
                                        <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                    )}Submit</button> : null}
                                    {(props.location.state && props.location.state.status == 'PIA') || (props.location.state && props.location.state.status == 'Proposal Sent for Approval') ? null : <button type="button" className="btn btn-lg btn-brand-1 ml-3 mr-3 bg-success" disabled={(props.location.state && props.location.state.page == 'Approval') || (props.location.state && props.location.state.status) == 'PIA' || (props.location.state && props.location.state.status) == 'PIC' || props.location.state.status == 'PD' || formFields.saveButtonLoader || (props.location.state && props.location.state.page == 'View') ? true : false} onClick={() => onSubmit()}>{formFields.saveButtonLoader && (
                                        <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                    )}Save</button>}
                                    {(props.location.state && (formFields.page == 'Proposal' || formFields.page == 'Edit')) && (props.location.state && props.location.state.status) != 'PIA' ?
                                        <button className="btn btn-brand-1  px-4" href="/pre-rfx-approvals-filters" onClick={() => _onSendForApproval()} disabled={formFields.sendForApprovalLoader || (props.location.state && props.location.state.status) == 'PIA' || (props.location.state && props.location.state.status) == 'PIC' || props.location.state.status == 'PD' || (props.location.state && props.location.state.status) == 'PSA'}>
                                            {formFields.sendForApprovalLoader && (
                                                <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                            )}Send for Approval</button> : null}
                                    {(props.location.state && formFields.page == 'Approval') ? 
                                    <button type="button" className="btn btn-lg btn-brand-1" onClick={() => onSubmit('PUSHBACK')}>
                                        {pushBackLoader && (
                                                <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                        )}
                                        PushBack
                                    </button> : null}
                                    {(props.location.state && formFields.page == 'Approval') ? 
                                    <button type="button" className="btn btn-lg btn-brand-1 bg-danger ml-3" onClick={() => onSubmit('DENY')}>
                                        {denyLoader && (
                                                <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                        )}
                                        Deny
                                    </button> : null} 
                                    {(props.location.state && formFields.page == 'Approval') ? 
                                    <button type="button" className="btn btn-lg btn-brand-1 bg-success ml-3" onClick={() => onSubmit('PROPOSAL_APPROVED')}>
                                        {approveLoader && (
                                                <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                        )}
                                        Approve
                                    </button> : null}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            {/* Add Modal Box */}
            <ReactModal
                initWidth={550}
                initHeight={439}
                top={665}
                className={"contained-modal-title-vcenter"}
                onRequestClose={() => setShow(false)}
                isOpen={show}
                disableResize={true}
                disableKeystroke={true}>
                <div className="modal-header">
                    <h5 className="modal-title" id="">Details</h5>
                    <button type="button" className="close" onClick={() => setShow(false)}>
                        <i className="icofont-close"></i>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row form-style">

                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Question</label>
                                <textarea className="form-control h-auto" id="" cols="30" rows="2" name="modalQuestion" value={formFields['modalQuestion']}></textarea>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Answer</label>
                                <textarea className="form-control h-auto" id="" cols="30" rows="3" name="modalAnswer" value={formFields['modalAnswer'].replace(regex, '')}></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-lg btn-brand-1 bg-danger" onClick={() => setShow(false)}>Cancel</button>
                </div>
            </ReactModal>


            {/* MODAL */}
            <ReactModal
                initWidth={500}
                initHeight={'auto'}
                top={900}
                disableKeystroke={true}
                className={"contained-modal-title-vcenter"}
                onRequestClose={() => setAddDocumentShow(!addDocumentShow)}
                isOpen={addDocumentShow}
                disableResize={true}>
                <div className="modal-header">
                    <h5 className="modal-title" id="">{editDocumentId ? 'Edit' : 'Add'} Documents</h5>
                    <button type="button" className="close" onClick={() => onCancelModal()}>
                        <i className="icofont-close"></i>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row form-style">
                        <div className="col-md-12">
                            {/* <div className="form-group">
                                <label>Version</label>
                                <input type="text" className="form-control" onChange={handleChange} name="version" value={formFields['version']} />
                                <span className="text-danger">{modalFlag === true && formFields.version === '' ? 'Please Enter Version' : null}</span>
                            </div> */}
                            <div>
                                <div className="form-group">
                                    <label>Description</label>
                                    <textarea className="form-control h-auto" id="" cols="30" rows="3" onChange={handleChange} name="description" value={formFields['description']}></textarea>
                                    <span className="text-danger">{modalFlag === true && formFields.description === '' ? 'Please Enter Description' : null}</span>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Document Type</label>
                                <select id="disabledSelect" className="form-control custom-select" value={formFields['document_type']} name="document_type" onChange={handleChange}>
                                    <option value="" disabled selected>Select your option</option>
                                    {
                                        documentList.map((res, index) => {
                                            return (
                                                <option key={index} value={res.id}>{res.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <span className="text-danger">{modalFlag === true && formFields.document_type_id === '' ? 'Please Select Document Type' : null}</span>
                            </div>
                            <div className="form-group">
                                <label>Upload File</label>
                                <div className="custom-file">
                                    <input type="file" className="custom-file-input" id="inputGroupFile02" onChange={filehandler} multiple/>
                                    {/* <label className="custom-file-label" for="inputGroupFile02" aria-describedby="inputGroupFileAddon02">{file === undefined ? 'Choose File' : (file.length ===1 ? file[0].name : file.length +" files are selected ")}</label> */}
                                    <label className="custom-file-label" for="inputGroupFile02" aria-describedby="inputGroupFileAddon02">{editIndex < 0 ? (file === undefined ? 'Choose File' : (file.length === 1 ? file[0].name : file.length + ' files are selected')) : (addDocuments[editIndex].file_name )}</label>
                                   
                                    {/* <label className="custom-file-label" for="inputGroupFile02" aria-describedby="inputGroupFileAddon02">{file === undefined ? 'Choose File' : file.name}</label> */}
                                    <span className="text-danger">{modalFlag === true && file === undefined ? 'Please Select file' : null}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-lg btn-brand-1 bg-danger" onClick={() => onCancelModal()}>Cancel</button>
                    <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => _onAddDocument()} disabled={modalButtonLoader || isViewPath}>
                        {modalButtonLoader && (
                            <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                        )}
                        <span>Save</span>
                    </button>
                </div>
            </ReactModal>



            <Modal show={EditTemplateModal} onHide={() => setEditTemplateModal(!EditTemplateModal)}

                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <div className="modal-header">
                    <h5 className="modal-title" id="">Alert</h5>
                </div>
                <Modal.Body>
                    <div>
                        <span>If you click OK, you will loose Answers you might have entered and template will have to go through approval again.</span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setEditTemplateModal(!EditTemplateModal)} style={{ width: 100 }}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={() => history.push({ pathname: `/edit-proposal-template/${id}`, state: { detail: formFields.preRfxId, title: props.location.state.rfx_title, rfx_number: props.location.state.rfx_number } })} style={{ width: 100 }} disabled={isViewPath}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* Answer Modal */}
            <ReactModal
                initHeight={'auto'}
                top={505}
                left={400}
                initWidth={1050}
                className={"contained-modal-title-vcenter"}
                onRequestClose={() => setAnswerModal(false)}
                isOpen={answerModal}
                disableResize={true}
                disableKeystroke={true}>
                <Container className="px-4">
                    <div className="modal-header">
                        <h5 className="modal-title" id="">Edit/ Generate Answer</h5>
                        <button type="button" className="close" onClick={() => setAnswerModal(false)}>
                            <i className="icofont-close"></i>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row form-style">

                            <div className="form-group" style={{ width: "2000px", marginLeft: 25 }}>
                                <Row>
                                    <Col md={12} lg={12} sm={12} className="form-group">
                                        <label for="exampleFormControlTextarea1"><h6 style={{display:"inline", fontWeight:"bold", color:"brown"}}>Your Prompt </h6><span>(Use the prompt below or create your own prompt to Generate a different answer.)</span></label>
                                        {error && <p className="error alert alert-danger" role="alert" style={{fontWeight:"bold"}}>{error}</p>}
                                        {/* <textarea className="form-control border-light" id="exampleFormControlTextarea1" rows="5" style={{height:"170px",borderWidth: '3px'}} onChange={handlePromptChange} value={'As a way of preparing a resonse to '+ preRfxData?.title ? preRfxData?.title : '-'+' RFP, provide answers to the following RFP Questions. RFP is issued by Solomons International. Provide this proposal on behalf of Solomons International.\nRFP question:'}></textarea> */}
                                        <textarea className="form-control border-light" id="exampleFormControlTextarea1" rows="5" style={{height:"170px",borderWidth: '3px'}} onChange={handlePromptChange} value={prompt} required></textarea>
                                    </Col>
                                </Row>
                                <Row className="justify-content-end">
                                    <Col md={3} lg={3} sm={6}>
                                        <button type="button" className="btn btn-brand-1 bg-success mb-4" onClick={handleGenerate} disabled={processing || isViewPath} style={{'&:focus': {backgroundColor: '#218838' }}}>{processing ? (
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                        ) : (
                                        'Generate'
                                        )}
                                        </button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} lg={12} sm={12}>
                                        <label><h6 style={{color:"brown", fontWeight:"bold"}}>Model Response</h6></label>
                                        {/* <Editor
                                            apiKey={tiny_key}
                                            onInit={(evt, editor) => answerRef.current = editor}
                                            initialValue={answer}
                                            init={{
                                                height: 500,
                                                menubar: 'file edit view insert format tools table tc help',
                                                selector: 'textarea#full-featured',
                                                plugins: 'print preview importcss paste searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image  table lists',
                                                toolbar: 'undo redo|blocks | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }',
                                                default: [
                                                    { start: '*', end: '*', format: 'bold' },
                                                    { start: '**', end: '**', format: 'bold' },]
                                            }}
                                        /> */}
                                        <CKEditor
                                                                                            initData={answer}
                                
                                                                                            onInstanceReady={(event) => answerRef.current = event.editor}
                                                                                            name="answers_generate_p"
                
                                                                                            config={{
                                                                                                height: 500,
                                                                                                versionCheck: false,
                                                                                                toolbar: [
                                                                                                { name: 'document', items: ['Source', 'Save', 'NewPage', 'Preview', 'Print', 'Templates'] },
                                                                                                { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', 'Undo', 'Redo'] },
                                                                                                { name: 'editing', items: ['Find', 'Replace', 'SelectAll', 'Scayt'] },
                                                                                                { name: 'forms', items: ['Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField'] },
                                                                                                { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', 'RemoveFormat'] },
                                                                                                { name: 'paragraph', items: ['NumberedList', 'BulletedList', 'Outdent', 'Indent', 'Blockquote', 'CreateDiv', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', 'BidiLtr', 'BidiRtl', 'Language'] },
                                                                                                { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
                                                                                                { name: 'insert', items: ['Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe'] },
                                                                                                { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
                                                                                                { name: 'colors', items: ['TextColor', 'BGColor'] },
                                                                                                { name: 'tools', items: ['Maximize', 'ShowBlocks', 'About'] }
                                                                                                ],
                                                                                            }}
                                                                                        />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-lg btn-brand-1 bg-danger" onClick={() => setAnswerModal(!answerModal)}>Cancel</button>
                        <button disabled={isViewPath} type="button" className="btn btn-lg btn-brand-1 ml-3 mr-3 bg-success" onClick={() => onSubmitAnswer()}>{formFields.saveButtonLoader && (
                            <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                        )}Save</button>
                    </div>
                </Container>
            </ReactModal>



            {/* Comment Modal */}
            <ReactModal
                initWidth={1000}
                initHeight={'auto'}
                top={200}
                left={400}
                className={"contained-modal-title-vcenter"}
                onRequestClose={() => setCommentModal(false)}
                isOpen={commentModal}
                disableResize={true}
                disableKeystroke={true}>
                <div className="modal-header">
                    <h5 className="modal-title" id="">Comments</h5>
                    <button type="button" className="close" onClick={() => setCommentModal(false)}>
                        <i className="icofont-close"></i>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row form-style">

                        <div className="form-group" style={{ width: "950px", marginLeft: 25 }}>
                            <label>Send Comments to</label>
                            <MultiSelect
                                options={users}
                                value={selected}
                                onChange={setSelected}
                                labelledBy="Select"
                            />
                            <label className="mt-2">Comments</label>
                            <textarea className="form-control h-auto mb-2" rows="5" placeholder="Write here.." onChange={handleChange} name="comment" value={formFields['comment']}></textarea>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-end">
                    <button type="button" className="btn btn-lg btn-brand-1 bg-danger" onClick={() => setCommentModal(false)}>Cancel</button>
                    <button disabled={isViewPath} type="button" className="btn btn-lg btn-brand-1 ml-3 mr-3 bg-success" onClick={() => onSubmitComment()}>{formFields.saveButtonLoader && (
                        <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                    )}Send</button>
                </div>
                <div className="comment-history-view">
                    <div className="comment-history-top-view">Comments History</div>
                    <div className="d-flex proposal-comment-view">
                        {
                            commentsData.length > 0 && commentsData.map((res) => {
                                return (
                                    <div className="comment-msg-box">
                                        <div>{res.name} {moment(res.created_date).format('MM-DD-YYYY')}</div>
                                        <div>{res.comment}</div>
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
            </ReactModal>
            {
                showChatModal == false && preRfxData?.id &&
                <div className='chat' onClick={() => setShowChatModal(!showChatModal)}>
                    {/* Commenting the following code for WOII-103. Divya. October 06 2023.. Code Start */}
                    {/*<img src={chatIcon} />*/}
                    {/* Commenting end. Divya. October 06 2023.. */}
                    {/* Added this code for  WOII-103. Divya. October 06 2023.. Code Start */}
                    <img src={chatIcon}  className="chat-icon"/>
                    {/* Commenting the following code for WOII-103. Divya. November 14 2023.. Code Start */}
                    {/*<span className='chat-tooltip'>Ask me any question related to the RFx.<br/>Example: What is the due date?</span>*/}
                    {/* Commenting end. Divya. November 14 2023.. */}
                    {/* Added this code for  WOII-103. Divya. November 14 2023.. Code Start */}
                    <span className='chat-tooltip'>Ask me any question related to the RFx.</span>
                    {/* Divya. November 14 2023. Code End */}
                    {/* Divya. October 06 2023. Code End */}
                </div>
            }
            {
                showChatModal &&
                <Chat
                    id={preRfxData?.id}
                    title={preRfxData?.title}
                    onClose={() => setShowChatModal(!showChatModal)} />
            }
        </>
    )
}
export default GenerateProposalMp;