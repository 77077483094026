import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { APIMethod } from "../../../../API/APIClient";
import Loader from "../../../../components/Loader";
import ModalPopup from "../../../../components/ModalPopUp";
import Pagination from "../../../../components/Pagination";
import { useHistory } from "react-router";
import NoData from "../../../../components/NoDataFound";
import SearchNoDataFound from "../../../../components/SearchNoDataFound";
import ReactModal from "react-modal-resizable-draggable";

const DocumentEntries = () => {
  const [getListData, setGetListData] = useState([]);
  const [documentData, setDocumentData] = useState([]);
  const [loading, setLoading] = useState(false);
  // Delete
  const [deleteId, setDeleteId] = useState("");
  const [showModalPopup, setShowModalPopup] = useState(false);
  // page
  const [pageCount, setPageCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  //Added this code for  WOII-120. Divya. November 11 2023.. Code Start
  const [rowsPerPage, setRowsPerPage] = useState(10);
  //Divya. November 11 2023. Code End
  const history = useHistory();
  const [adding, setAdding] = useState();
  const [editing, setEditing] = useState();
  const [deleting, setDeleting] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  // Commenting the following code for WOII-185. Divya. March 18 2024.. Code Start
  // const [formFields, setFormFields] = useState({
  //     document_name: '', document_type: '', client_name: ''
  // })
  //Commenting end. Divya  March 18 2024..

  //Added this code for  WOII-185. Divya. March 18 2024.. Code Start
  const [formFields, setFormFields] = useState({
    document_name: "",
    document_type: "",
    client_name: "",
    status: "ACTIVE",
  });
  //Divya. March 18 2024. Code End

  useEffect(() => {
    //Commenting the following code for WOII-120. Divya. November 11 2023.. Code Start
    //getDocumentEntriesList(1)
    //Commenting end. Divya. November 11 2023..
    //Added this code for  WOII-120. Divya. November 11 2023.. Code Start
    getDocumentEntriesList(currentPage);
    //Divya. November 11 2023. Code End
    getDocumentType();
    var data = JSON.parse(localStorage.getItem("user_Role"));
    checkPermission(data);
    //Commenting the following code for WOII-120. Divya. November 11 2023.. Code Start
    //}, [])
    //Commenting end. Divya. November 11 2023..
    //Added this code for  WOII-120. Divya. November 11 2023.. Code Start
  }, [rowsPerPage, currentPage]);
  //Divya. November 11 2023. Code End

  const checkPermission = (data) => {
    const resObject = data.find(
      (item) => item.name === "Content Library Setup"
    );
    const valObject = resObject.access.find(
      (item) => item.name === "Document Entries"
    );
    setAdding(valObject.is_create);
    setEditing(valObject.is_update);
    setDeleting(valObject.is_delete);
  };

  const getDocumentEntriesList = (page, key) => {
    setLoading(true);
    const new_key = key === undefined ? "" : key;
    global.new_key = key === undefined ? "" : key;
    var method = "GET";
    //Commenting the following code for WOII-120. Divya. November 11 2023.. Code Start
    //const apifetcherObj = APIMethod(`/document/list/${page}?document_type_id=${formFields.document_type}&client_name=${formFields.client_name}&keyword=` + (new_key || formFields.document_name), null, method)
    //Commenting end. Divya. November 11 2023..
    //Added this code for  WOII-120. Divya. November 11 2023.. Code Start
    //Added 'status' parameter to below api method for WOII-185
    const apifetcherObj = APIMethod(
      `/document/list/${page}?document_type_id=${formFields.document_type}&client_name=${formFields.client_name}&limit=${rowsPerPage}&keyword=${formFields.document_name}&status=${formFields.status}` +
        new_key,
      null,
      method
    );
    //Divya. November 11 2023. Code End
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];

        if (statusCode === 200) {
          setGetListData(data.list);
          if (page === 1) {
            setPageCount(data.count);
          }
          setCurrentPage(page);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getDocumentType = () => {
    setLoading(true);
    var method = "GET";
    const apifetcherObj = APIMethod(
      `/rfx-document-type/all/list`,
      null,
      method
    );
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];

        if (statusCode === 200) {
          setDocumentData(data);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const nextPage = (pageNumber) => {
    //Commenting the following code for WOII-120. Divya. November 11 2023.. Code Start
    //getDocumentEntriesList(pageNumber)
    //Commenting end. Divya. November 11 2023..
    //Added this code for  WOII-120. Divya. November 11 2023.. Code Start
    setCurrentPage(pageNumber);
    //Divya. November 11 2023. Code End
  };

  {
    /* Added this code for  WOII-120. Divya. November 11 2023.. Code Start */
  }
  const handleRowsPerPageChange = (selectedValue) => {
    setRowsPerPage(selectedValue);
  };
  {
    /* Divya. November 11 2023. Code End */
  }

  const _onDelete = (id) => {
    setDeleteId(id);
    isShowPopup(true);
  };

  const isShowPopup = (status) => {
    setShowModalPopup(status);
  };

  const Search = (key) => {
    getDocumentEntriesList(1, key);
  };

  const _onEdit = (val) => {
    history.push({
      pathname: "/add-doc",
      state: { details: val },
    });
  };

  const getData = () => {
    getDocumentEntriesList(1);
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value,
    };
    setFormFields(formFieldsObject);
  };

  const _onResetFilter = (type) => {
    if (type === "DOCUMENT_NAME") {
      formFields.document_name = "";
    } else if (type === "DOCUMENT_TYPE") {
      formFields.document_type = "";
    } else if (type === "CLIENT_NAME") {
      formFields.client_name = "";
    }
    //Added this code for  WOII-185. Divya. March 18 2024.. Code Start
    else if ((type = "STATUS")) {
      formFields.status = "";
    }
    //Divya. March 18 2024. Code End
    else {
      formFields.document_name = "";
      formFields.document_type = "";
      formFields.client_name = "";
      //Added this code for  WOII-185. Divya. March 18 2024.. Code Start
      formFields.status = "";
      //Divya. March 18 2024. Code End
      handleClose();
    }
    getDocumentEntriesList(1, "");
  };

  //Added this code for  WOII-185. Divya. March 18 2024.. Code Start
  const enableRow = (id) => {
    var method = "PUT";
    const apifetcherObj = APIMethod(`/document/${id}/enable`, null, method);
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];

        if (statusCode === 200) {
          getDocumentEntriesList(currentPage);
          isShowPopup(false);
        }
      })
      .catch((error) => {
        console.error("Error enabling Document entries:", error);
      });
  };

  const deleteRow = (id) => {
    var method = "DELETE";
    const apifetcherObj = APIMethod(`/document/${id}/delete`, null, method);
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];

        if (statusCode === 200) {
          getDocumentEntriesList(currentPage);
          isShowPopup(false);
        }
      })
      .catch((error) => {
        console.error("Error in deleting Document entries:", error);
      });
  };
  //Divya. March 18 2024. Code End

  const _onFilter = () => {
    getDocumentEntriesList(1, "");
    handleClose();
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content">
          <div className="row mb-3 align-items-center justify-content-between">
            <div className="col-lg-4 col-md-12 d-md-flex mb-md-0 mb-2 mb-lg-0 mb-md-3">
              <h2 className="page-title mb-md-0">Document Entries </h2>
            </div>
            <div className="col-lg-5 col-md-12 text-right d-md-flex align-items-center justify-content-center">
              <div className="input-group page-cont-search mr-md-3 mb-md-0 mb-2">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search by document name"
                  onChange={(event) => Search(event.target.value)}
                />
                <span className="input-group-text text-secondary">
                  <i className="icofont-search-1"></i>
                </span>
              </div>
              <button
                type="button"
                className="btn btn-brand-1 btn-h-40 mr-3"
                onClick={() => setShow(true)}
              >
                <i className="icofont-filter mr-2"></i> Filter
              </button>
              {adding === true ? (
                <NavLink
                  to="/add-doc"
                  className="btn btn-brand-1 bg-success px-4"
                >
                  + Add
                </NavLink>
              ) : null}
            </div>
          </div>

          <div className="mb-2 filter-by">
            {formFields.document_name ? (
              <div className="badge">
                <span className="lable">Document Name</span>
                <span className="value">{formFields.document_name}</span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("DOCUMENT_NAME")}
                ></i>
              </div>
            ) : null}
            {formFields.document_type ? (
              <div className="badge">
                <span className="lable">Document Type</span>
                {documentData.map((res, index) => {
                  return (
                    <span key={index} className="value">
                      {formFields.document_type == res.id ? res.name : null}
                    </span>
                  );
                })}
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("DOCUMENT_TYPE")}
                ></i>
              </div>
            ) : null}
            {formFields.client_name ? (
              <div className="badge">
                <span className="lable">Client/Source Name</span>
                <span className="value">{formFields.client_name}</span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("CLIENT_NAME")}
                ></i>
              </div>
            ) : null}
            {/* Added this code for  WOII-185. Divya. March 18 2024.. Code Start  */}
            {formFields.status ? (
              <div className="badge">
                <span className="lable">Status</span>
                <span className="value">
                  {formFields.status == "ACTIVE"
                    ? "Active"
                    : formFields.status == "DELETED"
                    ? "Archived"
                    : formFields.status}
                </span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("STATUS")}
                ></i>
              </div>
            ) : null}
            {/* Divya. March 18 2024. Code End */}
          </div>

          <div
            style={{ height: "500px", overflowY: "auto", marginBottom: "20px" }}
          >
            <table className="table table-style-1 table-responsive-md mb-4 table reports">
              <thead className="sticky-top" style={{ zIndex: 10 }}>
                <tr>
                  <th scope="col">Document Name</th>
                  <th scope="col">Document Type</th>
                  <th scope="col">Client/Source Name </th>
                  <th scope="col">File Name</th>
                  <th scope="col" className="text-center" width="110">
                    {editing === true || deleting === true ? "Action" : null}
                  </th>
                </tr>
              </thead>
              <ModalPopup
                showModalPopup={showModalPopup}
                onPopupClose={isShowPopup}
                id={deleteId}
                endPoint={"/document/"}
                updateList={getData}
              ></ModalPopup>
              <tbody>
                {loading === false
                  ? getListData.map((res) => {
                      const isRowArchived = res.status === "DELETED";
                      return (
                        // Commenting the following code for WOII-185. Divya. March 18 2024.. Code Start
                        // <tr>
                        //Commenting end. Divya  March 18 2024..
                        //Added this code for  WOII-185. Divya. March 18 2024.. Code Start
                        <tr
                          key={res.id}
                          className={isRowArchived ? "archived" : ""}
                        >
                          {/* Divya. March 18 2024. Code End */}
                          <td>{res.title}</td>
                          <td>{res.rfx_document_type_name}</td>
                          <td>{res.name}</td>
                          <td>
                            {res.file === undefined
                              ? null
                              : res.file
                                  .substring(res.file.lastIndexOf("/") + 1)
                                  .replace(/\-/, "|")
                                  .split("|")[1]}
                          </td>
                          <td className="text-center">
                            {/* Commenting the following code for WOII-185. Divya. March 18 2024.. Code Start
                                                    {
                                                        editing === true ?
                                                            <button type="button" className="btn btn-action edit mr-1" onClick={() => _onEdit(res)}><i className="icofont-pencil-alt-2"></i></button> : null
                                                    }
                                                    {
                                                        deleting === true ?
                                                            <button type="button" className="btn btn-action delete" onClick={() => _onDelete(res.id)}><i className="icofont-archive"></i></button> : null
                                                    }
                                                    Commenting end. Divya  March 18 2024..*/}
                            {/* Added this code for  WOII-185. Divya. March 18 2024.. Code Start */}
                            {isRowArchived ? (
                              <>
                                <button
                                  type="button"
                                  className="btn btn-action enable"
                                  onClick={() => enableRow(res.id)}
                                >
                                  <i
                                    className="icofont-check"
                                    style={{ fontSize: "18px" }}
                                  ></i>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-action delete ml-1"
                                  onClick={() => deleteRow(res.id)}
                                >
                                  <i className="icofont-archive"></i>
                                </button>
                              </>
                            ) : (
                              <>
                                {editing === true ? (
                                  <button
                                    type="button"
                                    className="btn btn-action edit mr-1"
                                    onClick={() => _onEdit(res)}
                                  >
                                    <i className="icofont-pencil-alt-2"></i>
                                  </button>
                                ) : null}
                                {deleting === true ? (
                                  <button
                                    type="button"
                                    className="btn btn-action delete"
                                    onClick={() => _onDelete(res.id)}
                                  >
                                    <i className="icofont-archive"></i>
                                  </button>
                                ) : null}
                              </>
                            )}
                            {/* Divya. March 18 2024. Code End */}
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
          {getListData.length === 0 && loading === false && global.new_key ? (
            <SearchNoDataFound message={"No Document Entrie Found"} />
          ) : getListData.length === 0 && loading === false ? (
            <NoData />
          ) : null}
          {<Loader isLoading={loading} />}
          <div className="text-md-right">
            <nav aria-label="Page navigation">
              <Pagination
                pages={pageCount}
                nextPage={nextPage}
                currentPage={currentPage}
                pageCount={pageCount}
                //Added this code for  WOII-120. Divya. November 11 2023.. Code Start
                rowsPerPage={handleRowsPerPageChange}
                // Divya. November 11 2023. Code End
              />
            </nav>
          </div>
        </div>
      </div>

      {/* Filter Modal Box */}
      <ReactModal
        initWidth={550}
        initHeight={"auto"}
        top={137.5}
        className={"contained-modal-title-vcenter"}
        onRequestClose={handleClose}
        isOpen={show}
        disableResize={true}
        disableKeystroke={true}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="">
            Filter Q&As
          </h5>
          <button type="button" className="close" onClick={handleClose}>
            <i className="icofont-close"></i>
          </button>
        </div>
        <div className="modal-body">
          <div className="row form-style">
            <div className="col-md-6">
              <div className="form-group">
                <label>Document Name</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  name="document_name"
                  value={formFields["document_name"]}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Document Type</label>
                <select
                  id="disabledSelect"
                  className="form-control custom-select"
                  onChange={handleChange}
                  name="document_type"
                  value={formFields["document_type"]}
                >
                  <option value="" disabled>
                    Select your option
                  </option>
                  {documentData.map((res, index) => {
                    return (
                      <option key={index} value={res.id}>
                        {res.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Client/Source Name</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  name="client_name"
                  value={formFields["client_name"]}
                />
              </div>
            </div>
            {/* Added this code for  WOII-185. Divya. March 18 2024.. Code Start  */}
            <div className="col-md-6">
              <div className="form-group">
                <label>Status</label>
                <select
                  id="disabledSelect"
                  className="form-control custom-select"
                  onChange={handleChange}
                  name="status"
                  value={formFields["status"]}
                >
                  <option value="" disabled>
                    Select your option
                  </option>
                  <option value="ACTIVE">Active</option>
                  <option value="DELETED">Archived</option>
                </select>
              </div>
            </div>
            {/* Divya. March 18 2024. Code End */}
          </div>
        </div>
        <div className="modal-footer ">
          <button
            type="button"
            className="btn btn-lg btn-brand-1 bg-danger"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className="btn btn-lg btn-brand-1"
            onClick={() => _onResetFilter()}
          >
            Reset
          </button>
          <button
            type="button"
            className="btn btn-lg btn-brand-1 bg-success"
            onClick={() => _onFilter()}
          >
            Search
          </button>
        </div>
      </ReactModal>
    </>
  );
};
export default DocumentEntries;
