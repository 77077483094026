import React, { useState, useEffect } from "react";
import Leftnavbar from "../../../../components/Leftnavbar";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { NavLink } from "react-router-dom";
import { APIMethod } from "../../../../API/APIClient";
import Pagination from "../../../../components/Pagination";
import NoData from "../../../../components/NoDataFound";
import SearchNoDataFound from "../../../../components/SearchNoDataFound";
import ReactModal from "react-modal-resizable-draggable";
import { useToasts } from "react-toast-notifications";
import moment from "moment";

const ProposalDispatch = (props) => {
  const [loading, setLoading] = useState(false);
  const [getListData, setGetListData] = useState([]);
  const [businessUnitData, setBusinessUnitData] = useState([]);
  const proposalStatusData = [
    { id: 56, name: "Proposal Is Completed" },
    { id: 58, name: "Proposal Is Dispatched" },
  ];
  const [allOwners, setAllOwners] = useState([]);
  const [formFields, setFormFields] = useState({
    businessUnitId: "",
    rfxClient: "",
    rfxNumber: "",
    rfxTitle: "",
    isDispatched: "",
    owner: "",
    proposalStartDate: undefined,
    proposalClosingDate: undefined,
  });
  // //Goto back
  // // Add modal box
  const [show, setShow] = useState(false);
  const [pageCount, setPageCount] = useState("");
  //Commenting the following code for WOII-120. Divya. November 10 2023.. Code Start
  //const [currentPage, setCurrentPage] = useState('');
  //Commenting end. Divya. November 10 2023..
  {
    /* Added this code for  WOII-120. Divya. November 10 2023.. Code Start */
  }
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  {
    /* Divya. November 10 2023. Code End */
  }
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  //--------date-Piicker----------
  const [startDate, setStartDate] = useState(new Date());
  const [adding, setAdding] = useState();
  const [editing, setEditing] = useState();
  const [deleting, setDeleting] = useState();

  useEffect(() => {
    getList(currentPage);
    getBusinessUnitList();
    var data = JSON.parse(localStorage.getItem("user_Role"));

    checkPermission(data);
  }, [rowsPerPage, currentPage]);

  const checkPermission = (data) => {
    const resObject = data.find(
      (item) => item.name === "Manage Proposals & Awards"
    );
    const valObject = resObject.access.find(
      (item) => item.name === "Proposal Dispatches"
    );
    setAdding(valObject.is_create);
    setEditing(valObject.is_update);
    setDeleting(valObject.is_delete);
  };

  const getList = (page, key) => {
    const new_key = key === undefined ? "" : key;
    var filter_start_date = "";
    if (formFields.proposalStartDate) {
      filter_start_date =
        moment(formFields.proposalStartDate).format("DD-MM-YYYY") ==
        moment(formFields.proposalClosingDate).format("DD-MM-YYYY")
          ? ""
          : moment(formFields.proposalStartDate).format("DD-MM-YYYY");
    }
    const filter_end_date =
      formFields.proposalClosingDate === undefined
        ? ""
        : moment(formFields.proposalClosingDate).format("DD-MM-YYYY");
    setLoading(true);
    var method = "GET";
    //Commenting the following code for WOII-120. Divya. November 10 2023.. Code Start
    //const apifetcherObj = APIMethod(`/pre-rfx/proposal/list/${page}?client=${formFields.rfxClient}&business_unit_id=${formFields.businessUnitId}&rfx_number=${formFields.rfxNumber}&proposal_start_date=${filter_start_date}&proposal_end_date=${filter_end_date}&proposal_status_id=${ formFields.proposalTemplateStatus}&keyword=${new_key || formFields.rfxTitle}`, null, method)
    //Commenting end. Divya. November 10 2023..
    // Added this code for  WOII-120. Divya. November 10 2023.. Code Start
    const apifetcherObj = APIMethod(
      `/pre-rfx/proposal-dispatch/list/${page}?client=${
        formFields.rfxClient
      }&business_unit_id=${formFields.businessUnitId}&rfx_number=${
        formFields.rfxNumber
      }&owner=${
        formFields.owner
      }&proposal_start_date=${filter_start_date}&proposal_end_date=${filter_end_date}&proposal_status_id=${
        formFields.isDispatched
      }&limit=${rowsPerPage}&keyword=${new_key || formFields.rfxTitle}`,
      null,
      method
    );
    {
      /* Divya. November 10 2023. Code End */
    }
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];
        if (statusCode === 200) {
          setGetListData(data.list);
          var md = data.list[0].owner.split("$-$");
          setAllOwners(md[1].split("$$$"));
          if (page === 1) {
            setPageCount(data.count);
          }
          setCurrentPage(page);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getBusinessUnitList = () => {
    setLoading(true);
    var method = "GET";
    const apifetcherObj = APIMethod(`/business-units/all/list`, null, method);
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];

        if (statusCode === 200) {
          setBusinessUnitData(data);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const Search = (key) => {
    getList(1, key);
  };

  const nextPage = (pageNumber) => {
    //Commenting the following code for WOII-120. Divya. November 10 2023.. Code Start
    //getList(pageNumber)
    //Commenting end. Divya. November 10 2023..
    // Added this code for  WOII-120. Divya. November 10 2023.. Code Start
    setCurrentPage(pageNumber);
    {
      /* Divya. November 10 2023. Code End */
    }
  };

  {
    /* Added this code for  WOII-120. Divya. November 10 2023.. Code Start */
  }
  const handleRowsPerPageChange = (selectedValue) => {
    setRowsPerPage(selectedValue);
  };
  {
    /* Divya. November 10 2023. Code End */
  }

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value,
    };
    setFormFields(formFieldsObject);
  };

  const dateValidation = (date) => {
    if (formFields.proposalStartDate) {
      const formFieldsObject = {
        ...formFields,
        proposalClosingDate: date,
      };
      setFormFields(formFieldsObject);
    } else {
      addToast("Please select Proposal Start From Date first", {
        autoDismissTimeout: TOAST_TIMEOUT,
        appearance: "warning",
        autoDismiss: true,
      });
    }
  };

  const setFromDate = (date) => {
    const formFieldsObject = {
      ...formFields,
      proposalStartDate: date,
    };
    setFormFields(formFieldsObject);
  };

  const _onResetFilter = (type) => {
    if (type === "BUSINESS_UNIT_TYPE") {
      formFields.businessUnitId = "";
    } else if (type === "RFX_CLIENT") {
      formFields.rfxClient = "";
    } else if (type === "RFX_NUMBER") {
      formFields.rfxNumber = "";
    } else if (type === "RFX_TITLE") {
      formFields.rfxTitle = "";
    } else if (type === "PROPOSAL_TEMPLATE_STATUS") {
      formFields.isDispatched = "";
    } else if (type === "PROPOSAL_START_DATE") {
      formFields.proposalStartDate = undefined;
    } else if (type === "PROPOSAL_CLOSING_DATE") {
      formFields.proposalClosingDate = undefined;
    } else {
      formFields.businessUnitId = "";
      formFields.rfxClient = "";
      formFields.rfxNumber = "";
      formFields.rfxTitle = "";
      formFields.isDispatched = "";
      formFields.proposalStartDate = undefined;
      formFields.proposalClosingDate = undefined;
      handleClose();
    }
    getList(1);
  };

  const _onFilter = () => {
    getList(1);
    handleClose();
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content">
          <div className="row mb-3 align-items-center justify-content-between">
            <div className="col-lg-5 col-md-12 d-md-flex mb-md-0 mb-2 mb-lg-0 mb-md-3">
              <h2 className="page-title mb-md-0">Proposal Dispatches</h2>
            </div>
            <div className="col-lg-4 col-md-12 text-right d-md-flex align-items-center justify-content-center">
              <div className="input-group page-cont-search mr-md-3 mb-md-0 mb-2">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search by RFx Title"
                  onChange={(event) => Search(event.target.value)}
                />
                <span className="input-group-text text-secondary">
                  <i className="icofont-search-1"></i>
                </span>
              </div>
              <button
                type="button"
                className="btn btn-brand-1 btn-h-40 mr-3"
                onClick={handleShow}
              >
                <i className="fa fa-filter mr-2" aria-hidden="true"></i> Filter
              </button>
            </div>
          </div>

          <div className="mb-2 filter-by">
            {formFields.businessUnitId ? (
              <div className="badge">
                <span className="lable">Business Unit Type</span>
                {businessUnitData.map((res, index) => {
                  return (
                    <span key={index} className="value">
                      {formFields.businessUnitId == res.id ? res.name : null}
                    </span>
                  );
                })}
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("BUSINESS_UNIT_TYPE")}
                ></i>
              </div>
            ) : null}
            {formFields.rfxClient ? (
              <div className="badge">
                <span className="lable">Client / Agency</span>
                <span className="value">{formFields.rfxClient}</span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("RFX_CLIENT")}
                ></i>
              </div>
            ) : null}
            {formFields.rfxNumber ? (
              <div className="badge">
                <span className="lable">RFx Number</span>
                <span className="value">{formFields.rfxNumber}</span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("RFX_NUMBER")}
                ></i>
              </div>
            ) : null}
            {formFields.rfxTitle ? (
              <div className="badge">
                <span className="lable">RFx Title</span>
                <span className="value">{formFields.rfxTitle}</span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("RFX_TITLE")}
                ></i>
              </div>
            ) : null}
            {formFields.isDispatched ? (
              <div className="badge">
                <span className="lable">Dispatch Status</span>
                {proposalStatusData.map((res, index) => {
                  return (
                    <span key={index} className="value">
                      {formFields.isDispatched == res.id ? res.name : null}
                    </span>
                  );
                })}
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("PROPOSAL_TEMPLATE_STATUS")}
                ></i>
              </div>
            ) : null}
            {formFields.proposalStartDate ? (
              <div className="badge">
                <span className="lable">Proposal Start From Date</span>
                <span className="value">
                  {moment(formFields.proposalStartDate).format("MM-DD-YYYY")}
                </span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("PROPOSAL_START_DATE")}
                ></i>
              </div>
            ) : null}
            {formFields.proposalClosingDate ? (
              <div className="badge">
                <span className="lable">Proposal Closing To Date</span>
                <span className="value">
                  {moment(formFields.proposalClosingDate).format("MM-DD-YYYY")}
                </span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("PROPOSAL_CLOSING_DATE")}
                ></i>
              </div>
            ) : null}
          </div>

          <div
            className="table-responsive "
            style={{
              height: "500px",
              overflowY: "auto",
              marginBottom: "20px",
            }}
          >
            <table
              className="table table-style-1 table-responsive-lg mb-4 table reports"
              id="reportTable"
            >
              <thead className="sticky-top" style={{ zIndex: 10 }}>
                <tr>
                  <th scope="col">BU</th>
                  <th scope="col" style={{ textTransform: "none" }}>
                    TITLE
                  </th>
                  <th scope="col">Dispatched</th>
                  <th scope="col">Dispatch Method</th>
                  <th scope="col" style={{ textTransform: "none" }}>
                    RFx DUE
                  </th>
                  <th scope="col" className="text-center">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading === false
                  ? getListData.map((val, index) => {
                      return (
                        <tr key={index}>
                          <td>{val.business_name}</td>
                          <td title={val.title}>{val.title}</td>
                          {val.p_status == "Proposal Is Dispatched" && (
                            <td>Yes</td>
                          )}
                          {val.p_status != "Proposal Is Dispatched" && (
                            <td>No</td>
                          )}
                          <td>
                            {val.dispatch_method
                              ? val.dispatch_method == "Online_Submission"
                                ? "Online Submission"
                                : val.dispatch_method
                              : "N/A"}
                          </td>
                          <td>
                            {val.due
                              ? moment(val.due, "DD/MM/YYYY").format(
                                  "MM-DD-YYYY"
                                )
                              : "N/A"}
                          </td>
                          <td className="text-center">
                            <NavLink
                              to={{
                                pathname: `/edit-proposal-dispatch/${val.id}`,
                                state: {
                                  detail: val.id,
                                  page: "Proposal Dispatches",
                                  rfx_num: val.rfx_number,
                                  rfx_name: val.title,
                                  from: "Proposal Dispatches",
                                  status: val.p_status,
                                  dispatchMethod: val.dispatch_method
                                    ? val.dispatch_method
                                    : "N/A",
                                  carrier: val.carrier,
                                  trackingId: val.tracking_id,
                                  email: val.reciever_email,
                                },
                              }}
                              className="btn btn-action edit mr-1"
                            >
                              <i className="icofont-pencil-alt-2"></i>
                            </NavLink>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
          {getListData.length === 0 && loading === false ? (
            <SearchNoDataFound message={"No Completed Proposals Found"} />
          ) : (
            ""
          )}
          <div className="text-md-right">
            <nav aria-label="Page navigation">
              <Pagination
                pages={pageCount}
                endPoint={"/business-unit-type/list/"}
                nextPage={nextPage}
                currentPage={currentPage}
                pageCount={pageCount}
                //Added this code for  WOII-120. Divya. November 10 2023.. Code Start
                rowsPerPage={handleRowsPerPageChange}
                // Divya. November 10 2023. Code End
              />
            </nav>
          </div>
        </div>
      </div>

      {/* Filter Modal Box */}
      <ReactModal
        initWidth={550}
        initHeight={500}
        top={137.5}
        className={"contained-modal-title-vcenter"}
        onRequestClose={handleClose}
        isOpen={show}
        disableResize={true}
        disableKeystroke={true}
      >
        <div className="modal-header ">
          <h5 className="modal-title" id="">
            Filter Proposals
          </h5>
          <button type="button" className="close" onClick={handleClose}>
            <i className="icofont-close"></i>
          </button>
        </div>
        <div className="modal-body">
          <div className="row form-style">
            <div className="col-md-6">
              <div className="form-group">
                <label>Business Unit</label>
                <select
                  id="disabledSelect"
                  className="form-control custom-select"
                  onChange={handleChange}
                  name="businessUnitId"
                  value={formFields["businessUnitId"]}
                >
                  <option value="" disabled>
                    Select your option
                  </option>
                  {businessUnitData.map((res, index) => {
                    return (
                      <option key={index} value={res.id}>
                        {res.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Client / Agency</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  name="rfxClient"
                  value={formFields["rfxClient"]}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>RFx Number</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  name="rfxNumber"
                  value={formFields["rfxNumber"]}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>RFx Title</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  name="rfxTitle"
                  value={formFields["rfxTitle"]}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Dispatch Status</label>
                <select
                  id="disabledSelect"
                  className="form-control custom-select"
                  onChange={handleChange}
                  name="isDispatched"
                  value={formFields["isDispatched"]}
                >
                  <option value="" disabled>
                    Select your option
                  </option>
                  <option>Dispatched</option>
                  <option>Not Dispatched</option>
                  {/* {
                                        proposalStatusData.map((res, index) => {
                                            return (
                                                <option key={index} value={res.id}>{res.name}</option>
                                            )
                                        })
                                    } */}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Author</label>
                <select
                  id="disabledSelect"
                  className="form-control custom-select "
                  onChange={handleChange}
                  name="owner"
                  value={formFields["owner"]}
                >
                  <option value="" disabled selected>
                    Select your option
                  </option>
                  {allOwners.map((owner, index) => {
                    return (
                      <option key={index} value={owner}>
                        {owner}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Proposal Start From Date</label>
                <DatePicker
                  selected={formFields.proposalStartDate}
                  onChange={(date) => setFromDate(date)}
                  className="form-control"
                  placeholder="select Date"
                  dateFormat="MM-dd-yyyy"
                  scrollableYearDropdown
                  showYearDropdown
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Proposal Closing To Date </label>
                <DatePicker
                  selected={formFields.proposalClosingDate}
                  onChange={(date) => dateValidation(date)}
                  className="form-control"
                  placeholder="select Date"
                  dateFormat="MM-dd-yyyy"
                  minDate={formFields.proposalStartDate}
                  scrollableYearDropdown
                  showYearDropdown
                />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-lg btn-brand-1 bg-danger"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className="btn btn-lg btn-brand-1"
            onClick={() => _onResetFilter()}
          >
            Reset
          </button>
          <button
            type="button"
            className="btn btn-lg btn-brand-1 bg-success"
            onClick={() => _onFilter()}
          >
            Search
          </button>
        </div>
      </ReactModal>
    </>
  );
};
export default ProposalDispatch;
