import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {
  APIMethod,
  FileUploadMethod,
  TOAST_TIMEOUT,
} from "../../../../API/APIClient";
import ModalPopup from "../../../../components/ModalPopUp";
import Pagination from "../../../../components/Pagination";
import Loader from "../../../../components/Loader";
import { useToasts } from "react-toast-notifications";
import NoData from "../../../../components/NoDataFound";
import SearchNoDataFound from "../../../../components/SearchNoDataFound";
import ReactModal from "react-modal-resizable-draggable";

const WordTemplates = (props) => {
  const [getListData, setGetListData] = useState([]);
  //ADDED STATUS FORM FIELD TO BELOW LINE FOR WOII-185
  const [formFields, setFormFields] = useState({
    templateName: "",
    description: "",
    status: "ACTIVE",
  });
  const [loading, setLoading] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  // Edit
  const [edit, setEdit] = useState("");
  const [id, setId] = useState("");
  // Delete
  const [deleteId, setDeleteId] = useState("");
  // Delete PopUp
  const [showModalPopup, setShowModalPopup] = useState(false);
  // Pagination
  const [pageCount, setPageCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  {
    /* Added this code for  WOII-120. Divya. November 06 2023.. Code Start */
  }
  const [rowsPerPage, setRowsPerPage] = useState(10);
  {
    /* Divya. November 06 2023. Code End */
  }
  // file
  const [file, setFile] = useState();
  // Add modal box
  const [show, setShow] = useState(false);
  //ADDED CODE FOR WOII-185
  // Filter box
  const [showFilter, setShowFilter] = useState(false);
  const handleCloseFilter = () => setShowFilter(false);
  //CODE ENDED 21-03-2024 FOR WOII-185
  const handleClose = () => {
    setFormFields({ ...formFields, templateName: "", description: "" });
    setId("");
    setShow(false);
    setFlag(false);
    setEdit();
    setFile();
  };
  const handleShow = () => setShow(true);
  // Toast
  const { addToast } = useToasts();
  const [flag, setFlag] = useState(false);
  //--------date-Piicker----------
  const [adding, setAdding] = useState();
  const [editing, setEditing] = useState();
  const [deleting, setDeleting] = useState();

  useEffect(() => {
    {
      /* Commenting the following code for WOII-120. Divya. November 06 2023.. Code Start */
    }
    //getWordTemplatesList(1)
    {
      /* Commenting end. Divya. November 06 2023.. */
    }
    {
      /* Added this code for  WOII-120. Divya. November 06 2023.. Code Start */
    }
    getWordTemplatesList(currentPage);
    {
      /* Divya. November 06 2023. Code End */
    }
    var data = JSON.parse(localStorage.getItem("user_Role"));
    checkPermission(data);
    {
      /* Commenting the following code for WOII-120. Divya. November 06 2023.. Code Start */
    }
    //}, [])
    {
      /* Commenting end. Divya. November 06 2023.. */
    }
    {
      /* Added this code for  WOII-120. Divya. November 06 2023.. Code Start */
    }
  }, [rowsPerPage, currentPage]);
  {
    /* Divya. November 06 2023. Code End */
  }

  const checkPermission = (data) => {
    const resObject = data.find((item) => item.name === "Organizational Setup");
    const valObject = resObject.access.find(
      (item) => item.name === "Word Templates"
    );
    setAdding(valObject.is_create);
    setEditing(valObject.is_update);
    setDeleting(valObject.is_delete);
  };

  const getWordTemplatesList = (page, key) => {
    setLoading(true);
    const new_key = key === undefined ? "" : key;
    global.new_key = key === undefined ? "" : key;
    var method = "GET";
    {
      /* Commenting the following code for WOII-120. Divya. November 06 2023.. Code Start */
    }
    //const apifetcherObj = APIMethod(`/word-template/list/${page}?keyword=` + new_key, null, method)
    {
      /* Commenting end. Divya. November 06 2023.. */
    }
    {
      /* Added this code for  WOII-120. Divya. November 06 2023.. Code Start */
    }
    //ADDED STATUS PARAMETER TO THE BELOW API METHOD FOR WOII-185
    const apifetcherObj = APIMethod(
      `/word-template/list/${page}?limit=${rowsPerPage}&status=${
        formFields.status
      }&keyword=${formFields.templateName || formFields.description}` + new_key,
      null,
      method
    );
    {
      /* Divya. November 06 2023. Code End */
    }
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];

        if (statusCode === 200) {
          setGetListData(data.list);
          if (page === 1) {
            setPageCount(data.count);
          }
          setCurrentPage(page);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const nextPage = (pageNumber) => {
    {
      /* Commenting the following code for WOII-120. Divya. November 06 2023.. Code Start */
    }
    //getWordTemplatesList(pageNumber)
    {
      /* Commenting end. Divya. November 06 2023.. */
    }
    {
      /* Added this code for  WOII-120. Divya. November 06 2023.. Code Start */
    }
    setCurrentPage(pageNumber);
    {
      /* Divya. November 06 2023. Code End */
    }
  };

  {
    /* Added this code for  WOII-120. Divya. November 06 2023.. Code Start */
  }
  const handleRowsPerPageChange = (selectedValue) => {
    setRowsPerPage(selectedValue);
  };
  {
    /* Divya. November 06 2023. Code End */
  }

  const save = () => {
    if (formFields.templateName === "" || formFields.description === "") {
      setFlag(true);
      addToast("Please fill all the fields", {
        autoDismissTimeout: TOAST_TIMEOUT,
        appearance: "error",
        autoDismiss: true,
      });
    } else {
      setButtonLoader(true);
      if (edit === "/") {
        var params = JSON.stringify({
          id: id,
          name: formFields.templateName,
          description: formFields.description,
        });
        var method = "PUT";
      } else {
        params = JSON.stringify({
          name: formFields.templateName,
          description: formFields.description,
        });
        method = "POST";
      }
      const apifetcherObj = APIMethod("/word-template", params, method);
      apifetcherObj
        .then((response) => {
          return Promise.all([response.status, response.json()]);
        })
        .then((res) => {
          let statusCode = res[0];
          let data = res[1];

          if (statusCode === 200) {
            if (file.type) {
              sendFile(data.id);
              addToast(data.message, {
                autoDismissTimeout: TOAST_TIMEOUT,
                appearance: "success",
                autoDismiss: true,
              });
            } else {
              handleClose();
              setButtonLoader(false);
              setFlag(false);
              setFormFields({
                ...formFields,
                templateName: "",
                description: "",
              });
              setFile();
              setId("");
              setEdit("");
              addToast(data.message, {
                autoDismissTimeout: TOAST_TIMEOUT,
                appearance: "success",
                autoDismiss: true,
              });
              getWordTemplatesList("1", "");
            }
          } else {
            addToast(data.message, {
              autoDismissTimeout: TOAST_TIMEOUT,
              appearance: "error",
              autoDismiss: true,
            });
            setButtonLoader(false);
          }
        })
        .catch((error) => {
          setButtonLoader(false);
        });
    }
  };

  const sendFile = (id) => {
    var form = new FormData();
    form.append("file", file);
    var method = "POST";
    const apifetcherObj = FileUploadMethod(
      `/word-template/file/` + id,
      form,
      method
    );
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];

        if (statusCode === 200) {
          handleClose();
          setButtonLoader(false);
          setFlag(false);
          setFormFields({ ...formFields, templateName: "", description: "" });
          setFile();
          setId("");
          setEdit("");
          getWordTemplatesList(currentPage);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const _onDelete = (id) => {
    setDeleteId(id);
    isShowPopup(true);
  };

  const isShowPopup = (status) => {
    setShowModalPopup(status);
  };

  const _onEdit = (res) => {
    handleShow();
    setFormFields({
      ...formFields,
      templateName: res.name,
      description: res.description,
    });
    setEdit("/");
    setId(res.id);
    setFile({ name: res.file });
  };

  const Search = (key) => {
    getWordTemplatesList(1, key);
  };

  const onChangeHandler = (event) => {
    setFile(event.target.files[0]);
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value,
    };
    setFormFields(formFieldsObject);
  };

  const getData = () => {
    getWordTemplatesList(1);
  };

  //ADDED BELOW CODE FOR WOII-185
  const _onResetFilter = (type) => {
    if (type === "NAME") {
      formFields.templateName = "";
    } else if (type === "DESCRIPTION") {
      formFields.description = "";
    } else if (type === "STATUS") {
      formFields.status = "";
    } else {
      formFields.templateName = "";
      formFields.description = "";
      formFields.status = "";
      handleCloseFilter();
    }
    getWordTemplatesList(1, "");
    handleCloseFilter();
  };

  const enableRow = (id) => {
    var method = "PUT";
    const apifetcherObj = APIMethod(
      `/word-template/${id}/enable`,
      null,
      method
    );
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];

        if (statusCode === 200) {
          getWordTemplatesList(currentPage);
          isShowPopup(false);
        }
      })
      .catch((error) => {
        console.error("Error enabling Document entries:", error);
      });
  };

  const deleteRow = (id) => {
    var method = "DELETE";
    const apifetcherObj = APIMethod(
      `/word-template/${id}/delete`,
      null,
      method
    );
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];

        if (statusCode === 200) {
          getWordTemplatesList(currentPage);
          isShowPopup(false);
        }
      })
      .catch((error) => {
        console.error("Error in deleting Document entries:", error);
      });
  };

  const _onFilter = () => {
    getWordTemplatesList(1, "");
    handleCloseFilter();
  };
  //ABOVE CODE ENDED 21-03-2024 FOR WOII-185
  return (
    <>
      <div className="page-wrapper">
        <div className="page-content">
          <div className="row mb-3 align-items-center justify-content-between">
            <div className="col-lg-5 col-md-12 d-md-flex mb-md-0 mb-2 mb-lg-0 mb-md-3">
              <h2 className="page-title mb-md-0">Word Templates</h2>
            </div>
            <div className="col-lg-5 col-md-12 text-right d-md-flex align-items-center justify-content-center">
              <div className="input-group page-cont-search mr-md-3 mb-md-0 mb-2">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search name and description"
                  onChange={(event) => Search(event.target.value)}
                />
                <span className="input-group-text text-secondary">
                  <i className="icofont-search-1"></i>
                </span>
              </div>
              <button
                type="button"
                className="btn btn-brand-1 btn-h-40 mr-3"
                onClick={() => setShowFilter(true)}
              >
                <i className="icofont-filter mr-2"></i>filter
              </button>
              {adding === true ? (
                <button
                  type="button"
                  className="btn btn-brand-1 btn-h-40 bg-success px-4"
                  onClick={handleShow}
                >
                  + Add
                </button>
              ) : null}
            </div>
          </div>
          {/* ADDED CELOW CODE FOR WOII-185 */}
          <div className="mb-2 filter-by">
            {formFields.templateName ? (
              <div className="badge">
                <span className="lable">Template Name</span>
                <span className="value">{formFields.templateName}</span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("NAME")}
                ></i>
              </div>
            ) : null}
            {formFields.description ? (
              <div className="bagde">
                <span className="lable">Description</span>
                <span className="value">{formFields.description}</span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("DESCRIPTION")}
                ></i>
              </div>
            ) : null}
            {formFields.status ? (
              <div className="bagde">
                <span className="lable">Status</span>
                <span className="vlaue">
                  {formFields.status == "ACTIVE"
                    ? "Active"
                    : formFields.status == "DELETED"
                    ? "Archived"
                    : formFields.status}
                </span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("STATUS")}
                ></i>
              </div>
            ) : null}
          </div>
          {/* CODE ENDED 21-03-2024 FOR WOII-185 */}
          <div
            className="table-responsive"
            style={{ height: "400px", overflowY: "auto", marginBottom: "20px" }}
          >
            <table
              className="table table-style-1 table-responsive-lg mb-4 table reports"
              id="reportTable"
            >
              <thead className="sticky-top" style={{ zIndex: 10 }}>
                <tr>
                  <th scope="col">Template Name</th>
                  <th scope="col">Description</th>
                  <th scope="col">File</th>
                  <th scope="col" className="text-center" width="110">
                    {editing === true || deleting === true ? "Action" : null}
                  </th>
                </tr>
              </thead>
              <ModalPopup
                showModalPopup={showModalPopup}
                onPopupClose={isShowPopup}
                id={deleteId}
                endPoint={"/word-template/"}
                updateList={getData}
              />
              <tbody>
                {loading === false
                  ? getListData.map((res, index) => {
                      //BELOW LINE IS ADDED FOR WOII-185
                      const isRowArchived = res.status === "DELETED";
                      return (
                        //CLASS NAME ATTRIBUTE IS ADDED TO THE BELOW LINE
                        <tr
                          key={index}
                          className={isRowArchived ? "archived" : ""}
                        >
                          <td>{res.name}</td>
                          <td title={res.description}>
                            {res.description.length > 100
                              ? `${res.description.substring(0, 100)}...`
                              : res.description}
                          </td>
                          {res.file ? (
                            <td>
                              <button
                                type="button"
                                className="btn btn-action"
                                onClick={() =>
                                  window.open(res.fill_full_name, "_blank")
                                }
                              >
                                <i className="icofont-eye-alt"></i>
                              </button>
                            </td>
                          ) : (
                            <td></td>
                          )}
                          <td className="text-center">
                            {/* Commenting the following code for WOII-185. Divya. March 18 2024.. Code Start
                                                    {
                                                        editing === true ?
                                                            <button type="button" className="btn btn-action edit mr-1" onClick={() => _onEdit(res)}><i className="icofont-pencil-alt-2"></i></button> : null
                                                    }
                                                    {/* UnCommenting the following code for WOII-29. Divya. August 28 2023.. Code Start
                                                    {
                                                        deleting === true ?
                                                            <button type="button" className="btn btn-action delete" onClick={() => _onDelete(res.id)}><i className="icofont-archive"></i></button> : null
                                                    }
                                                    {/* UnCommenting end. Divya. August 28 2023..
                                                    Commenting end. Divya  March 18 2024..*/}
                            {/* ADDED CODE FOR WOII-185 */}
                            {isRowArchived ? (
                              <>
                                <button
                                  type="button"
                                  className="btn btn-action enable"
                                  onClick={() => enableRow(res.id)}
                                >
                                  <i
                                    className="icofont-check"
                                    style={{ fontSize: "18px" }}
                                  ></i>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-action delete ml-1"
                                  onClick={() => deleteRow(res.id)}
                                >
                                  <i className="icofont-archive"></i>
                                </button>
                              </>
                            ) : (
                              <>
                                {editing === true ? (
                                  <button
                                    type="button"
                                    className="btn btn-action edit mr-1"
                                    onClick={() => _onEdit(res)}
                                  >
                                    <i className="icofont-pencil-alt-2"></i>
                                  </button>
                                ) : null}
                                {deleting === true ? (
                                  <button
                                    type="button"
                                    className="btn btn-action delete"
                                    onClick={() => _onDelete(res.id)}
                                  >
                                    <i className="icofont-archive"></i>
                                  </button>
                                ) : null}
                              </>
                            )}
                            {/* CODE ENDED 21-03-2024 FOR WOII */}
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
          {getListData.length === 0 && loading === false && global.new_key ? (
            <SearchNoDataFound message={"No Word Template Found"} />
          ) : getListData.length === 0 && loading === false ? (
            <NoData />
          ) : null}
          {<Loader isLoading={loading} />}
          <div className="text-md-right">
            <nav aria-label="Page navigation">
              <Pagination
                pages={pageCount}
                endPoint={"/word-template/list/"}
                nextPage={nextPage}
                currentPage={currentPage}
                pageCount={pageCount}
                //Added this code for  WOII-120. Divya. November 06 2023.. Code Start
                rowsPerPage={handleRowsPerPageChange}
                // Divya. November 06 2023. Code End
              />
            </nav>
          </div>
        </div>
      </div>

      {/* Add Modal Box */}
      <ReactModal
        initWidth={550}
        initHeight={"auto"}
        top={132.5}
        className={"contained-modal-title-vcenter"}
        onRequestClose={handleClose}
        isOpen={show}
        disableResize={true}
        disableKeystroke={true}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="">
            {id ? "Edit Word Template" : "Add Word Template"}
          </h5>
          <button type="button" className="close" onClick={handleClose}>
            <i className="icofont-close"></i>
          </button>
        </div>
        <div className="modal-body">
          <div className="row form-style">
            <div className="col-md-12">
              <div className="form-group">
                <label>Template Name </label>
                <input
                  type="text"
                  className="form-control"
                  value={formFields["templateName"]}
                  name="templateName"
                  onChange={handleChange}
                />
                <span className="text-danger">
                  {flag === true && formFields.templateName === ""
                    ? "Please Enter Template Name"
                    : null}
                </span>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Description</label>
                <textarea
                  className="form-control h-auto"
                  id=""
                  cols="30"
                  rows="3"
                  value={formFields["description"]}
                  name="description"
                  onChange={handleChange}
                />
                <span className="text-danger">
                  {flag === true && formFields.description === ""
                    ? "Please Enter Description"
                    : null}
                </span>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Select file</label>
                <div className="input-group mb-3">
                  <div className="custom-file">
                    <input
                      type="file"
                      accept=".doc, .docx,.txt,.pdf"
                      className="custom-file-input"
                      id="inputGroupFile02"
                      onChange={onChangeHandler}
                    />
                    <label
                      className="custom-file-label"
                      for="inputGroupFile02"
                      aria-describedby="inputGroupFileAddon02"
                    >
                      {file === undefined ? "Choose File" : file.name}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-lg btn-brand-1 bg-danger"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-brand-1 btn-h-50 bg-success px-4"
            onClick={() => save()}
            disabled={buttonLoader}
          >
            {buttonLoader && (
              <img
                src={
                  require("../../../../assets/css/images/button_loader.gif")
                    .default
                }
                alt="loading..."
                style={{ marginLeft: 5 }}
              />
            )}
            <span>Save</span>
          </button>
        </div>
      </ReactModal>

      {/* ADDED CODE FOR THE WOII-185 */}
      {/*Filter Modal Box */}
      <ReactModal
        initWidth={550}
        initHeight={"auto"}
        top={137.5}
        className={"contained-modal-title-vcenter"}
        onRequestClose={handleClose}
        isOpen={showFilter}
        disableKeystroke={true}
      >
        <div className="modal-header">
          <h5 className="modal-tile" id="">
            Filer Template Name
          </h5>
          <button type="button" className="close" onClick={handleCloseFilter}>
            <i className="icofont-close"></i>
          </button>
        </div>
        <div className="modal-body">
          <div className="row form-style">
            <div className="col-md-6">
              <div className="form-group">
                <label>Template Name</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  name="templateName"
                  value={formFields["templateName"]}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Description</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  name="description"
                  value={formFields["description"]}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Status</label>
                <select
                  id="disabledSelect"
                  className="from-control custom-select"
                  onChange={handleChange}
                  name="status"
                  value={formFields["status"]}
                >
                  <option value="" disabled>
                    select your option
                  </option>
                  <option value="ACTIVE">Active</option>
                  <option value="DELETED">Archived</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-lg btn-brand-1 bg-danger"
            onClick={handleCloseFilter}
          >
            Cancel
          </button>
          <button
            className="btn btn-lg btn-brand-1"
            onClick={() => _onResetFilter()}
          >
            Reset
          </button>
          <button
            type="button"
            className="btn btn-lg btn-brand-1 bg-success"
            onClick={() => _onFilter()}
          >
            Search
          </button>
        </div>
      </ReactModal>
      {/* CODE ENDED 21-03-2024 FOR WOII-185 */}
    </>
  );
};
export default WordTemplates;
