import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { APIMethod, TOAST_TIMEOUT } from "../../../../API/APIClient";
import Pagination from "../../../../components/Pagination";
import ModalPopup from "../../../../components/ModalPopUp";
import Loader from "../../../../components/Loader";
import { useToasts } from "react-toast-notifications";
import NoData from "../../../../components/NoDataFound";
import SearchNoDataFound from "../../../../components/SearchNoDataFound";
import ReactModal from "react-modal-resizable-draggable";
import PhoneInput from "react-phone-number-input";

const Clients = (props) => {
  const [formFields, setFormFields] = useState({
    businessUnit: "",
    clientName: "",
    clientEmail: "",
    clientAddress: "",
    clientId: null,
    rfxId: null,
    clientUrl: "",
    clientLogo: "",
    clientRfxStatus: "",
    // Added this code for  WOII-185. Divya. March 21 2024.. Code Start
    client_status: "ACTIVE",
  });
  //Divya. March 21 2024. Code End
  const [getListData, setGetListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [edit, setEdit] = useState("");
  const [Id, setId] = useState("");
  const [showModalPopup, setShowModalPopup] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  // page
  const [pageCount, setPageCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [file, setFile] = useState();
  const [base64URL, setBase64URL] = useState();
  // Add modal box
  const [show, setShow] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const handleShow = () => {
    setShow(true);
  };
  // Filter model box
  const [showFilter, setShowFilter] = useState(false);
  const handleCloseFilter = () => setShowFilter(false);
  // Toast
  const { addToast } = useToasts();
  const [flag, setFlag] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [adding, setAdding] = useState();
  const [editing, setEditing] = useState();
  const [deleting, setDeleting] = useState();
  const [businessUnitOptions] = useState([
    "Canada",
    "France",
    "USA",
    "India",
    "Ukraine",
  ]);

  useEffect(() => {
    getList(currentPage);
    var data = JSON.parse(localStorage.getItem("user_Role"));
    checkPermission(data);
  }, [rowsPerPage, currentPage]);
  const checkPermission = (data) => {
    if (data != null) {
      const resObject = data.find(
        (item) => item.name === "Organizational Setup"
      );
      const valObject = resObject.access.find(
        (item) => item.name === "Clients"
      );
      setAdding(valObject.is_create);
      setEditing(valObject.is_update);
      setDeleting(valObject.is_delete);
    }
  };
  const handleModal = () => {
    setShow(true);
    setCancelModal(!cancelModal);
  };
  const _onDelete = (deleteId) => {
    setDeleteId(deleteId);
    isShowPopup(true);
  };
  const handleClose = () => {
    if (
      formFields.businessUnit === "" ||
      formFields.clienturl === "" ||
      formFields.clientEmail === "" ||
      formFields.clientName === "" ||
      phoneNumber === ""
    ) {
      setShow(false);
      setFlag(false);
      setId("");
      setEdit("");
    } else {
      handleModal(!cancelModal);
      setShow(false);
      setFlag(false);
      setId("");
      setEdit("");
    }
  };
  const getList = (page, key) => {
    const new_key = key === undefined ? "" : key;
    global.new_key = key === undefined ? "" : key;
    setLoading(true);
    var method = "GET";
    // Commenting the following code for WOII-185. Divya. March 21 2024.. Code Start
    //const apifetcherObj = APIMethod(`/clients/list/${page}?limit=${rowsPerPage}&keyword=` + new_key, null, method)
    //Commenting end. Divya  March 21 2024..
    // Added this code for  WOII-185. Divya. March 21 2024.. Code Start
    const apifetcherObj = APIMethod(
      `/clients/list/${page}?limit=${rowsPerPage}&client_status=${formFields.client_status}&client_name=${formFields.clientName}&keyword=` +
        new_key,
      null,
      method
    );
    {
      /* Divya. March 21 2024. Code End  */
    }
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];
        if (statusCode === 200) {
          setGetListData(data.list);
          // console.log(data.list);
          if (page === 1) {
            setPageCount(data.count);
          }
          setCurrentPage(page);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const Search = (key) => {
    getList(1, key);
  };
  const nextPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleRowsPerPageChange = (selectedValue) => {
    setRowsPerPage(selectedValue);
  };
  const handleFieldChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    if (name === "businessUnit") {
      setFormFields((prevFormFields) => ({
        ...prevFormFields,
        [name]: value,
      }));
    } else {
      setFormFields((prevFormFields) => ({
        ...prevFormFields,
        [name]: value,
      }));
    }
  };
  const save = () => {
    // console.log("LOGO",formFields.clientLogo)
    if (
      formFields.clientUrl === "" ||
      formFields.businessUnit === "" ||
      formFields.clientEmail == "" ||
      phoneNumber === "" ||
      formFields.clientName == ""
    ) {
      setFlag(true);
      addToast("Please fill all the fields", {
        autoDismissTimeout: TOAST_TIMEOUT,
        appearance: "error",
        autoDismiss: true,
      });
    } else {
      setButtonLoader(true);
      if (edit === "/") {
        var method = "PUT";
        var params = {
          client_id: formFields.clientId || null,
          client_name: formFields.clientName || null,
          client_email: formFields.clientEmail || null,
          client_contact_number: phoneNumber || null,
          business_unit: formFields.businessUnit || null,
          client_address: formFields.clientAddress || null,
          client_url: formFields.clientUrl || null,
          rfx_id: formFields.rfxId || null,
          client_logo: formFields.clientLogo || null,
          client_rfx_status: formFields.clientRfxStatus || null,
        };
      }

      const apifetcherObj = APIMethod(
        "/clients/update",
        JSON.stringify(params),
        method,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      apifetcherObj
        .then((response) => {
          return Promise.all([response.status, response.json()]);
        })
        .then((res) => {
          let statusCode = res[0];
          let data = res[1];
          if (statusCode === 200) {
            setShow(false);
            // setFormFields({ ...formFields, businessUnit: '', clientName: '', clientAddress: '', clientEmail:'', clientMobileNumber: '',clientId:''})
            getList(currentPage);
            setButtonLoader(false);
            addToast(data.message, {
              autoDismissTimeout: TOAST_TIMEOUT,
              appearance: "success",
              autoDismiss: true,
            });
          } else {
            addToast(data.message, {
              autoDismissTimeout: TOAST_TIMEOUT,
              appearance: "error",
              autoDismiss: true,
            });
            setButtonLoader(false);
          }
        })
        .catch((error) => {
          setButtonLoader(false);
        });
    }
  };
  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };
  const filehandler = (event) => {
    event.preventDefault();
    setFile(event.target.files[0]);
    getBase64(event.target.files[0])
      .then((result) => {
        // file["base64"] = result;
        console.log("File Is", file);
        console.log("result", result);

        setBase64URL(result);
        formFields.clientLogo = result;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const _onEdit = (val) => {
    handleShow();
    const formFieldsObject1 = {
      ...formFields,
      clientId: val.client_id,
      businessUnit: val.business_unit,
      clientName: val.client_name,
      clientEmail: val.client_email,
      clientAddress: val.client_address,
      clientUrl: val.client_url,
      rfxId: val.rfx_id,
      clientLogo: val.client_logo,
      clientRfxStatus: val.client_rfx_status,
    };
    setPhoneNumber(val.client_contact_number);
    setFormFields((prevFormFields) => ({
      ...prevFormFields,
      ...formFieldsObject1,
    }));
    setEdit("/");
    setId(val.client_id);
    // console.log("su",formFields.clientUrl,"sa",formFields.clientAddress)
  };
  const isShowPopup = (status) => {
    setShowModalPopup(status);
  };
  const getData = () => {
    getList(1);
  };

  // Added this code for  WOII-185. Divya. March 21 2024.. Code Start
  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value,
    };
    setFormFields(formFieldsObject);
  };

  const _onResetFilter = (type) => {
    if (type === "CLIENT_STATUS") {
      formFields.client_status = "";
    } else if (type === "CLIENT_NAME") {
      formFields.clientName = "";
    } else {
      formFields.client_status = "";
      formFields.clientName = "";
      handleCloseFilter();
    }
    getList(1, "");
  };

  const _onFilter = () => {
    getList(1, "");
    handleCloseFilter();
  };

  const enableRow = (id) => {
    var method = "PUT";
    const apifetcherObj = APIMethod(`/clients/${id}/enable`, null, method);
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];

        if (statusCode === 200) {
          getList(currentPage);
          isShowPopup(false);
        }
      })
      .catch((error) => {
        console.error("Error enabling clients:", error);
      });
  };

  const deleteRow = (id) => {
    var method = "DELETE";
    const apifetcherObj = APIMethod(`/clients/${id}/delete`, null, method);
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];

        if (statusCode === 200) {
          getList(currentPage);
          isShowPopup(false);
        }
      })
      .catch((error) => {
        console.error("Error in deleting clients:", error);
      });
  };
  // Divya. March 21 2024. Code End

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content">
          <div className="row mb-3">
            <div className="col-7">
              <h2 className="page-title mb-md-0">Clients</h2>
            </div>
            <div className="col-lg-5 col-md-12 text-right d-md-flex align-items-center justify-content-center">
              <div className="input-group page-cont-search mr-md-3 mb-md-0 mb-2">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search by client email or client name"
                  onChange={(event) => Search(event.target.value)}
                />
                <span className="input-group-text text-secondary">
                  <i className="icofont-search-1"></i>
                </span>
              </div>
              {/*  Added this code for  WOII-185. Divya. March 21 2024.. Code Start */}
              <button
                type="button"
                className="btn btn-brand-1 btn-h-40 mr-3"
                onClick={() => setShowFilter(true)}
              >
                <i className="icofont-filter mr-2"></i> Filter
              </button>
              {/*  Divya. March 21 2024. Code End  */}
            </div>
          </div>
          {/*  Added this code for  WOII-185. Divya. March 21 2024.. Code Start */}
          <div className="mb-2 filter-by">
            {formFields.client_status ? (
              <div className="badge">
                <span className="lable">Status</span>
                <span className="value">
                  {formFields.client_status == "ACTIVE"
                    ? "Active"
                    : formFields.client_status == "DELETED"
                    ? "Archived"
                    : formFields.client_status}
                </span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("CLIENT_STATUS")}
                ></i>
              </div>
            ) : null}
            {formFields.clientName ? (
              <div className="badge">
                <span className="lable">Client Name</span>
                <span className="value">{formFields.clientName}</span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("CLIENT_NAME")}
                ></i>
              </div>
            ) : null}
          </div>
          {/*  Divya. March 21 2024. Code End  */}
          <div
            className="table-responsive"
            style={{
              height: "400px",
              overflowY: "auto",
              marginBottom: "20px",
            }}
          >
            <table
              className="table table-style-1 table-responsive-md mb-4 table reports"
              id="reportTable"
            >
              <thead className="sticky-top" style={{ zIndex: 10 }}>
                <tr>
                  <th scope="col">Business Unit</th>
                  <th scope="col">Client Name</th>
                  <th scope="col">Client Email</th>
                  <th scope="col" className="text-center" width="110">
                    {editing === true || deleting === true ? "Action" : null}
                  </th>
                </tr>
              </thead>
              <ModalPopup
                showModalPopup={showModalPopup}
                onPopupClose={isShowPopup}
                id={deleteId}
                endPoint={"/clients/"}
                updateList={getData}
              ></ModalPopup>
              {
                <tbody>
                  {loading === false
                    ? getListData
                        .slice(
                          (currentPage - 1) * rowsPerPage,
                          currentPage * rowsPerPage
                        )
                        .map((val, index) => {
                          if (Object.keys(val).length == 0) {
                            return;
                          }
                          {
                            /*  Added this code for  WOII-185. Divya. March 21 2024.. Code Start */
                          }
                          const isRowArchived = val.client_status === "DELETED";
                          {
                            /*  Divya. March 21 2024. Code End  */
                          }
                          return (
                            // Commenting the following code for WOII-185. Divya. March 21 2024.. Code Start
                            // <tr key={index}>
                            //Commenting end. Divya  March 21 2024..
                            // Added this code for  WOII-185. Divya. March 21 2024.. Code Start
                            <tr
                              key={val.client_id}
                              className={isRowArchived ? "archived" : ""}
                            >
                              {/* Divya. March 21 2024. Code End */}
                              <td>{val.business_unit}</td>
                              <td>{val.client_name}</td>
                              <td>{val.client_email}</td>
                              <td className="text-center">
                                {/* Commenting the following code for WOII-185. Divya. March 21 2024.. Code Start */}
                                {/* {
                                                            editing === true ?
                                                                <button type="button" className="btn btn-action edit mr-1" onClick={() => _onEdit(val)}><i className="icofont-pencil-alt-2"></i></button> : null
                                                        }
                                                        {
                                                            deleting === true ?
                                                                <button type="button" className="btn btn-action delete" onClick={() => _onDelete(val.client_id)}><i className="icofont-archive"></i></button> : null
                                                        } */}
                                {/* Commenting end. Divya  March 21 2024..
                                                        Added this code for  WOII-185. Divya. March 21 2024.. Code Start */}
                                {isRowArchived ? (
                                  <>
                                    <button
                                      type="button"
                                      className="btn btn-action enable"
                                      onClick={() => enableRow(val.client_id)}
                                    >
                                      <i
                                        className="icofont-check"
                                        style={{ fontSize: "18px" }}
                                      ></i>
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-action delete ml-1"
                                      onClick={() => deleteRow(val.client_id)}
                                    >
                                      <i className="icofont-archive"></i>
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    {editing === true ? (
                                      <button
                                        type="button"
                                        className="btn btn-action edit mr-1"
                                        onClick={() => _onEdit(val)}
                                      >
                                        <i className="icofont-pencil-alt-2"></i>
                                      </button>
                                    ) : null}
                                    {deleting === true ? (
                                      <button
                                        type="button"
                                        className="btn btn-action delete"
                                        onClick={() => _onDelete(val.client_id)}
                                      >
                                        <i className="icofont-archive"></i>
                                      </button>
                                    ) : null}
                                  </>
                                )}
                                {/* Divya. March 21 2024. Code End */}
                              </td>
                            </tr>
                          );
                        })
                    : null}
                </tbody>
              }
            </table>
          </div>
          {getListData.length === 0 && loading === false && global.new_key ? (
            <SearchNoDataFound message={"No Clients Found"} />
          ) : getListData.length === 0 && loading === false ? (
            <NoData />
          ) : null}
          {<Loader isLoading={loading} />}
          <div className="text-md-right">
            <nav aria-label="Page navigation">
              <Pagination
                pages={pageCount}
                endPoint={"/clients/list/"}
                nextPage={nextPage}
                currentPage={currentPage}
                pageCount={pageCount}
                rowsPerPage={handleRowsPerPageChange}
              />
            </nav>
          </div>
        </div>
      </div>
      {/* Add Modal Box */}
      <ReactModal
        initWidth={550}
        initHeight={"auto"}
        className={"contained-modal-title-vcenter"}
        onRequestClose={handleClose}
        isOpen={show}
        top={100}
        disableResize={true}
        disableKeystroke={true}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="">
            {"Edit"} Client
          </h5>
          <button type="button" className="close" onClick={handleClose}>
            <i className="icofont-close"></i>
          </button>
        </div>
        <div className="modal-body">
          <div className="row form-style">
            {/* Logo on the right side */}
            <div className="col-md-12">
              <img
                src={formFields["clientLogo"]}
                alt=" Upload Your Logo"
                className="logo-image mx-auto d-block my-auto"
                style={{ marginBottom: "5px", height: "130px", width: "130px" }} // Adjust the styling as needed
              />
            </div>
            <div className="col-md-12">
              {/* </div> */}
              <div className="form-group">
                <label>Upload File</label>
                <div className="custom-file">
                  <input
                    type="file"
                    accept=".png,.jpg,.jpeg,.webp,.svg, .jfif, .pjpeg, .pjp,.ico"
                    className="custom-file-input"
                    id="inputGroupFile02"
                    onChange={filehandler}
                  />
                  <label
                    className="custom-file-label"
                    for="inputGroupFile02"
                    aria-describedby="inputGroupFileAddon02"
                  >
                    {file === undefined ? "Choose File" : file.name}
                  </label>
                  {/* <span className="text-danger">{ file === undefined ? 'Please Select file' : null}</span> */}
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Business Unit</label>
                <select
                  className="form-control"
                  onChange={handleFieldChange}
                  name="businessUnit"
                  value={formFields["businessUnit"]}
                >
                  <option value="">Select Business Unit</option>
                  {businessUnitOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <span className="text-danger">
                  {flag === true && formFields.businessUnit === ""
                    ? "Please Enter Business Unit"
                    : null}
                </span>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Client Name</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleFieldChange}
                  name="clientName"
                  value={formFields["clientName"]}
                />
                <span className="text-danger">
                  {flag === true && formFields.clientName === ""
                    ? "Please Enter Client Name"
                    : null}
                </span>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Client Contact Number</label>
                <PhoneInput
                  placeholder="Enter phone number"
                  value={phoneNumber}
                  className="form-control"
                  onChange={setPhoneNumber}
                  countrySelectProps={{ unicodeFlags: true }}
                  defaultCountry="US"
                  international
                  name="clientMobileNumber"
                />
                <span className="text-danger">
                  {flag === true && formFields.clientMobileNumber === ""
                    ? "Please Enter Client Mobile Number"
                    : null}
                </span>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Client Email</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleFieldChange}
                  name="clientEmail"
                  value={formFields["clientEmail"]}
                />
                <span className="text-danger">
                  {flag === true && formFields.clientEmail === ""
                    ? "Please Enter Client Email"
                    : null}
                </span>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Client Address</label>
                <textarea
                  className="form-control h-auto"
                  rows={4}
                  onChange={handleFieldChange}
                  name="clientAddress"
                  value={formFields["clientAddress"]}
                />
                <div className="d-flex flex-column">
                  <span className="text-danger">
                    {flag === true && formFields.clientAddress === ""
                      ? "Please Enter Client Address"
                      : null}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Client Url</label>
                <textarea
                  className="form-control h-auto"
                  onChange={handleFieldChange}
                  name="clientUrl"
                  value={formFields["clientUrl"]}
                ></textarea>
                <div className="d-flex flex-column">
                  <span className="text-danger">
                    {flag === true && formFields.clientUrl === ""
                      ? "Please Enter Client Url"
                      : null}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-lg btn-brand-1 bg-danger"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-lg btn-brand-1 bg-success"
              onClick={() => save()}
              disabled={buttonLoader}
            >
              {buttonLoader && (
                <img
                  src={
                    require("../../../../assets/css/images/button_loader.gif")
                      .default
                  }
                  alt="loading..."
                  style={{ marginLeft: 5 }}
                />
              )}
              <span>Save</span>
            </button>
          </div>
        </div>
      </ReactModal>
      {/*Added this code for  WOII-185. Divya. March 21 2024.. Code Start */}
      {/* Filter model */}
      <ReactModal
        initWidth={550}
        initHeight={"auto"}
        top={137.5}
        className={"contained-modal-title-vcenter"}
        onRequestClose={handleClose}
        isOpen={showFilter}
        disableResize={true}
        disableKeystroke={true}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="">
            Filter
          </h5>
          <button type="button" className="close" onClick={handleCloseFilter}>
            <i className="icofont-close"></i>
          </button>
        </div>
        <div className="modal-body">
          <div className="row form-style">
            <div className="col-md-6">
              <div className="form-group">
                <label>Status</label>
                <select
                  id="disabledSelect"
                  className="form-control custom-select "
                  onChange={handleChange}
                  name="client_status"
                  value={formFields["client_status"]}
                >
                  <option value="" disabled>
                    Select your option
                  </option>
                  <option value="ACTIVE">Active</option>
                  <option Value="DELETED">Archived</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Client Name</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  name="clientName"
                  value={formFields["clientName"]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer ">
          <button
            type="button"
            className="btn btn-lg btn-brand-1 bg-danger"
            onClick={handleCloseFilter}
          >
            Cancel
          </button>
          <button
            className="btn btn-lg btn-brand-1"
            onClick={() => _onResetFilter()}
          >
            Reset
          </button>
          <button
            type="button"
            className="btn btn-lg btn-brand-1 bg-success"
            onClick={() => _onFilter()}
          >
            Search
          </button>
        </div>
      </ReactModal>
      {/* Divya. March 21 2024. Code End  */}
    </>
  );
};
export default Clients;
